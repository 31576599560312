<!--
 * @Author: Libra
 * @Date: 2022-06-10 16:11:24
 * @LastEditTime: 2024-04-10 13:56:21
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/views/question/layout/base/index.vue
-->
<template>
  <div class="qus-container">
    <div
          class="left"
          id="left"
        >
        <div
    class="question"
    style="padding-right: 50px;"
  >
  <div class="title">
      {{
        $store.state.examInfo.coverInfo.shortName +
        $store.state.examInfo.userExamName +
        $store.state.jobInfo.name
      }}
          <span style="font-weight: normal;font-size: 14px;color: #888">{{
            $store.state.examInfo.scenario
          }}</span>
        </div>
    <div class="question-type">
      <div>
        {{ current + 1 }}、{{ typeMap[question.type] }}
        <span
v-if="!$store.state.examInfo.isEvaluation"
class="score"
          >{{
            $store.state.isPractice || $route.query.isQuestionnaire === 'true'
              ? '--'
              : question.questionScore
          }}<span style="font-size: 12px;">分</span></span
        >
        <span v-if="(question.type === 2 || question.type === 7) && !$store.state.isPractice" style="font-size: 14px; color: #888; margin-left: 10px;">
          <span v-if="question.scoringRoleType === 0">（计分方式：错选、多选或少选均不得分，全对得分）</span>
          <span v-else>（计分方式：错选、多选不得分，少选得答对数量比例的分）</span>
        </span>
      </div>
      <div
        class="toggle-nail"
        :class="[isRemark ? 'remark' : 'not-remark']"
        v-if="isEnablePreviousQuestion"
        @click="remark(question.questionUuid)"
      >
        <i class="iconfont iconbiaojibeifen"></i><span class="mark">标记</span>
      </div>
      </div>
      <question-interview
        :key="question.questionUuid"
        :question="question"
        :commitLogs="commitLogs"
        @singleChange="singleChange"
        ref="interview"
        v-if="+question.type === 16"
      />
    <div
      :class="[question.isWhiteBackground ? 'question-body2' : 'question-body']"
      ref="questionBody"
    >
      <div class="float">
        <el-tooltip
          class="item"
          effect="dark"
          content="拖拽题干移动位置，拖拽边框调整大小"
          placement="top-end"
        >
          <div v-if="isFold" @click.stop="floatOn">
            <span style="font-size: 12px">拖拽题干 </span>
            <i class="iconfont icon-zuidahua"></i>
          </div>
          <div v-else @click.stop="floatOff">
            <span style="font-size: 12px">还原题干 </span>
            <i class="iconfont iconzuixiaohua"></i>
          </div>
        </el-tooltip>
      </div>
      <div v-html="strReplace(question.body)"></div>
      <div class="audio" v-if="question.voicePath">
        <audio-player
          :src="question.voicePath"
          @subCount="subCount"
          :playCount="playCount"
          :key="question.questionUuid"
        ></audio-player>
        <!-- <span style="margin-top: 20px">试听</span> -->
      </div>
    </div>
    <question-select
      :key="question.questionUuid"
      :question="question"
      :commitLogs="commitLogs"
      @singleChange="singleChange"
      v-if="
          +question.type === 1 ||
          +question.type === 2 ||
          +question.type === 3 ||
          +question.type === 7
      "
    />
    <question-blank
      :question="question"
      :commitLogs="commitLogs"
      @singleChange="singleChange"
      :key="question.questionUuid"
      v-if="+question.type === 4"
    />
    <question-short-answer
      :question="question"
      :commitLogs="commitLogs"
      @singleChange="singleChange"
      :key="question.questionUuid"
      v-if="+question.type === 5"
    />
    <question-audio
      :key="question.questionUuid"
      :question="question"
      :commitLogs="commitLogs"
      @singleChange="singleChange"
      v-if="+question.type === 14"
    ></question-audio>
    <question-cloze
      :question="question"
      :commitLogs="commitLogs"
      @singleChange="singleChange"
      :key="question.questionUuid"
      v-if="+question.type === 13"
    ></question-cloze>
    <question-video
      :question="question"
      :commitLogs="commitLogs"
      @singleChange="singleChange"
      :key="question.questionUuid"
      v-if="+question.type === 15"
    ></question-video>
    </div>
        </div>
        <div class="right">
          <div class="top-container">
            <div class="user">
              <div v-show="!$store.state.isPractice">
                <div id="video-right" v-show="$store.state.cameraDetect">
              </div>
              <div v-show="!$store.state.cameraDetect" class="defaultAvatar">
                <i class="iconfont iconuser"></i>
              </div>
              </div>
              <div v-show="$store.state.isPractice" >
                <video
                v-if="showVideo"
                id="video"
                muted="muted"
                width="272"
                height="240"
              ></video>
              </div>
              <div class="full-name">{{ $store.state.userInfo.realName }}</div>
            </div>
            <div class="tag-con" v-if="+question.type !== 16">
              <div class="question-tags">
                <div class="top">
                  <div class="title">{{ $route.query.name }}</div>
                  <div class="process">
                    <span class="current">{{ current + 1 }}</span
                    >/<span class="total">{{ total }}</span>
                  </div>
                </div>
                <div class="tags">
                  <div
                    v-for="(item, index) in questionIdList"
                    :class="[
                      `tag ${index === current ? 'current' : ''}`,
                      `${remarkList.includes(item) ? 'nailed' : ''}`,
                      `${answerIdList.includes(item) ? 'answered' : ''}`,
                    ]"
                    :key="index"
                    @click="jumpToQuestionInRight(index)"
                  >
                    {{ index + 1 }}
                  </div>
                </div>
              </div>
            </div>
            <div class="bottom" v-if="+question.type !== 16">
              <div class="tips">
                <div class="tip">
                  <div class="type">已答</div>
                  <span class="shape answered"></span>
                </div>
                <div class="tip">
                  <div class="type">未答</div>
                  <span class="shape"></span>
                </div>
                <div class="tip">
                  <div class="type">当前</div>
                  <span class="shape current"></span>
                </div>
                <div class="tip">
                  <div class="type">标记</div>
                  <span class="shape nailed"></span>
                </div>
              </div>
            </div>
            <div class="video-record" v-if="+question.type === 16 && interviewLoaded">
              <i class="iconfont iconjishi1"></i>
              <div class="time" v-if="this.$refs.interview.readMode === 1 && !this.$refs.interview.answerUrl && this.$refs.interview.remainReadTime > 0">{{ formatSeconds(this.$refs.interview.remainReadTime) }}</div>
              <div class="time" v-else>{{ formatSeconds(this.$refs.interview.remainAnswerTimer) }}</div>
              <div class="remain" v-if="this.$refs.interview.readMode === 1 && !this.$refs.interview.answerUrl && this.$refs.interview.remainReadTime > 0">阅读试题 剩余时间</div>
              <div class="remain" v-else>录制视频 剩余时间</div>
              <div class="tip" v-if="this.$refs.interview.readMode === 1 && !this.$refs.interview.answerUrl && this.$refs.interview.remainReadTime > 0">读题结束后，自动开始录制面试视频<br />请提前做好答题准备!</div>
              <CustomButton
                title="录制视频"
                :isPlain="false"
                :hasIcon="true"
                classStr="cusBtn"
                v-if="(this.$refs.interview.readMode === 0 || this.$refs.interview.remainReadTime === 0) &&!this.$refs.interview.answerUrl"
                iconName="iconfont iconvideo2-fill"
                :clickMethod="()=>{
                      this.$refs.interview.startRecord(this.$refs.interview.remainAnswerTimer)
                    }"
              ></CustomButton>
            <div class="problems" v-if="questionIdList.length > 1">
              <div class="pro-con" v-for="(item, index) in questionIdList" :key="index">
                <div
:class="[
                      `point ${index === current ? 'currentv' : ''}`,
                      `${remarkList.includes(item) ? 'nailedv' : ''}`,
                    ]"></div>
                <div class="num">{{ index + 1 }}</div>
              </div>
            </div>
            </div>
          </div>
          <div class="sub_btn_container">
            <el-button
              type="primary"
              @click="submit"
              plain
              class="pre_btn"
              style="width: 206px"
              >提交当前小卷</el-button
            >
          </div>
        </div>
  </div>

</template>

<script>
import QuestionSelect from '@/views/question/components/Select'
import QuestionBlank from '@/views/question/components/Blank'
import QuestionShortAnswer from '@/views/question/components/ShortAnswer'
import QuestionAudio from '@/views/question/components/Audio'
import QuestionCloze from '@/views/question/components/Cloze'
import QuestionVideo from '@/views/question/components/Video'
import audioPlayer from '@/components/audioPlayer'
import QuestionInterview from '@/views/question/components/Interview'
import Api from '@/api/api'
import { getItem, setItem } from '@/utils/storage'
import { formatSeconds } from '@/utils/common'
import CustomButton from '@/components/CustomButton'

export default {
  name: 'Question',
  components: {
    QuestionSelect,
    QuestionBlank,
    QuestionShortAnswer,
    QuestionAudio,
    QuestionCloze,
    audioPlayer,
    QuestionVideo,
    QuestionInterview,
    CustomButton
  },
  props: ['current', 'question', 'commitLogs', 'total', 'questionIdList', 'answerIdList', 'showVideo'],
  data() {
    return {
      prefixName: '',
      isFold: true,
      isRemark: false,
      playCount: this.question.voicePlayTimes,
      isEnablePreviousQuestion: true,
      remarkList: JSON.parse(getItem('remarkList') || '[]'),
      interviewLoaded: false,
      typeMap: {
        '1': '单选题',
        '2': '多选题',
        '3': '判断题',
        '4': '填空题',
        '5': '问答题',
        '6': '编程题',
        '7': '不定项选择题',
        '10': 'scratch编程题',
        '13': '复合题',
        '14': '音频题',
        '15': '录视频题',
        '16': '人机面试题'
      }
    }
  },
  watch: {
    $route() {
      this.isRemark = this.hasRemark(this.question.questionUuid)
      this.$nextTick(() => {
        if (this.question.voicePath) {
          this.handlePlayCount()
        }
      })
    }
  },
  created() {
    if (this.question.voicePath) {
      this.handlePlayCount()
    }
    this.isEnablePreviousQuestion =
      this.$route.query.isEnablePreviousQuestion === 'true'
  },
  mounted() {
    if (this.question.type === 16) {
      this.interviewLoaded = true
    }
    const doc = document.querySelector('.question')
    if (this.question.type !== 6) {
      // 禁止复制
      doc.oncopy = function() {
        return false
      }
      // 禁止剪切
      doc.oncut = function() {
        return false
      }
      // 禁止粘贴
      doc.onpaste = function() {
        return false
      }
    }
  },
  methods: {
    formatSeconds(time) {
      return formatSeconds(time)
    },
    submit() {
      this.$emit('submit')
    },
    backTop() {
      document.documentElement.scrollTop = 0
    },
    floatOff() {
      const e = this.$refs.questionBody
      this.isFold = true
      e.style.position = 'static'
      e.style.width = 'auto'
      e.style.height = 'auto'
      e.style.border = 'none'
      e.style.borderRadius = '0px'
      e.style.boxShadow = 'none'
      e.style.cursor = 'auto'
      e.onmousemove = null
      e.onmousedown = null
    },
    floatOn() {
      const e = this.$refs.questionBody
      this.isFold = false
      this.drag(e)
      e.style.position = 'fixed'
      e.style.zIndex = '999'
      e.style.width = '30%'
      e.style.height = '500px'
      e.style.overflow = 'auto'
      e.style.border = '10px solid #fff'
      e.style.borderRadius = '5px'
      e.style.boxShadow = '0 0 10px 5px rgba(0,0,0,0.2)'
    },
    drag(obj) {
      obj.onmousedown = function(e) {
        var dir = '' // 设置好方向
        var firstX = e.clientX // 获取第一次点击的横坐标
        var firstY = e.clientY // 获取第一次点击的纵坐标
        var width = obj.offsetWidth // 获取到元素的宽度
        var height = obj.offsetHeight // 获取到元素的高度
        var Left = obj.offsetLeft // 获取到距离左边的距离
        var Top = obj.offsetTop // 获取到距离上边的距离
        // 下一步判断方向距离左边的距离+元素的宽度减去自己设定的宽度，只要点击的时候大于在这个区间，他就算右边
        if (firstX > Left + width - 30) {
          dir = 'right'
        } else if (firstX < Left + 30) {
          dir = 'left'
        }
        if (firstY > Top + height - 30) {
          dir = 'down'
        } else if (firstY < Top + 30) {
          dir = 'top'
        }
        // 判断方向结束
        document.onmousemove = function(e) {
          switch (dir) {
            case 'right':
              obj.style['width'] = width + (e.clientX - firstX) + 'px'
              break
            case 'left':
              obj.style['width'] = width - (e.clientX - firstX) + 'px'
              obj.style['left'] = Left + (e.clientX - firstX) + 'px'
              break
            case 'top':
              // 触发不了 onmouseup
              // obj.style['height'] = height - (e.clientY - firstY) + 'px'
              // obj.style['top'] = Top + (e.clientY - firstY) + 'px'
              break
            case 'down':
              obj.style['height'] = height + (e.clientY - firstY) + 'px'
              break
            default:
              obj.style['left'] = Left + (e.clientX - firstX) + 'px'
              obj.style['top'] = Top + (e.clientY - firstY) + 'px'
              obj.style['cursor'] = 'move'
              break
          }
        }
        obj.onmouseup = function() {
          document.onmousemove = null
        }
        return false
      }
    },
    singleChange(val) {
      this.question.value = val
    },
    // 用户刷新的时候，并不会监听 $route ,所以需要自动判断url中的params
    // 进而更新当前 current 和 question
    handleRefresh() {
      this.current = +this.$route.query.current || 0
      this.question = this.questions[this.current]
      this.backTop()
    },
    // 标记当前试题
    remark(questionUuid) {
      let remarkList = JSON.parse(getItem('remarkList') || '[]')
      if (!remarkList.length) {
        setItem('remarkList', JSON.stringify([questionUuid]))
      }
      for (const index in remarkList) {
        const item = remarkList[index]
        if (item === questionUuid) {
          remarkList.splice(index, 1)
          setItem('remarkList', JSON.stringify(remarkList))
          this.isRemark = this.hasRemark(this.question.questionUuid)
          this.updateReamrkList()
          return
        }
      }
      setItem('remarkList', JSON.stringify([...remarkList, questionUuid]))
      remarkList = [...remarkList, questionUuid]
      this.isRemark = this.hasRemark(this.question.questionUuid)
      this.updateReamrkList()
    },
    // 更新 remarkList
    updateReamrkList() {
      this.remarkList = JSON.parse(getItem('remarkList') || '[]')
    },
    async jumpToQuestionInRight(index) {
      if (this.question.type === 16) {
        this.$message.error('当前题目不允许跳转')
        return
      }
      this.$emit('jumpToQuestionInRight', index)
    },
    // 判断当前题目是否被标记了
    hasRemark(questionUuid) {
      const remarkList = JSON.parse(getItem('remarkList') || '[]')
      for (const item of remarkList) {
        if (item === questionUuid) {
          return true
        }
      }
      return false
    },
    // 字符串替换
    strReplace(str) {
      if (!str) return
      const s = str.replace(/&lt;/g, '<')
      return s.replace(/&gt;/g, '>')
    },
    breakToBr(str) {
      const a = JSON.stringify(str)
      return a.replace(/\n/g, '<br />')
    },
    async handlePlayCount() {
      const questionUuid = this.question.questionUuid
      const res = await Api.getPlayCount(questionUuid)
      if (res.code === 0) {
        this.playCount = res.data
      }
    },
    async subCount() {
      const questionUuid = this.question.questionUuid
      const res = await Api.subPlayCount(questionUuid)
      if (res.code === 0) {
        this.playCount = res.data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
