<!--
 * @Author: Libra
 * @Date: 2021-05-24 17:51:11
 * @LastEditTime: 2021-08-25 16:12:17
 * @LastEditors: Libra
 * @Description: 填空题模版
 * @FilePath: /stone-exam-ui/src/views/question/components/Blank.vue
-->
<template>
  <div class="blank">
    <div class="blanks">
      <div
        class="blank-item"
        v-for="(item, index) in question.answerCount"
        :key="index"
      >
        <input
          class="blank-input"
          type="text"
          v-model="blankAnswerList[index]"
          @input="singleChange(blankAnswerList)"
        />
        <div class="label">{{ index + 1 }}、</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionBlank',
  props: ['question', 'commitLogs'],
  data() {
    return {
      blankAnswerList: Array.from({ length: this.question.answerCount }).fill(
        ''
      )
    }
  },
  created() {
    for (const item of this.commitLogs) {
      if (item.questionUuid === this.question.questionUuid) {
        const val = JSON.parse(JSON.stringify(item.value))
        this.blankAnswerList = val
      }
    }
  },
  methods: {
    singleChange(val) {
      for (let item of val) {
        if (item === null) {
          item = ''
        }
      }
      this.$emit('singleChange', {
        value: val,
        file: null,
        originalFile: null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/variables.scss';
.blank {
  .blanks {
    .blank-item {
      font-size: 15px;
      height: 42px;
      width: 100%;
      background-color: #fff;
      margin-bottom: 12px;
      position: relative;
      .blank-input {
        box-sizing: border-box;
        padding: 0 36px;
        width: 100%;
        display: block;
        border: 1px solid #c3c3c3;
        height: 100%;
        &:focus {
          border-color: $primary-color;
          outline: none;
        }
      }
      .label {
        position: absolute;
        left: 0;
        top: 0;
        height: 42px;
        line-height: 42px;
        padding-left: 16px;
      }
    }
  }
}
</style>
