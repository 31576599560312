import { render, staticRenderFns } from "./ClozeSelect.vue?vue&type=template&id=32d27833"
import script from "./ClozeSelect.vue?vue&type=script&lang=js"
export * from "./ClozeSelect.vue?vue&type=script&lang=js"
import style0 from "./ClozeSelect.vue?vue&type=style&index=0&id=32d27833&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/root/workspace/stone-exam-ui_hRF9/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('32d27833')) {
      api.createRecord('32d27833', component.options)
    } else {
      api.reload('32d27833', component.options)
    }
    module.hot.accept("./ClozeSelect.vue?vue&type=template&id=32d27833", function () {
      api.rerender('32d27833', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/question/components/ClozeSelect.vue"
export default component.exports