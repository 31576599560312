var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "video",
    {
      staticClass: "video-js vjs-default-skin vjs-big-play-centered",
      staticStyle: { "margin-top": "20px" },
      attrs: { id: _vm.id, width: "480", height: "360" },
    },
    [_c("source", { attrs: { type: "application/x-mpegURL" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }