<!--
 * @Author: Libra
 * @Date: 2021-08-12 11:07:14
 * @LastEditTime: 2023-11-23 10:02:20
 * @LastEditors: Libra
 * @Description: 子母题
 * @FilePath: /stone-exam-ui/src/views/question/components/ClozeSelect.vue
-->
<template>
  <div class="cloze-container">
    <div
      class="questions"
      v-for="(item, index) in groupOptionList"
      :key="index"
    >
      <div class="describe">
        <span>第{{ index + 1 }}题 {{ item.typeName }}</span>
        <span
v-if="!$store.state.examInfo.isEvaluation"
style="color: #a58e54"
          >{{
            $route.query.isQuestionnaire === 'true' ? '--' : item.questionScore
          }}
          分</span
        >
      </div>
      <div class="sub-question" v-html="strReplace(item.body)"></div>
      <div
        class="sub-question-content"
        v-for="(option, i) in item.optionList"
        :key="i"
      >
        <el-checkbox-group
          v-model="clozeAnwser[index]"
          v-if="+item.type === 2 || +item.type === 7"
          @change="multi($event, index)"
        >
          <div
            :class="
              clozeAnwser[index].includes(String(option.value))
                ? 'option2'
                : 'option'
            "
          >
            <el-checkbox
              :label="String(option.value)"
              :key="String(option.value)"
              ><div style="display: flex; justify-content: flex-start; align-items: center;">
              <span>{{optionMap[i]}}、</span>
            <span
              v-html="strReplace(option.title)"
            ></span
          >
            </div></el-checkbox>
          </div>
        </el-checkbox-group>
        <el-radio-group
          v-model="clozeAnwser[index]"
          v-if="+item.type === 1 || +item.type === 3"
          @change="single($event, index)"
        >
          <div
            :class="
              clozeAnwser[index] === String(option.value) ? 'option2' : 'option'
            "
          >
            <el-radio :label="String(option.value)" :key="String(option.value)">
              <div style="display: flex; justify-content: flex-start; align-items: center;">
              <span>
                {{optionMap[i]}}、
              </span>
              <span
                class="option-content"
                v-html="strReplace(option.title)"
              ></span>
            </div>
            </el-radio>
          </div>
        </el-radio-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['question', 'commitLogs'],
  data() {
    return {
      clozeAnwser: Array.from({
        length: this.question.groupOptionList.length
      }).fill([]),
      isOptionRandom: false,
      isOptionRandomDisable: false,
      groupOptionList: [],
      optionMap: {
        '0': 'A',
        '1': 'B',
        '2': 'C',
        '3': 'D',
        '4': 'E',
        '5': 'F',
        '6': 'G',
        '7': 'H'
      }
    }
  },
  created() {
    // 是否需要选项乱序
    this.isOptionRandom = this.$route.query.isOptionRandom === 'true'
    // 某道题目是否选项乱序
    this.isOptionRandomDisable = this.question.isOptionRandomDisable
    this.groupOptionList = this.question.groupOptionList.map(item => {
      return {
        ...item,
        optionList: this.shuffle(item.optionList)
      }
    })
    for (const item of this.commitLogs) {
      if (item.questionUuid === this.question.questionUuid) {
        const val = JSON.parse(JSON.stringify(item.value))
        const clozeAnwser = this.arrString(val)
        for (const [index, item] of this.question.groupOptionList.entries()) {
          if (item.type === 1 || item.type === 3) {
            clozeAnwser[index] = clozeAnwser[index][0]
            this.clozeAnwser = clozeAnwser
          }
          if (item.type === 2 || item.type === 7) {
            this.clozeAnwser = clozeAnwser
          }
        }
      }
    }
  },
  methods: {
    strArray(arr) {
      return arr.map(item => JSON.stringify(item))
    },
    arrString(arr) {
      return arr.map(item => JSON.parse(item))
    },
    checkNull(value) {
      const res = []
      for (const item of value) {
        let itemArr = JSON.parse(item)
        if (itemArr.includes(null)) {
          // 删除 null
          itemArr.splice(itemArr.indexOf(null), 1)
        }
        itemArr = JSON.stringify(itemArr)
        res.push(itemArr)
      }
      return res
    },
    singleChange() {
      let value = this.strArray(this.handleAnwser(this.clozeAnwser))
      value = this.checkNull(value)
      this.$emit('singleChange', {
        value,
        file: null,
        originalFile: null
      })
    },
    strReplace(str) {
      if (!str) return
      const s = str.replace(/&lt;/g, '<')
      return s.replace(/&gt;/g, '>')
    },
    single() {
      this.singleChange()
    },
    multi(val, index) {
      val = val.map(item => String(item))
      this.$set(this.clozeAnwser, 'index', val)
      this.singleChange()
    },
    handleAnwser(arr) {
      const res = []
      for (const item of arr) {
        if (!Array.isArray(item)) {
          res.push([item])
        } else {
          res.push(item)
        }
      }
      return res
    },
    // 数组乱序
    shuffle(a) {
      if (!this.isOptionRandom) return a
      if (this.isOptionRandomDisable) return a
      for (let i = a.length; i; i--) {
        const j = Math.floor(Math.random() * i)
        ;[a[i - 1], a[j]] = [a[j], a[i - 1]]
      }
      return a
    }
  }
}
</script>

<style lang="scss">
.el-radio-group,
.el-checkbox-group {
  width: 100%;
}
.cloze-container {
  .questions {
    margin-bottom: 40px;
    .describe {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .sub-question {
      margin-top: 10px;
      background-color: #f7f7f7;
      padding: 16px 24px;
      color: #000;
      margin-bottom: 24px;
      line-height: 1.6;
      p {
        margin: 0;
      }
    }
    .option {
      font-size: 15px;
      margin-bottom: 6px;
      background-color: #f1f1f1;
      p {
        display: inline-block;
        margin: 0;
      }
      .is-checked {
        line-height: 1.5;
      }
      .el-radio {
        display: block;
        padding: 16px 24px 16px 8px;
        box-sizing: border-box;
        .el-radio__label {
          white-space: normal;
        }
      }
      .el-checkbox {
        padding: 16px 24px 16px 8px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .el-checkbox__label {
          white-space: normal;
          p {
            line-height: 1.5;
            display: inline;
          }
        }
      }
      .el-radio {
        padding: 16px 24px 16px 8px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .el-radio__label {
          white-space: normal;
          p {
            line-height: 1.5;
            display: inline;
          }
        }
      }
      &:hover {
        background-color: #fce6e8;
        color: #de0000;
      }
    }
    .option2 {
      font-size: 15px;
      margin-bottom: 6px;
      background-color: #fce6e8;
      p {
        display: inline-block;
        margin: 0;
      }
      .el-radio {
        display: block;
        padding: 16px 24px 16px 8px;
        box-sizing: border-box;
        .el-radio__label {
          white-space: normal;
        }
      }
      .el-checkbox {
        padding: 16px 24px 16px 8px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .el-checkbox__label {
          white-space: normal;
          p {
            line-height: 1.5;
            display: inline;
          }
        }
      }
      .el-radio {
        padding: 16px 24px 16px 8px;
        box-sizing: border-box;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .el-radio__label {
          white-space: normal;
          p {
            line-height: 1.5;
            display: inline;
          }
        }
      }
    }
  }
}
</style>
