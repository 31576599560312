var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "audio-container" }, [
    _c("div", { staticClass: "holder" }, [
      _c("div", { staticClass: "audio green-audio-player" }, [
        _c("div", { staticClass: "play-pause" }, [
          !_vm.disabled
            ? _c("div", [
                _vm.isPlaying && _vm.canPlay
                  ? _c("i", { staticClass: "iconfont iconpause-full" })
                  : !_vm.isPlaying && _vm.canPlay
                  ? _c("i", {
                      staticClass: "iconfont iconicon_bofang",
                      on: { click: _vm.play },
                    })
                  : _c("i", { staticClass: "iconfont iconjiazai1" }),
              ])
            : _c("div", [_c("i", { staticClass: "iconfont iconjinzhi" })]),
        ]),
        _c(
          "div",
          { staticClass: "controls" },
          [
            _c("span", { staticClass: "current-time" }, [
              _vm._v(_vm._s(_vm.formatTime(_vm.currentTime))),
            ]),
            _c("el-progress", {
              staticStyle: { width: "100%" },
              attrs: {
                "show-text": false,
                percentage: (_vm.currentTime / _vm.totalTime) * 100 || 0,
              },
            }),
            _c("span", { staticClass: "total-time" }, [
              _vm._v(_vm._s(_vm.formatTime(_vm.totalTime))),
            ]),
          ],
          1
        ),
        _c("audio", { ref: "myAudio", attrs: { preload: "auto" } }, [
          _c("source", { attrs: { src: _vm.src, type: "audio/mpeg" } }),
        ]),
      ]),
      _vm.canPlay
        ? _c("span", [
            _vm._v(
              _vm._s(
                _vm.playCount === 99 ? "" : `（剩余播放次数：${_vm.playCount}）`
              )
            ),
          ])
        : _c("span", [_vm._v("（音频正在加载中...）")]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }