<!--
 * @Author: Libra
 * @Date: 2021-05-21 10:57:30
 * @LastEditTime: 2022-11-16 10:56:11
 * @LastEditors: Libra
 * @Description: 语音题
 * @FilePath: /stone-exam-ui/src/views/question/components/Audio.vue
-->
<template>
  <div class="audio-contaienr">
    <GlobalDialog
      :dialogVisible="showAudioDetect"
      @dialog-cancel="showAudioDetect = false"
      title="检测设备"
      :isShowFooter="false"
      width="500px"
    >
      <div class="audio-detect">
        <span class="title">你能听到自己的声音吗？</span>
        <div class="detect-container">
          <div class="left">
            <div class="icon-container">
              <i class="iconfont iconshengyin"></i>
            </div>
            <span class="start-title">开始检测</span>
          </div>
          <div class="right">
            <!-- <el-slider
              height="100"
              :show-tooltip="false"
              v-model="value1"
            ></el-slider> -->
          </div>
        </div>
        <div class="buttons">
          <CustomButton
            class="gap"
            title="我能听到"
            :isPlain="true"
          ></CustomButton>
          <CustomButton title="没有声音" :isPlain="true"></CustomButton>
        </div>
        <div class="drops">
          <div class="left">
            <i class="iconfont iconvoice-2"></i>
            <span>麦克风</span>
          </div>
          <div class="right">
            <el-select v-model="value" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="drops">
          <div class="left">
            <i class="iconfont iconyanjizhushou-shangchuan_yangshengqi"></i>
            <span>扬声器</span>
          </div>
          <div class="right">
            <el-select v-model="value2" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.disabled"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </GlobalDialog>
    <GlobalDialog
      :dialogVisible="showGetNullAudio"
      :show_close="false"
      @dialog-cancel="showGetNullAudio = false"
      title="提示"
      width="500px"
    >
      <div class="occupy-result" style="margin-bottom: 20px;">
        <i class="iconfont iconjinzhi"></i>
        <div class="right">
          <span class="title">未获取到音频</span>
          <span class="content">请在手机端上传音频，再点击预览</span>
        </div>
      </div>
    </GlobalDialog>
    <GlobalDialog
      :dialogVisible="showDeleteAudio"
      :show_close="false"
      @dialog-cancel="showDeleteAudio = false"
      @dialog-ok="deleteAudio"
      title="提示"
      width="500px"
      :showSecond="true"
      btn_title="取消"
      btn_title2="确认"
    >
      <div class="occupy-result" style="margin-bottom: 20px;">
        <i class="iconfont iconshanchu"></i>
        <div class="right">
          <span class="title">确定要删除吗？</span>
        </div>
      </div>
    </GlobalDialog>
    <GlobalDialog
      :dialogVisible="showReUpload"
      :show_close="false"
      @dialog-cancel="showReUpload = false"
      @dialog-ok="reUpload"
      title="提示"
      width="500px"
      :showSecond="true"
      btn_title="取消"
      btn_title2="确认"
    >
      <div class="occupy-result" style="margin-bottom: 20px;">
        <i class="iconfont iconrefresh"></i>
        <div class="right">
          <span class="title">重新上传会删除原先的音频</span>
          <span class="content">你确定要重新上传音频吗？</span>
        </div>
      </div>
    </GlobalDialog>
    <el-dialog
      title="手机扫码上传音频"
      :visible.sync="showPhoneUpload"
      :before-close="beforePhoneUploadClose"
      width="600px"
      :modal="false"
      :modal-append-to-body="false"
    >
      <div class="content-contaienr">
        <div
          v-loading="qrLoading"
          element-loading-text="二维码生成中"
          class="qr-container"
          style="width: 200px;height: 200px;"
        >
          <vue-qr
            style="width: 200px;height: 200px;"
            :size="800"
            :text="qrContent"
          ></vue-qr>
          <div class="refresh-qr" v-if="isFailure">
            <span class="failure">二维码已失效</span>
            <el-button type="danger" @click="refresh">请点击刷新</el-button>
          </div>
        </div>
        <span class="upload-label">手机微信扫码</span>
        <!-- <span class="upload-tips">每题最多可传三张图片</span> -->
        <el-button
          type="danger"
          style="margin: 20px 0;"
          @click="getAudio"
          :loading="isGettingAudio"
          >手机已传完，预览音频</el-button
        >
      </div>
    </el-dialog>
    <!-- <span class="title">语音题</span> -->
    <div class="answer">
      <div v-if="!isAnswerUseText" class="audio-answer">
        <div class="btn-con">
          <CustomButton
            class="gap"
            title="上传录音"
            :isPlain="false"
            :hasIcon="true"
            iconName="iconfont iconupload-fill"
            :clickMethod="upload"
            :isDisabled="src !== null"
            v-if="src === null"
          ></CustomButton>
          <CustomButton
            class="gap"
            title="预览音频"
            :isPlain="true"
            :hasIcon="true"
            iconName="iconfont iconyanjing"
            :clickMethod="getAudio"
          ></CustomButton>
          <!-- <CustomButton
            class="gap"
            title="重新上传"
            :isPlain="true"
            :hasIcon="true"
            :clickMethod="() => (showReUpload = true)"
            iconName="iconfont iconzhongxinshangchuan"
            :isDisabled="src === null"
            v-if="src !== null"
          ></CustomButton> -->
          <CustomButton
            title="删除录音"
            :isPlain="true"
            :hasIcon="true"
            :clickMethod="() => (showDeleteAudio = true)"
            iconName="iconfont iconshanchu"
            :isDisabled="src === null"
            v-if="src !== null"
          ></CustomButton>
        </div>
        <audio v-if="src" controls :src="src">
          你的浏览器不支持
          <code>audio</code> 标签.
        </audio>
      </div>
      <el-input
        v-else
        type="textarea"
        :rows="8"
        placeholder="请输入答案..."
        v-model="audioAnswer"
        @input="singleChange()"
      >
      </el-input>
    </div>
    <!-- <span class="detect" @click="showAudioDetect = true">检测设备</span> -->
  </div>
</template>

<script>
import CustomButton from '@/components/CustomButton'
import GlobalDialog from '@/components/GlobalDialog'
import Api from '@/api/api'
import VueQr from 'vue-qr'
import { file_host } from '@/api/config'
import { bus } from '@/utils/bus'

export default {
  components: {
    CustomButton,
    GlobalDialog,
    VueQr
  },
  props: ['question', 'commitLogs'],
  data() {
    return {
      showAudioDetect: true,
      options: [],
      value: '默认-麦克风阵列2323',
      value2: '默认-扬声器阵列2323',
      qrTimer: null,
      isFailure: false,
      qrContent: '',
      qrLoading: false,
      isGettingAudio: false,
      showPhoneUpload: false,
      showGetNullAudio: false,
      showReUpload: false,
      showDeleteAudio: false,
      audioAnswer: '',
      filePath: '',
      isAnswerUseText: this.question.isVoiceQuestionTextAnswer,
      src: null
    }
  },
  created() {
    for (const item of this.commitLogs) {
      if (item.questionUuid === this.question.questionUuid) {
        const val = JSON.parse(JSON.stringify(item.value))
        if (this.isAnswerUseText) {
          this.audioAnswer = val[0]
        } else {
          if (val[0]) {
            this.filePath = val[0]
            this.src = `${file_host}${val[0]}`
          }
        }
      }
    }
  },
  methods: {
    beforePhoneUploadClose() {
      clearTimeout(this.qrTimer)
      this.isFailure = false
      this.isGettingAudio = false
      this.showPhoneUpload = false
    },
    refreshQR() {
      this.qrTimer = setTimeout(() => {
        this.isFailure = true
        clearTimeout(this.qrTimer)
      }, 60000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.qrTimer)
      })
    },
    async refresh() {
      await this.getMobileToken()
      this.isFailure = false
      this.refreshQR()
    },
    // 手机扫码上传
    async getMobileToken() {
      this.qrLoading = true
      const res = await Api.getMobileToken()
      const { mobileMonitorType, mobileVideoType } = this.$store.state.examInfo
      const isOpen = mobileVideoType !== 0 || mobileMonitorType !== 0
      this.qrLoading = false
      const env = process.env.NODE_ENV
      // const env = 'beta'
      if (res.code === 0) {
        this.qrContent = `https://exam.stac.fun/audioUpload?token=${res.data}&partUuid=${this.$route.query.partUuid}&questionUuid=${this.question.questionUuid}&isOpen=${isOpen}&env=${env}&type=2`
      }
    },
    async getAudio() {
      const res = await Api.getQuestionSlice(this.question.questionUuid)
      if (res.code === 0) {
        if (res.data !== null) {
          const { path } = res.data
          this.filePath = `${path}`
          this.src = `${file_host}${path}`
        } else {
          this.showGetNullAudio = true
        }
        this.showPhoneUpload = false
        this.singleChange()
      }
    },
    upload() {
      this.showPhoneUpload = true
      this.getMobileToken()
      this.refreshQR()
    },
    reUpload() {
      this.deleteAudio()
      this.upload()
      this.showReUpload = false
    },
    async deleteAudio() {
      const res = await Api.deleteSlice({
        path: this.filePath,
        questionUuid: this.question.questionUuid
      })
      if (res.code === 0) {
        this.src = null
        this.filePath = null
        this.singleChange()
        bus.$emit('saveAnswer')
        this.showDeleteAudio = false
      } else {
        this.$message.error('删除失败')
      }
    },
    singleChange() {
      const value = this.isAnswerUseText ? [this.audioAnswer] : [this.filePath]
      this.$emit('singleChange', {
        value,
        file: null,
        originalFile: null
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-slider__button {
  width: 10px;
  height: 10px;
  border: none;
}
::v-deep .el-input .el-input__inner:focus {
  border: none;
}
::v-deep .el-input .el-input__inner {
  border: none;
}
::v-deep .el-select {
  width: 100%;
}
.audio-contaienr {
  .content-contaienr {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .qr-container {
      position: relative;
      .refresh-qr {
        position: absolute;
        width: 200px;
        height: 200px;
        top: 0;
        left: 0;
        font-size: 18px;
        background-color: rgba(255, 255, 255, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .failure {
          font-weight: bold;
          margin-bottom: 15px;
        }
      }
    }
    .upload-label {
      margin-top: 20px;
      font-size: 24px;
      font-weight: bold;
    }
    .upload-tips {
      margin: 20px 0;
      font-size: 14px;
      color: rgb(56, 56, 56);
    }
  }
  .audio-detect {
    padding: 0 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .detect-container {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .left {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-right: 20px;
        .icon-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 60px;
          height: 60px;
          background-color: rgb(251, 228, 228);
          border-radius: 50%;
          .iconfont {
            font-size: 40px;
            color: #cb2a1d;
          }
        }
        .start-title {
          font-weight: bold;
          margin-top: 5px;
        }
      }
      .right {
        flex: 1;
      }
    }
    .buttons {
      margin: 10px 0 20px 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .drops {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      .left {
        margin-right: 10px;
      }
      .right {
        flex: 1;
      }
    }
  }
  .title {
    font-weight: bold;
  }
  .detect {
    font-weight: bold;
    color: rgb(56, 56, 56);
    margin-top: 20px;
    display: block;
  }
  .answer {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    .audio-answer {
      .btn-con {
        display: flex;
        margin-bottom: 20px;
        justify-content: flex-start;
        align-items: center;
      }
    }
  }
}
.gap {
  margin-right: 20px;
}
.occupy-result {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  .iconfont {
    font-size: 89px;
    color: #cb2a1d;
  }
  .right {
    margin-left: 20px;
    .title2 {
      display: block;
      font-size: 18px;
      margin-bottom: 20px;
    }
    .title3 {
      display: block;
      font-size: 20px;
      color: #cb2a1d;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .title {
      display: block;
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
      margin-top: 10px;
    }
    .content {
      display: block;
    }
  }
}
</style>
