<template>
  <div class="layout" v-if="loading">
    <detect-layout />
      <!-- <simple-header
        v-on:saveCode="saveCode"
        v-on:submit="submitPaper"
        @screenShare="screenShare"
        :isProgram="question.type === 6"
        :companyInfo="companyInfo"
      /> -->
      <GlobalDialog
        :dialogVisible="showNotAnwserdList"
        @dialog-cancel="goToQuestion(notAnsweredList[0])"
        title="提示"
        width="550px"
        btn_title="去完成"
        :show_close="false"
      >
      <div class="occupy-result">
          <i class="iconfont icontishi"></i>
          <div class="right">
            <span class="title3">您有{{notAnsweredList.length}}道未作答完成的题目</span>
            <span class="detail">请作答完再提交！</span>
          </div>
        </div>
      </GlobalDialog>
      <GlobalDialog
        :dialogVisible="showPrompt"
        @dialog-cancel="showPrompt = false"
        title="提示"
        width="550px"
        :show_close="false"
      >
        <div class="prompt-container">
          <span class="title">温馨提示</span>
          <div class="content">
            <i class="iconfont iconxiaolian"></i>
            <ul class="right">
              <li class="detail">
                在线编程题<span
style="color: #f35a5a;font-weight: bold"
                  >可以使用本地编译器</span
                >
              </li>
              <li class="detail">
                作答此题<span
style="color: #f35a5a;font-weight: bold"
                  >可以跳出考试系统页面</span
                >
              </li>
              <li class="detail">
                系统会比对所有考生编程代码，请遵守考试纪律
              </li>
            </ul>
          </div>
        </div>
      </GlobalDialog>
      <GlobalDialog
        :dialogVisible="showSaveSuccess"
        @dialog-cancel="showSaveSuccess = false"
        title="提示"
        width="400px"
        :show_close="false"
      >
        <div class="prompt-container">
          <div class="content">
            <i class="iconfont iconduigou1" style="font-size: 48px;"></i>
            <div class="right" style="margin: 0">
              <span class="title">保存成功！</span>
            </div>
          </div>
        </div>
      </GlobalDialog>
      <GlobalDialog
        :dialogVisible="showMustAnswer"
        @dialog-cancel="showMustAnswer = false"
        title="提示"
        width="500px"
        :show_close="false"
      >
        <div class="occupy-result">
          <i class="iconfont icontishi"></i>
          <div class="right">
            <span class="title3">此题没有作答</span>
            <span class="detail">请作答完再打开下一题！</span>
          </div>
        </div>
      </GlobalDialog>
      <GlobalDialog
        :dialogVisible="showOccupyScreenTip"
        @dialog-cancel="reOccupyScreen()"
        :show_close="false"
        title="提示"
        width="500px"
        btn_title="我知道了，继续考试"
      >
        <div class="occupy-result">
          <i class="iconfont iconjinzhi"></i>
          <div class="right">
            <span class="title">本场考试禁止退出全屏</span>
            <span class="content">请点击下方按钮返回考试</span>
          </div>
        </div>
      </GlobalDialog>
      <GlobalDialog
        :dialogVisible="showLeavePageTip"
        @dialog-cancel="showLeavePageTip = false"
        width="500px"
        :show_close="false"
        title="提示"
      >
        <div class="occupy-result">
          <i class="iconfont iconiconfontzhizuobiaozhun41"></i>
          <div class="right">
            <span
class="title"
              >您已离开页面：<span
style="color: #e06563; font-size: 30px;"
                >{{ leaveCount }} </span
              >次</span
            >
            <div class="content" style="line-height: 1.8;letter-spacing: 2px">
              请确保各类可能导致鼠标失焦的后台软件<br />已全部关闭。
              <br />离开次数将记录在您的考试成绩中<br />请遵守考试规则
            </div>
          </div>
        </div>
      </GlobalDialog>
      <GlobalDialog
        :dialogVisible="showYellowCard"
        @dialog-cancel="showYellowCard = false"
        width="500px"
        title="提示"
      >
        <div class="occupy-result">
          <i class="iconfont icontishi-2"></i>
          <div class="right">
            <span class="title">黄牌警告！</span>
            <span
class="title2"
              >您已离开考试界面：<span
style="color: #e06563; font-size: 30px;"
                >{{ leaveCount }} </span
              >次</span
            >
            <span
              class="title2"
              v-if="$store.state.examInfo.leaveCountLimit !== 0"
              >还剩余：<span style="font-size: 24px;">{{
                $store.state.examInfo.leaveCountLimit - leaveCount > 0
                  ? $store.state.examInfo.leaveCountLimit - leaveCount
                  : 0
              }}</span>
              次</span
            >
            <span
class="content"
style="line-height: 1.8;letter-spacing: 2px"
              >请再次确认后台软件已全部关闭<br />次数过多会影响您的成绩<br />
              请遵守考试规则！
            </span>
          </div>
        </div>
      </GlobalDialog>
      <GlobalDialog
        :dialogVisible="showConfirmSubmit"
        @dialog-cancel="showConfirmSubmit = false"
        @dialog-ok="submitPaper"
        title="提示"
        width="500px"
        :showSecond="true"
        :show_close="false"
        btn_title="取消"
        btn_title2="提交"
      >
        <div class="occupy-result">
          <i class="iconfont icontishi-2"></i>
          <div class="right">
            <span
class="title3"
style="color: #1e1e1e"
              >{{ partName }} 小卷提交后<br /><span
class="title3"
                >不能再修改</span
              ></span
            >
            <span class="detail">你确认要提交吗？</span>
          </div>
        </div>
      </GlobalDialog>
      <GlobalDialog
        :dialogVisible="showPracticeSubmit"
        @dialog-cancel="showPracticeSubmit = false"
        @dialog-ok="submitPaper"
        title="提示"
        :showSecond="true"
        :show_close="false"
        btn_title="取消"
        btn_title2="确认"
        width="500px"
      >
        <div class="occupy-result">
          <i class="iconfont icontishi"></i>
          <div class="right">
            <span class="title">此练习题小卷已完成! </span>
            <span class="detail">正式考试时，以实际考试规则为准</span>
          </div>
        </div>
      </GlobalDialog>
      <GlobalDialog
        :dialogVisible="showCanModConfirmSubmit"
        @dialog-cancel="showCanModConfirmSubmit = false"
        @close="showCanModConfirmSubmit = false"
        @dialog-ok="submitPaper"
        title="提示"
        width="500px"
        :show_close="false"
        :showSecond="true"
        btn_title="取消"
        btn_title2="确认"
      >
        <div class="occupy-result">
          <i class="iconfont icontishi"></i>
          <div class="right">
            <span class="title3">你确认要提交吗？</span>
          </div>
        </div>
      </GlobalDialog>
      <GlobalDialog
        :dialogVisible="showNotAnwser"
        @dialog-cancel="nextQuestion"
        @close="showNotAnwser = false"
        @dialog-ok="showNotAnwser = false"
        title="提示"
        width="500px"
        :show_close="false"
        :showSecond="true"
        btn_title2="继续答题"
        btn_title="下一题"
      >
        <div class="occupy-result">
          <i class="iconfont icontishi"></i>
          <div class="right">
            <span class="title3">此题没有作答</span>
            <span class="detail">建议作答完再打开下一题！</span>
          </div>
        </div>
      </GlobalDialog>
      <GlobalDialog
        :dialogVisible="showCanNotBack"
        @dialog-cancel="showCanNotBack = false"
        title="提示"
        width="500px"
        :show_close="false"
      >
        <div class="occupy-result">
          <i class="iconfont icontishi"></i>
          <div class="right">
            <span
class="title3"
              >本小卷所有试题提交后<br />
              不能返回修改
            </span>
            <span class="detail">请确保准确作答后，再点击下一题</span>
          </div>
        </div>
      </GlobalDialog>
      <program-layout
            v-if="question.type==6"
            class="question"
            :current="current"
            :commitLogs="commitLogs"
            :question="question"
            :total="total"
            :saveCode="saveCode"
          ></program-layout>
          <base-layout
            v-else
            class="question"
            :current="current"
            :commitLogs="commitLogs"
            :question="question"
            :questionIdList="questionIdList"
            :answerIdList="answerIdList"
            :total="total"
            :showVideo="showVideo"
            @submit="submit"
            @jumpToQuestionInRight="jumpToQuestionInRight"
          ></base-layout>
          <div
            class="operate"
            :style="{
              width: question.type ===6 ? '50%' : 'calc((100% - 1230px)/2 + 910px)',
            }"
          >
            <div class="tools">
              <div class="service-box" v-show="showCustomer">
                <customer
                  @messageList="handleMessageList"
                  ref="customer"
                  @closeCustomer="showCustomer = false"
                />
              </div>
              <span
                id="calculator"
                class="calculators"
                v-if="useCalculator"
              >
              <div class="calculator-box" v-show="showCalculator">
                <calculator @closeCalculator="() => (showCalculator = false)" />
              </div>
                <span @click="openCalculator"><i class="iconfont iconjisuanqi"></i>计算器</span>  </span
              >
              <el-badge
                :is-dot="unReadCounts"
                class="item"
              >
                <div
                  class="service"
                  id="service"
                  @click="openCustomer"
                  v-if="useCustomer"
                >
                  <i class="iconfont iconkefu1"></i>客服
                </div>
              </el-badge>
            </div>
            <div class="btns">
              <el-button
                v-show="isEnablePreviousQuestion && current !== 0 && question.type !== 16"
                class="pre_btn"
                type="primary"
                plain
                @click="prevQuestion()"
                >上一题</el-button
              >
              <el-button
                v-if="
                  question.type === 5 ||
                    question.type === 14 ||
                    question.type === 15 ||
                    question.type === 13
                "
                class="next_btn"
                type="primary"
                @click="save"
                >保存</el-button
              >
              <el-button
                v-if="current !== total - 1 && question.type !== 16"
                class="next_btn"
                :loading="isLoadingNext"
                type="primary"
                @click="next()"
                >下一题</el-button
              >
              <el-button
                v-if="current === total - 1"
                class="next_btn"
                type="primary"
                @click="submit"
                >提交当前小卷</el-button
              >
            </div>
          </div>
    </div>
</template>

<script>
import Calculator from '@/components/Calculator'
import Customer from '@/components/Customer'
import GlobalDialog from '@/components/GlobalDialog'
import Api from '@/api/api'
import DetectLayout from '@/views/components/DetectLayout'
import { equar } from '@/utils/common.js'
import { getItem, setItem } from '@/utils/storage'
import BaseLayout from '@/views/question/layout/base'
import ProgramLayout from '@/views/question/layout/program'
import { setWaterMark } from '@/common/WaterMark.js'
import { bus } from '@/utils/bus'

export default {
  name: 'QuestionLayout',
  components: {
    Calculator,
    GlobalDialog,
    Customer,
    DetectLayout,
    BaseLayout,
    ProgramLayout
  },
  props: ['questions'],
  data() {
    return {
      tableData: [],
      mediaStreamTrack: null,
      allowCameraVisible: false,
      showCalculator: false,
      showCustomer: false,
      showCanNotBack: false,
      useCalculator: false,
      useCustomer: false,
      isFullscreenData: true,
      showOccupyScreenTip: false,
      showConfirmSubmit: false,
      showLeavePageTip: false,
      showSaveSuccess: false,
      showPhoneDetectError: false,
      showYellowCard: false,
      showCanModConfirmSubmit: false,
      isEnablePreviousQuestion: true,
      showPracticeSubmit: false,
      showPrompt: false,
      showNotAnwser: false,
      showMustAnswer: false,
      isLoadingNext: false,
      prefixName: '',
      hidden: '',
      isBlur: true,
      // 是否需要霸屏
      isOccupyScreen: this.$store.state.examInfo.maximizeScreen !== 0,
      isEnableModifyAfterSubmit: false,
      leaveTimer: null,
      leaveCount: 0,
      unReadCounts: false,
      showVideo: true,
      partName: this.$route.query.name,
      companyName: '',
      question: null,
      current: 0,
      total: 0,
      loading: false,
      questionIdList: [],
      answerIdList: [],
      commitLogs: [],
      answeredCount: 0,
      screenShotMonitorType:
        this.$store.state.examInfo.screenShotMonitorType || 0,
      companyInfo: {},
      examInfo: {},
      time: null,
      showNotAnwserdList: false,
      notAnsweredList: []
    }
  },
  async mounted() {
    await this.getCommitLog()
    this.getAllQuestionId()
    const current = this.$route.query.current || 0
    await this.getQuestion(this.questionIdList[+current], current)
    // 浏览器霸屏
    this.isFullscreen(() => console.log('浏览器不支持全屏幕'))
    // 处理浏览器全屏事件
    this.hanleFullScreen()
    // 处理浏览器全屏事件错误
    this.hanleFullScreenError()
    this.getCompanyName()
    // 刚进入的时候全屏浏览器
    this.isOccupyScreen ? this.Fullscreen() : null
    this.isEnablePreviousQuestion =
      this.$route.query.isEnablePreviousQuestion === 'true'
    this.useCalculator = this.$store.state.examInfo.isEnableCalculator
    this.useCustomer = this.$store.state.examInfo.isEnableSecretary
    const hasShowPrompt = getItem('hasShowPrompt') || 'false'
    if (this.$store.state.isPractice) {
      this.initCamera()
    }
    if (this.question.type === 6 && hasShowPrompt !== 'true') {
      this.showPrompt = true
      setItem('hasShowPrompt', true)
    }
    window.onresize = () => {
      const isElementFullScreen = this.isElementFullScreen()
      if (!isElementFullScreen && this.isOccupyScreen) {
        this.reOccupyScreen()
      }
    }
    if (!this.isEnablePreviousQuestion) {
      this.showCanNotBack = true
    }
    this.$nextTick(() => {
      this.setGuide()
      if (this.$store.state.examInfo.isWatermark) {
        const ele = document.getElementById('left')
        const phoneNum = this.$store.state.userInfo.mobile
        setWaterMark(ele, phoneNum)
      }
    })
    bus.$on('submit', () => {
      this.submitPaper()
    })
    bus.$on('saveCode', (val) => {
      this.saveCode(val)
    })
    bus.$on('saveAnswer', () => {
      this.saveAnwser()
    })
  },
  beforeDestroy() {
    this.stopCamera()
    // removeWatermark()
    // if (window.cameraLocalStream) window.cameraLocalStream.stop('video-right')
    bus.$off('submit')
    bus.$off('saveCode')
    bus.$off('saveAnswer')
  },
  destroyed() {
    this.removeAllEvent()
  },
  watch: {
    // 根据每个问题的 isAllowLeave 属性
    // 判断当前题目是否可以离开当前页面
    // 如果不能离开，则需要做离开的抓拍处理
    question: function(newQuestion) {
      window.clearInterval(this.leaveTimer)
      this.leaveTimer = null
      this.removeFocus()
      this.removeBlur()
      if (!newQuestion.isAllowLeave) {
        this.initBlur()
      }
    },
    // 上一题，下一题 页面滚动到顶部
    $route: function() {
      const left = document.querySelector('.left')
      left.scrollTop = 0
      left.scrollTop = 0
    }
  },
  methods: {
    // 初始化摄像机
    initCamera() {
      const loadingInstance = this.$loading({
        background: 'transparent',
        text: '试题加载中，请稍候...'
      })
      const video = document.getElementById('video')
      const videoObj = {
        video: { width: 272, height: 240, facingMode: 'user' },
        audio: true
      }
      const promise = navigator.mediaDevices.getUserMedia(videoObj)
      promise
        .then((MediaStream) => {
          this.showVideo = true
          this.mediaStreamTrack = MediaStream
          video.srcObject = MediaStream
          video.play()
          setTimeout(() => {
            loadingInstance.close()
          }, 1500)
        })
        .catch((PermissionDeniedError) => {
          this.showVideo = false
          loadingInstance.close()
          this.allowCameraVisible = true
          console.log(PermissionDeniedError)
          console.log('请允许打开摄像头')
        })
    },
    // 停止摄像
    stopCamera() {
      this.mediaStreamTrack && this.mediaStreamTrack.getTracks().forEach(function(track) {
        track.stop()
      })
    },
    // 打开计算器
    openCalculator() {
      this.showCalculator = !this.showCalculator
    },
    async messageRead() {
      await Api.messageRead({})
    },
    async handleMessageList(messageList) {
      if (this.showCustomer) {
        this.unReadCounts = false
        await this.messageRead()
      } else {
        this.unReadCounts = true
      }
    },
    // 打开客服
    async openCustomer() {
      this.showCustomer = true
      this.unReadCounts = false
      await this.messageRead()
      setTimeout(() => this.$refs.customer.scrollToBottom(), 100)
    },
    // 获取试题
    async getQuestion(questionId, current) {
      if (!questionId) return
      const res = await Api.paperQuestionList(questionId)
      this.question = res.data
      this.current = +current
      this.loading = true
      for (const i of this.commitLogs) {
        if (this.question.questionUuid === i.questionUuid) {
          this.question.value = {
            value: i.value,
            file: i.file,
            originalFile: i.originalFile
          }
        }
      }
    },
    // 获取所有试题id
    getAllQuestionId() {
      let { questionIdList } = this.$route.query
      if (!Array.isArray(questionIdList)) {
        questionIdList = [questionIdList]
      }
      this.total = questionIdList.length
      this.questionIdList = questionIdList
      for (const item of this.commitLogs) {
        this.answerIdList.push(item.questionUuid)
      }
    },
    // 添加当前选项到内存中，当用户点击上一题或者下一题的时候，从内存取答案
    // 当用户刷新页面的时候，需要重调取接口，获取提交记录
    saveCommitLogsToMemory() {
      this.commitLogs.some((item, index) => {
        if (item.questionUuid === this.question.questionUuid) {
          this.commitLogs.splice(index, 1)
        }
      })
      this.commitLogs.push({
        partUuid: this.$route.query.partUuid,
        questionUuid: this.question.questionUuid,
        value: this.question.value.value,
        file: this.question.value.file,
        originalFile: this.question.value.originalFile
      })
    },
    async save() {
      this.saveCommitLogsToMemory()
      const res = await this.saveAnwser()
      if (res.code === 0) {
        this.$message.success('答案保存成功！')
      } else {
        this.$message.error('答案保存失败！')
      }
    },
    async saveAnwser() {
      const question = this.question
      console.log(question.type)
      if (question.type === 6 && question.value.value[1].length < 10) {
        this.$message.error('代码量过少,保存失败!!')
        return
      }
      if (!this.answerIdList.includes(question.questionUuid)) {
        this.answerIdList.push(question.questionUuid)
      }
      return await Api.answerSave({
        partUuid: this.$route.query.partUuid,
        questionUuid: question.questionUuid,
        value: question.value.value,
        file: question.value.file,
        originalFile: question.value.originalFile
      })
    },
    // 下一题弹窗
    async next() {
      const answer = this.question.value
      if (!answer || answer.value[0] === '') {
        answer?.originalFile
          ? answer.originalFile.length === 0
            ? !this.isEnablePreviousQuestion
              ? (this.showMustAnswer = true)
              : (this.showNotAnwser = true)
            : this.nextQuestion()
          : !this.isEnablePreviousQuestion
            ? (this.showMustAnswer = true)
            : (this.showNotAnwser = true)
      } else {
        this.nextQuestion()
      }
    },
    // 获取子卷提交记录
    async getCommitLog() {
      const { partUuid } = this.$route.query
      const res = await Api.partCommitLog(partUuid)
      if (res.code === 0) {
        this.commitLogs = res.data
      }
    },
    // 获取子卷已答题数
    async getAnsweredCount() {
      const { partUuid } = this.$route.query
      const res = await Api.partCommitLog(partUuid)
      if (res.code === 0) {
        this.answeredCount = res.data.length
      }
    },
    // 处理答案保存
    async handleSave(question, current, isLast = false) {
      // 提交之前先判断答案是否更改，没更改就不需要重复调接口了
      if (question.value) {
        for (const item of this.commitLogs) {
          if (
            item.questionUuid === question.questionUuid &&
            equar(item.value, question.value.value) &&
            equar(item.file, question.value.file) &&
            equar(item.originalFile, question.value.originalFile)
          ) {
            isLast ? null : await this.jumpToQuestion(this.current)
            return
          }
        }
        if (question.type === 6 && question.value.value[1].length < 10) {
          console.log('不保存到内存')
        } else {
          this.saveCommitLogsToMemory()
        }
        try {
          await this.saveAnwser()
        } catch (error) {
          this.isLoadingNext = false
          return
        }
      }
      if (question.type === 15 && !question.value) {
        this.commitLogs.some((item, index) => {
          if (item.questionUuid === question.questionUuid) {
            this.commitLogs.splice(index, 1)
          }
        })
      }
      // 最后一道题不需要跳转页面了
      isLast ? null : await this.jumpToQuestion(current)
    },
    // 保存代码
    async saveCode(hasDialog = true) {
      const question = this.question
      if (question.type === 6 && question.value.value[1].length < 10) {
        this.$message.error('代码量过少,保存失败!!')
        return
      }
      // 提交之前先判断答案是否更改，没更改就不需要重复调接口了
      for (const item of this.commitLogs) {
        if (
          item.questionUuid === question.questionUuid &&
          equar(item.value, question.value.value) &&
          equar(item.file, question.value.file) &&
          equar(item.originalFile, question.value.originalFile)
        ) {
          hasDialog && (this.showSaveSuccess = true)
          return
        }
      }
      if (question.value) {
        this.saveCommitLogsToMemory()
        const res = await this.saveAnwser()
        if (res.code === 0) {
          hasDialog && (this.showSaveSuccess = true)
        }
      }
    },
    // 上一题
    async prevQuestion() {
      const question = this.question
      this.current -= 1
      await this.handleSave(question, this.current)
    },
    // 下一题
    async nextQuestion(isLast = false) {
      this.showNotAnwser = false
      const question = this.question
      if (!isLast) {
        this.isLoadingNext = true
        this.current += 1
      }
      await this.handleSave(question, this.current, isLast)
    },
    async goToQuestion(questionUuid) {
      const index = this.questionIdList.findIndex(item => item === questionUuid)
      if (index === -1) return
      this.current = index
      await this.jumpToQuestion(index)
      this.showNotAnwserdList = false
    },
    // 题目跳转
    async jumpToQuestion(key) {
      const questionId = this.questionIdList[key]
      if (!questionId) return
      await this.getQuestion(questionId, key)
      this.$router.push({
        path: '/question',
        query: {
          ...this.$route.query,
          current: key
        }
      })
      if (this.question.type === 15) {
        this.scrollToBottom()
      }
      this.isLoadingNext = false
    },
    async jumpToQuestionInRight(index) {
      // 不能返回上一题的，也不能通过右边进入到任何题
      if (!this.isEnablePreviousQuestion) {
        this.$message.error('请按顺序作答试题，不能点击题号作答！')
        return
      }
      const question = this.question
      // 提交之前先判断答案是否更改，没更改就不需要重复调接口了
      for (const item of this.commitLogs) {
        if (
          item.questionUuid === question.questionUuid &&
          equar(item.value, question.value.value) &&
          equar(item.file, question.value.file) &&
          equar(item.originalFile, question.value.originalFile)
        ) {
          await this.jumpToQuestion(index)
          return
        }
      }
      if (question.value) {
        this.saveCommitLogsToMemory()
        try {
          await this.saveAnwser()
          this.current = index
        } catch (error) {
          return
        }
      }
      await this.jumpToQuestion(index)
    },
    // 点击提交按钮
    async submit() {
      if (this.$store.state.isPractice) {
        this.showPracticeSubmit = true
        return
      }
      if (!this.isEnablePreviousQuestion) {
        const answer = this.question.value
        if (!answer || answer.value[0] === '') {
          this.showMustAnswer = true
          return
        }
      }
      const res = await Api.getPartInfo(this.$route.query.partUuid)
      if (res.code === 0) {
        this.isEnableModifyAfterSubmit = res.data.isEnableModifyAfterSubmit
      }
      if (this.isEnableModifyAfterSubmit) {
        this.showCanModConfirmSubmit = true
      } else {
        this.showConfirmSubmit = true
      }
    },
    // 提交试卷
    async submitPaper() {
      if (this.$store.state.isPractice) {
        this.showPracticeSubmit = false
      } else {
        if (!this.isEnableModifyAfterSubmit) {
          this.showCanModConfirmSubmit = false
        } else {
          this.showConfirmSubmit = false
        }
      }
      await this.nextQuestion(true)
      if (this.$store.state.isPractice) {
        this.$router.push('/exam?isPractice=true')
        if (this.isOccupyScreen) {
          this.exitFullscreen()
        }
        return
      }
      const res = await Api.partFinish(this.$route.query.partUuid)
      if (res.code === 0) {
        this.$router.push('/exam')
        if (this.isOccupyScreen) {
          this.exitFullscreen()
        }
      } else if (res.code === 30055) {
        this.showCanModConfirmSubmit = false
        this.showConfirmSubmit = false
        Api.getNotAnsweredList(this.$route.query.partUuid).then(res => {
          if (res.code === 0) {
            this.notAnsweredList = res.data
            this.showNotAnwserdList = true
          }
        })
      }
    },
    // 判断是否全屏
    isFullscreen(fn) {
      let fullscreenEnabled
      // 判断浏览器前缀
      if (document.fullscreenEnabled) {
        fullscreenEnabled = document.fullscreenEnabled
      } else if (document.webkitFullscreenEnabled) {
        fullscreenEnabled = document.webkitFullscreenEnabled
        this.prefixName = 'webkit'
      } else if (document.mozFullScreenEnabled) {
        fullscreenEnabled = document.mozFullScreenEnabled
        this.prefixName = 'moz'
      } else if (document.msFullscreenEnabled) {
        fullscreenEnabled = document.msFullscreenEnabled
        this.prefixName = 'ms'
      }
      if (!fullscreenEnabled) {
        if (fn !== undefined) fn() // 执行不支持全屏的回调
        this.isFullscreenData = false
      }
    },
    // 全屏浏览器
    Fullscreen() {
      const element = document.querySelector('body') // 获取dom
      const methodName =
        this.prefixName === ''
          ? 'requestFullscreen'
          : `${this.prefixName}RequestFullScreen` // API前缀
      // 调用全屏
      element[methodName]().catch(() => {
        this.showOccupyScreenTip = true
      })
    },
    // 退出全屏
    exitFullscreen() {
      const methodName =
        this.prefixName === ''
          ? 'exitFullscreen'
          : `${this.prefixName}ExitFullscreen` // API 前缀
      document[methodName]() // 调用
    },
    // 元素是否全屏
    isElementFullScreen() {
      const fullscreenElement =
        document.fullscreenElement ||
        document.msFullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement // 有前缀的f是大写，没前缀是小写
      if (fullscreenElement === null) {
        return false // 当前没有元素在全屏状态
      } else {
        return true // 有元素在全屏状态
      }
    },
    // 监听全屏事件
    screenChange(enter, quit) {
      if (!this.isFullscreenData) return
      const methodName = `on${this.prefixName}fullscreenchange`
      document[methodName] = (e) => {
        const isElementFullScreen = this.isElementFullScreen()
        if (isElementFullScreen) {
          enter && enter(e) // 进入全屏回调
        } else {
          quit && quit(e) // 离开全屏的回调
        }
      }
    },
    // 手动触发，重新开启全屏
    reOccupyScreen() {
      this.showOccupyScreenTip = false
      this.Fullscreen()
    },
    // 初始化blur事件
    initBlur() {
      this.removeBlur()
      // 监听浏览器的焦点事件,如果离开当前页面超过五秒钟，那么就会进行一次抓拍
      window.onblur = () => {
        // TODO document.hasFocus()
        let count = 1
        if (document.activeElement.tagName === 'IFRAME') return
        this.leaveTimer = window.setInterval(async() => {
          if (count >= 5) {
            clearInterval(this.leaveTimer)
            this.leaveTimer = null
            this.leaveTimer = window.setInterval(async() => {
              this.isBlur = false
              if (this.screenShotMonitorType === 0 || this.$store.state.isPractice) {
                return
              } else {
                count += 1
                // this.$children[0].browserSnap(true)
              }
            }, 30000)
          }
          this.isBlur = false
          if (this.screenShotMonitorType === 0 || this.$store.state.isPractice) {
            return
          } else {
            count += 1
            // this.$children[0].browserSnap(true)
          }
        }, 5000)
      }
      this.removeFocus()
      window.onfocus = async() => {
        if (document.activeElement.tagName === 'IFRAME') return
        window.clearInterval(this.leaveTimer)
        this.leaveTimer = null
        if (!this.isBlur) {
          const res = await Api.leave({
            partUuid: this.$route.query.partUuid,
            questionUuid: this.question.questionUuid,
            path: this.$route.path
          })
          this.leaveCount = res.data
          const remainCount =
            this.$store.state.examInfo.leaveCountLimit - this.leaveCount
          if (
            remainCount <= 3 &&
            this.$store.state.examInfo.leaveCountLimit !== 0
          ) {
            this.showYellowCard = true
          } else if (this.leaveCount >= 5) {
            this.showYellowCard = true
          } else {
            this.showLeavePageTip = true
          }
          this.isBlur = true
        }
      }
    },
    // 移除事件的handler 为避免重复添加，在所有事件添加之前，都进行移除。
    eventRemoveHandler() {
      console.log('event removed...')
    },
    //  移除 focus 事件
    removeFocus() {
      window.onfocus = null
    },
    //  移除 blur 事件
    removeBlur() {
      window.onblur = null
    },
    // 处理fullscreenchange和fullscreenerror的事件处理函数
    eventHandler(event) {
      if (event.type === 'fullscreenchange') {
        /* handle a full screen toggle */
        this.screenChange(
          () => console.log('进入全屏'),
          () => {
            const isElementFullScreen = this.isElementFullScreen()
            isElementFullScreen ? null : this.reOccupyScreen()
          }
        )
      } /* fullscreenerror */ else {
        /* handle a full screen toggle error */
        console.log('full screen toggle error!')
      }
    },
    // 处理全屏幕事件
    hanleFullScreen() {
      this.removeFullscreenChange()
      document.addEventListener('fullscreenchange', {
        handleEvent: this.eventHandler
      })
    },
    /**
     * 当用户强制刷新浏览器的时候，全屏可能会失效（Failed to execute 'requestFullscreen' on 'Element': API can only be initiated by a user gesture.）
     * 这是因为浏览器只允许通过用户的交互来实现全屏，其他的不允许
     * 但是经本人测试，并不是完全不允许，偶尔还是会成功的，故保留了成功结果，如果成功，刷新后还是保持全屏
     * 如果失败，则弹出警告页面，用户通过点击来实现全屏
     */
    hanleFullScreenError() {
      this.removeUnHandledRejection()
      window.addEventListener('unhandledrejection', (err) => {
        if (err.reason.message === 'fullscreen error') {
          this.showOccupyScreenTip = true
        }
      })
    },
    //  移除 unhandledrejection 事件
    removeUnHandledRejection() {
      window.removeEventListener('unhandledrejection', this.eventRemoveHandler)
    },
    //  移除 fullscreenChange 事件
    removeFullscreenChange() {
      document.removeEventListener('fullscreenchange', this.eventRemoveHandler)
    },
    // 移除所有事件监听
    removeAllEvent() {
      this.removeBlur()
      this.removeFocus()
      this.removeUnHandledRejection()
      this.removeFullscreenChange()
    },
    singleChange(val) {
      this.question.value = val
    },
    Replace(val) {
      return val.replace(/\n/g, '<br />')
    },
    async getCompanyName() {
      const examShortId = getItem('shortId')
      if (!examShortId || examShortId === 'undefined') {
        return
      }
      const res = await Api.getNameAndLogo({ examShortId })
      if (res.code === 0) {
        this.companyInfo = res.data
        this.companyName = res.data.companyShortName
      }
    },
    setGuide() {
      const ele = document.getElementsByClassName('service')
      const ele2 = document.querySelector('.icon-zuidahua')
      const hasGuide = getItem('hasGuide') || false
      if (hasGuide === 'true') return
      const steps = [
        {
          element: ele[0],
          intro: '在答题过程中，如遇到系统故障和试题问题,可以与工作人员联系。',
          position: 'top'
        },
        {
          element: ele2,
          intro: '如果题干过长，可以使用此功能悬浮题干，并调整题干位置和大小。',
          position: 'bottom'
        }
      ]
      this.$intro()
        .setOptions({
          prevLabel: '上一步',
          nextLabel: '下一步',
          skipLabel: '跳过',
          doneLabel: '我知道了',
          showBullets: false,
          steps: steps
        })
        .oncomplete(() => {
          // 点击结束按钮后执行的事件
        })
        .onexit(() => {
          // 点击跳过按钮后执行的事件
        })
        .start()
      setItem('hasGuide', true)
    },
    scrollToBottom() {
      const domWrapper = document.getElementById('left');
      (function smoothscroll() {
        const currentScroll = domWrapper.scrollTop // 已经被卷掉的高度
        const clientHeight = domWrapper.offsetHeight // 容器高度
        const scrollHeight = domWrapper.scrollHeight // 内容总高度
        if (scrollHeight - 10 > currentScroll + clientHeight) {
          window.requestAnimationFrame(smoothscroll)
          domWrapper.scrollTo(
            0,
            currentScroll +
              10 +
              (scrollHeight - currentScroll - clientHeight) / 2
          )
        }
      })()
    }
  }
}
</script>

<style>
.introjs-helperLayer {
  box-shadow: rgb(33 33 33 / 0%) 0px 0px 1px 2px,
    rgb(33 33 33 / 50%) 0px 0px 0px 5000px !important;
}
.introjs-button {
  border: 1px solid #cb2a1d;
  background-color: #fff;
  color: #e06563;
  text-shadow: none;
}
.introjs-tooltiptext {
  font-size: 14px;
  color: rgb(41, 41, 41);
  text-align: center;
}
.introjs-button {
  border: 1px solid #cb2a1d;
  color: #cb2a1d;
}
.introjs-button:hover {
  border: 1px solid #f35a5a;
  background-color: #f35a5a;
  color: #fff;
}
.introjs-button:focus {
  box-shadow: none;
  border: 1px solid #cb2a1d;
  background-color: #fff;
  color: #cb2a1d;
}
.introjs-button:active {
  border: 1px solid #cb2a1d;
  color: #fff;
}
.introjs-tooltip {
  max-width: 420px;
  min-width: 320px;
}
.introjs-skipbutton {
  font-size: 14px;
}
.known {
  cursor: pointer;
  text-align: center;
  border: 1px solid #cb2a1d;
  color: #cb2a1d;
  width: 100px;
  margin: 0 auto;
  padding: 8px 10px;
  margin-top: 20px;
  box-shadow: 0 0 51px rgba(224, 14, 14, 0.2);
}
</style>
<style lang="scss" scoped>
@import "~@/theme/variables.scss";
::v-deep .el-badge__content.is-dot {
  width: 13px;
  height: 13px;
}
.layout {
  .operate {
        height: 70px;
        z-index: 2;
        position: fixed;
        background-color: #fff;
        bottom: 0;
        box-sizing: border-box;
        padding: 0 20px;
        left: 0;
        border-top: 2px solid #ececec;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .btns {
          text-align: left;
          .pre_btn {
            border: 1px solid #cb2a1d;
            &:hover {
              color: #fff;
              background-color: #cb2a1d;
            }
          }
          .next_btn {
            background-color: rgb(203, 42, 29);
            border: 1px solid #cb2a1d;
            &:hover {
              background-color: rgba(203, 42, 29, 0.8);
            }
          }
          .gray_btn {
            background-color: #f5f5f5;
            color: #1e1e1e;
            border: 1px solid #c1c1c1;
            cursor: pointer;
            &:hover {
              background-color: #c1c1c1;
              color: #fff;
            }
          }
        }
        .tools {
        user-select: none;
        color: #999;
        font-size: 16px;
        vertical-align: middle;
        position: relative;
        .calculators,
        .service {
          line-height: 32px;
          cursor: pointer;
          &:hover {
            color: #f35a5a;
          }
        }
        .calculator-box {
          position: absolute;
          bottom: 60px;
          left: 0px;
        }
        .calculators {
          position: relative;
          margin-right: 64px;
          &:hover {
            color: #f35a5a;
          }
        }
        .iconfont {
          margin-right: 10px;
          font-size: 28px;
          line-height: 32px;
          vertical-align: middle;
        }
      }
        .tools {
          -webkit-user-select: none;
          -moz-user-select: none;
          -o-user-select: none;
          user-select: none;
          color: #999;
          font-size: 16px;
          vertical-align: middle;
          .pre_btn {
            background-color: rgb(203, 42, 29);
            border: 1px solid #cb2a1d;
            &:hover {
              color: #fff;
              background-color: #cb2a1d;
            }
          }
          .next_btn {
            background-color: rgb(203, 42, 29);
            border: 1px solid #cb2a1d;
            &:hover {
              background-color: rgba(203, 42, 29, 0.8);
            }
          }
        }
      }
  ::v-deep .el-loading-spinner {
    .circular {
      width: 120px;
      height: 120px;
    }
  }
  .prompt-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .title {
      font-weight: bold;
      font-size: 18px;
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      list-style: none;
      margin-top: 20px;
      margin-left: 20px;
      .detail {
        display: block;
        margin-bottom: 10px;
      }
      .detail::before {
        content: "\2022";
        color: #f35a5a;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      .iconfont {
        font-size: 100px;
        color: #f35a5a;
        margin-right: 20px;
      }
    }
  }
  .occupy-result {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .iconfont {
      font-size: 100px;
      color: #e06563;
    }
    .right {
      margin-left: 20px;
      .title2 {
        display: block;
        font-size: 18px;
        margin-bottom: 20px;
      }
      .title3 {
        display: block;
        font-size: 20px;
        color: #cb2a1d;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .title {
        display: block;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
        margin-top: 10px;
        letter-spacing: 2px;
      }
      .content {
        display: block;
      }
    }
  }
}
</style>
