var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "blank" }, [
    _c(
      "div",
      { staticClass: "blanks" },
      _vm._l(_vm.question.answerCount, function (item, index) {
        return _c("div", { key: index, staticClass: "blank-item" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.blankAnswerList[index],
                expression: "blankAnswerList[index]",
              },
            ],
            staticClass: "blank-input",
            attrs: { type: "text" },
            domProps: { value: _vm.blankAnswerList[index] },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.blankAnswerList, index, $event.target.value)
                },
                function ($event) {
                  return _vm.singleChange(_vm.blankAnswerList)
                },
              ],
            },
          }),
          _c("div", { staticClass: "label" }, [
            _vm._v(_vm._s(index + 1) + "、"),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }