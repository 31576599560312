var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "select" }, [
    _c(
      "div",
      { staticClass: "options" },
      [
        +_vm.question.type === 2 || +_vm.question.type === 7
          ? _c(
              "el-checkbox-group",
              {
                on: { change: _vm.singleChange },
                model: {
                  value: _vm.multiData,
                  callback: function ($$v) {
                    _vm.multiData = $$v
                  },
                  expression: "multiData",
                },
              },
              _vm._l(_vm.question.optionList, function (option, index) {
                return _c(
                  "div",
                  {
                    key: option.value,
                    class: _vm.multiData.includes(option.value)
                      ? "option2"
                      : "option",
                  },
                  [
                    _c(
                      "el-checkbox",
                      { key: option.value, attrs: { label: option.value } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "flex-start",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(_vm._s(_vm.optionMap[index]) + "、"),
                            ]),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.strReplace(option.title)),
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            )
          : _c(
              "el-radio-group",
              {
                on: { change: _vm.singleChange },
                model: {
                  value: _vm.singleData,
                  callback: function ($$v) {
                    _vm.singleData = $$v
                  },
                  expression: "singleData",
                },
              },
              _vm._l(_vm.question.optionList, function (option, index) {
                return _c(
                  "div",
                  {
                    key: option.value,
                    class:
                      _vm.singleData === option.value ? "option2" : "option",
                  },
                  [
                    _c(
                      "el-radio",
                      { key: option.value, attrs: { label: option.value } },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "justify-content": "flex-start",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("span", [
                              _vm._v(
                                " " + _vm._s(_vm.optionMap[index]) + "、 "
                              ),
                            ]),
                            _c("span", {
                              staticClass: "option-content",
                              domProps: {
                                innerHTML: _vm._s(_vm.strReplace(option.title)),
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }