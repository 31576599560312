<!--
 * @Author: Libra
 * @Date: 2022-01-18 17:38:38
 * @LastEditTime: 2022-12-28 10:10:03
 * @LastEditors: Libra
 * @Description:子母问答题
 * @FilePath: /stone-exam-ui/src/views/question/components/ClozeEssay.vue
-->
<template>
  <div class="cloze-container">
    <div
      class="questions"
      v-for="(item, index) in question.groupOptionList"
      :key="index"
    >
      <div class="describe">
        <span>第{{ index + 1 }}题 {{ item.typeName }}</span>
        <span
v-if="!$store.state.examInfo.isEvaluation"
style="color: #a58e54"
          >{{ item.questionScore }} 分</span
        >
      </div>
      <div class="sub-question" v-html="strReplace(item.body)"></div>
      <el-input
        type="textarea"
        :autosize="{ minRows: 4, maxRows: 8 }"
        v-model="answer[index]"
        @input="singleChange()"
        placeholder="请输入内容"
      ></el-input>
    </div>
  </div>
</template>

<script>
import { bus } from '@/utils/bus'
export default {
  props: ['question', 'commitLogs'],
  data() {
    return {
      answer: []
    }
  },
  created() {
    for (const item of this.commitLogs) {
      if (item.questionUuid === this.question.questionUuid) {
        const val = JSON.parse(JSON.stringify(item.value))
        const res = this.arrString(val)
        const res2 = []
        for (const item of res) {
          res2.push(item[0])
        }
        this.answer = res2
      }
    }
  },
  mounted() {
    // 每两分钟自动调一次保存答案
    const t = setInterval(() => {
      console.log('字母问答题两分钟自动保存答案')
      bus.$emit('saveAnswer')
    }, 120000)
    this.$once('hook:beforeDestroy', () => {
      clearInterval(t)
    })
  },
  methods: {
    strArray(arr) {
      return arr.map(item => JSON.stringify(item))
    },
    arrString(arr) {
      return arr.map(item => JSON.parse(item))
    },
    strReplace(str) {
      if (!str) return
      const s = str.replace(/&lt;/g, '<')
      return s.replace(/&gt;/g, '>')
    },
    handleAnwser(arr) {
      const res = []
      for (const item of arr) {
        res.push([item])
      }
      return res
    },
    singleChange() {
      const value = this.strArray(this.handleAnwser(this.answer))
      this.$emit('singleChange', {
        value,
        file: null,
        originalFile: null
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
