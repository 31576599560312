var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "qus-container" }, [
    _c("div", { staticClass: "left", attrs: { id: "left" } }, [
      _c(
        "div",
        { staticClass: "question", staticStyle: { "padding-right": "50px" } },
        [
          _c("div", { staticClass: "title" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$store.state.examInfo.coverInfo.shortName +
                    _vm.$store.state.examInfo.userExamName +
                    _vm.$store.state.jobInfo.name
                ) +
                " "
            ),
            _c(
              "span",
              {
                staticStyle: {
                  "font-weight": "normal",
                  "font-size": "14px",
                  color: "#888",
                },
              },
              [_vm._v(_vm._s(_vm.$store.state.examInfo.scenario))]
            ),
          ]),
          _c("div", { staticClass: "question-type" }, [
            _c("div", [
              _vm._v(
                " " +
                  _vm._s(_vm.current + 1) +
                  "、" +
                  _vm._s(_vm.typeMap[_vm.question.type]) +
                  " "
              ),
              !_vm.$store.state.examInfo.isEvaluation
                ? _c("span", { staticClass: "score" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$store.state.isPractice ||
                          _vm.$route.query.isQuestionnaire === "true"
                          ? "--"
                          : _vm.question.questionScore
                      )
                    ),
                    _c("span", { staticStyle: { "font-size": "12px" } }, [
                      _vm._v("分"),
                    ]),
                  ])
                : _vm._e(),
              (_vm.question.type === 2 || _vm.question.type === 7) &&
              !_vm.$store.state.isPractice
                ? _c(
                    "span",
                    {
                      staticStyle: {
                        "font-size": "14px",
                        color: "#888",
                        "margin-left": "10px",
                      },
                    },
                    [
                      _vm.question.scoringRoleType === 0
                        ? _c("span", [
                            _vm._v(
                              "（计分方式：错选、多选或少选均不得分，全对得分）"
                            ),
                          ])
                        : _c("span", [
                            _vm._v(
                              "（计分方式：错选、多选不得分，少选得答对数量比例的分）"
                            ),
                          ]),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm.isEnablePreviousQuestion
              ? _c(
                  "div",
                  {
                    staticClass: "toggle-nail",
                    class: [_vm.isRemark ? "remark" : "not-remark"],
                    on: {
                      click: function ($event) {
                        return _vm.remark(_vm.question.questionUuid)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "iconfont iconbiaojibeifen" }),
                    _c("span", { staticClass: "mark" }, [_vm._v("标记")]),
                  ]
                )
              : _vm._e(),
          ]),
          +_vm.question.type === 16
            ? _c("question-interview", {
                key: _vm.question.questionUuid,
                ref: "interview",
                attrs: { question: _vm.question, commitLogs: _vm.commitLogs },
                on: { singleChange: _vm.singleChange },
              })
            : _vm._e(),
          _c(
            "div",
            {
              ref: "questionBody",
              class: [
                _vm.question.isWhiteBackground
                  ? "question-body2"
                  : "question-body",
              ],
            },
            [
              _c(
                "div",
                { staticClass: "float" },
                [
                  _c(
                    "el-tooltip",
                    {
                      staticClass: "item",
                      attrs: {
                        effect: "dark",
                        content: "拖拽题干移动位置，拖拽边框调整大小",
                        placement: "top-end",
                      },
                    },
                    [
                      _vm.isFold
                        ? _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.floatOn.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12px" } },
                                [_vm._v("拖拽题干 ")]
                              ),
                              _c("i", {
                                staticClass: "iconfont icon-zuidahua",
                              }),
                            ]
                          )
                        : _c(
                            "div",
                            {
                              on: {
                                click: function ($event) {
                                  $event.stopPropagation()
                                  return _vm.floatOff.apply(null, arguments)
                                },
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticStyle: { "font-size": "12px" } },
                                [_vm._v("还原题干 ")]
                              ),
                              _c("i", {
                                staticClass: "iconfont iconzuixiaohua",
                              }),
                            ]
                          ),
                    ]
                  ),
                ],
                1
              ),
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(_vm.strReplace(_vm.question.body)),
                },
              }),
              _vm.question.voicePath
                ? _c(
                    "div",
                    { staticClass: "audio" },
                    [
                      _c("audio-player", {
                        key: _vm.question.questionUuid,
                        attrs: {
                          src: _vm.question.voicePath,
                          playCount: _vm.playCount,
                        },
                        on: { subCount: _vm.subCount },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          +_vm.question.type === 1 ||
          +_vm.question.type === 2 ||
          +_vm.question.type === 3 ||
          +_vm.question.type === 7
            ? _c("question-select", {
                key: _vm.question.questionUuid,
                attrs: { question: _vm.question, commitLogs: _vm.commitLogs },
                on: { singleChange: _vm.singleChange },
              })
            : _vm._e(),
          +_vm.question.type === 4
            ? _c("question-blank", {
                key: _vm.question.questionUuid,
                attrs: { question: _vm.question, commitLogs: _vm.commitLogs },
                on: { singleChange: _vm.singleChange },
              })
            : _vm._e(),
          +_vm.question.type === 5
            ? _c("question-short-answer", {
                key: _vm.question.questionUuid,
                attrs: { question: _vm.question, commitLogs: _vm.commitLogs },
                on: { singleChange: _vm.singleChange },
              })
            : _vm._e(),
          +_vm.question.type === 14
            ? _c("question-audio", {
                key: _vm.question.questionUuid,
                attrs: { question: _vm.question, commitLogs: _vm.commitLogs },
                on: { singleChange: _vm.singleChange },
              })
            : _vm._e(),
          +_vm.question.type === 13
            ? _c("question-cloze", {
                key: _vm.question.questionUuid,
                attrs: { question: _vm.question, commitLogs: _vm.commitLogs },
                on: { singleChange: _vm.singleChange },
              })
            : _vm._e(),
          +_vm.question.type === 15
            ? _c("question-video", {
                key: _vm.question.questionUuid,
                attrs: { question: _vm.question, commitLogs: _vm.commitLogs },
                on: { singleChange: _vm.singleChange },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "right" }, [
      _c("div", { staticClass: "top-container" }, [
        _c("div", { staticClass: "user" }, [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.$store.state.isPractice,
                  expression: "!$store.state.isPractice",
                },
              ],
            },
            [
              _c("div", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.$store.state.cameraDetect,
                    expression: "$store.state.cameraDetect",
                  },
                ],
                attrs: { id: "video-right" },
              }),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.$store.state.cameraDetect,
                      expression: "!$store.state.cameraDetect",
                    },
                  ],
                  staticClass: "defaultAvatar",
                },
                [_c("i", { staticClass: "iconfont iconuser" })]
              ),
            ]
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.$store.state.isPractice,
                  expression: "$store.state.isPractice",
                },
              ],
            },
            [
              _vm.showVideo
                ? _c("video", {
                    attrs: {
                      id: "video",
                      muted: "muted",
                      width: "272",
                      height: "240",
                    },
                    domProps: { muted: true },
                  })
                : _vm._e(),
            ]
          ),
          _c("div", { staticClass: "full-name" }, [
            _vm._v(_vm._s(_vm.$store.state.userInfo.realName)),
          ]),
        ]),
        +_vm.question.type !== 16
          ? _c("div", { staticClass: "tag-con" }, [
              _c("div", { staticClass: "question-tags" }, [
                _c("div", { staticClass: "top" }, [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.$route.query.name)),
                  ]),
                  _c("div", { staticClass: "process" }, [
                    _c("span", { staticClass: "current" }, [
                      _vm._v(_vm._s(_vm.current + 1)),
                    ]),
                    _vm._v("/"),
                    _c("span", { staticClass: "total" }, [
                      _vm._v(_vm._s(_vm.total)),
                    ]),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "tags" },
                  _vm._l(_vm.questionIdList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        class: [
                          `tag ${index === _vm.current ? "current" : ""}`,
                          `${_vm.remarkList.includes(item) ? "nailed" : ""}`,
                          `${
                            _vm.answerIdList.includes(item) ? "answered" : ""
                          }`,
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.jumpToQuestionInRight(index)
                          },
                        },
                      },
                      [_vm._v(" " + _vm._s(index + 1) + " ")]
                    )
                  }),
                  0
                ),
              ]),
            ])
          : _vm._e(),
        +_vm.question.type !== 16
          ? _c("div", { staticClass: "bottom" }, [_vm._m(0)])
          : _vm._e(),
        +_vm.question.type === 16 && _vm.interviewLoaded
          ? _c(
              "div",
              { staticClass: "video-record" },
              [
                _c("i", { staticClass: "iconfont iconjishi1" }),
                this.$refs.interview.readMode === 1 &&
                !this.$refs.interview.answerUrl &&
                this.$refs.interview.remainReadTime > 0
                  ? _c("div", { staticClass: "time" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatSeconds(this.$refs.interview.remainReadTime)
                        )
                      ),
                    ])
                  : _c("div", { staticClass: "time" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatSeconds(
                            this.$refs.interview.remainAnswerTimer
                          )
                        )
                      ),
                    ]),
                this.$refs.interview.readMode === 1 &&
                !this.$refs.interview.answerUrl &&
                this.$refs.interview.remainReadTime > 0
                  ? _c("div", { staticClass: "remain" }, [
                      _vm._v("阅读试题 剩余时间"),
                    ])
                  : _c("div", { staticClass: "remain" }, [
                      _vm._v("录制视频 剩余时间"),
                    ]),
                this.$refs.interview.readMode === 1 &&
                !this.$refs.interview.answerUrl &&
                this.$refs.interview.remainReadTime > 0
                  ? _c("div", { staticClass: "tip" }, [
                      _vm._v("读题结束后，自动开始录制面试视频"),
                      _c("br"),
                      _vm._v("请提前做好答题准备!"),
                    ])
                  : _vm._e(),
                (this.$refs.interview.readMode === 0 ||
                  this.$refs.interview.remainReadTime === 0) &&
                !this.$refs.interview.answerUrl
                  ? _c("CustomButton", {
                      attrs: {
                        title: "录制视频",
                        isPlain: false,
                        hasIcon: true,
                        classStr: "cusBtn",
                        iconName: "iconfont iconvideo2-fill",
                        clickMethod: () => {
                          this.$refs.interview.startRecord(
                            this.$refs.interview.remainAnswerTimer
                          )
                        },
                      },
                    })
                  : _vm._e(),
                _vm.questionIdList.length > 1
                  ? _c(
                      "div",
                      { staticClass: "problems" },
                      _vm._l(_vm.questionIdList, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "pro-con" },
                          [
                            _c("div", {
                              class: [
                                `point ${
                                  index === _vm.current ? "currentv" : ""
                                }`,
                                `${
                                  _vm.remarkList.includes(item) ? "nailedv" : ""
                                }`,
                              ],
                            }),
                            _c("div", { staticClass: "num" }, [
                              _vm._v(_vm._s(index + 1)),
                            ]),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "sub_btn_container" },
        [
          _c(
            "el-button",
            {
              staticClass: "pre_btn",
              staticStyle: { width: "206px" },
              attrs: { type: "primary", plain: "" },
              on: { click: _vm.submit },
            },
            [_vm._v("提交当前小卷")]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "tips" }, [
      _c("div", { staticClass: "tip" }, [
        _c("div", { staticClass: "type" }, [_vm._v("已答")]),
        _c("span", { staticClass: "shape answered" }),
      ]),
      _c("div", { staticClass: "tip" }, [
        _c("div", { staticClass: "type" }, [_vm._v("未答")]),
        _c("span", { staticClass: "shape" }),
      ]),
      _c("div", { staticClass: "tip" }, [
        _c("div", { staticClass: "type" }, [_vm._v("当前")]),
        _c("span", { staticClass: "shape current" }),
      ]),
      _c("div", { staticClass: "tip" }, [
        _c("div", { staticClass: "type" }, [_vm._v("标记")]),
        _c("span", { staticClass: "shape nailed" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }