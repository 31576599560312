<!--
 * @Author: Libra
 * @Date: 2021-10-09 18:51:57
 * @LastEditTime: 2022-11-15 15:03:45
 * @LastEditors: Libra
 * @Description: 自定义音频播放器
 * @FilePath: /stone-exam-ui/src/components/audioPlayer/index.vue
-->
<template>
  <div class="audio-container">
    <div class="holder">
      <div class="audio green-audio-player">
        <div class="play-pause">
          <div v-if="!disabled">
            <i v-if="isPlaying && canPlay" class="iconfont iconpause-full"></i>
            <i
              v-else-if="!isPlaying && canPlay"
              class="iconfont iconicon_bofang"
              @click="play"
            ></i>
            <i v-else class="iconfont iconjiazai1"></i>
          </div>
          <div v-else>
            <i class="iconfont iconjinzhi"></i>
          </div>
        </div>

        <div class="controls">
          <span class="current-time">{{ formatTime(currentTime) }}</span>
          <el-progress
            style="width: 100%"
            :show-text="false"
            :percentage="(currentTime / totalTime) * 100 || 0"
          ></el-progress>
          <span class="total-time">{{ formatTime(totalTime) }}</span>
        </div>
        <audio preload="auto" ref="myAudio">
          <source :src="src" type="audio/mpeg" />
        </audio>
      </div>
      <span v-if="canPlay">{{
        playCount === 99 ? '' : `（剩余播放次数：${playCount}）`
      }}</span>
      <span v-else>（音频正在加载中...）</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    playCount: {
      type: Number,
      default: 0
    },
    src: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      isPlaying: false,
      myAudio: null,
      canPlay: false,
      currentTime: null,
      totalTime: null,
      disabled: false
    }
  },
  watch: {
    playCount() {
      this.disabled = this.playCount === 0
    }
  },
  created() {},
  mounted() {
    this.initPlayer()
    setTimeout(() => {
      this.disabled = this.playCount === 0
    }, 100)
  },
  methods: {
    initPlayer() {
      this.myAudio = this.$refs.myAudio
      this.myAudio.addEventListener('durationchange', () => {
        this.totalTime = this.myAudio.duration
      })
      this.myAudio.addEventListener('timeupdate', () => {
        this.currentTime = this.myAudio.currentTime.toFixed(2)
      })
      this.myAudio.addEventListener('canplay', () => {
        this.canPlay = true
      })
      this.myAudio.addEventListener('play', () => {
        if (this.playCount < 0) {
          this.myAudio.pause()
        }
      })
      this.myAudio.addEventListener('ended', () => {
        this.isPlaying = false
        if (this.playCount === 0) {
          this.disabled = true
        }
      })
    },
    play() {
      this.isPlaying = true
      this.myAudio.play()
      if (this.playCount < 99) {
        this.$emit('subCount', this.playCount)
      }
    },
    pause() {
      this.isPlaying = false
      this.myAudio.pause()
    },
    formatTime(time) {
      const min = Math.floor(time / 60).toString()
      const sec = Math.floor(time % 60)
        .toString()
        .padStart(2, '0')
      return `${min}:${sec}`
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
