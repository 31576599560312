<!--
 * @Author: Libra
 * @Date: 2023-04-13 12:00:21
 * @LastEditTime: 2023-10-30 17:25:32
 * @LastEditors: Libra
 * @Description:
-->
<template>
  <div
    class="question"
  >
    <div class="left" id="left" >
      <div class="question-type">
      <div>
        {{ current + 1 }}、编程题
        <span
v-if="!$store.state.examInfo.isEvaluation"
class="score"
          >{{
            $store.state.isPractice || $route.query.isQuestionnaire === 'true'
              ? '--'
              : question.questionScore
          }}<span style="font-size: 12px;">分</span></span
        >
      </div>
      <div class="toggle-nail">
        <span
          ><span style="color: #f35a5a; font-size: 18px;">{{
            current + 1
          }}</span
          >/{{ total }}</span
        >
      </div>
    </div>
    <div
      :class="[question.isWhiteBackground ? 'question-body2' : 'question-body']"
    >
      <div class="title" v-html="strReplace(question.body)"></div>
      <div class="limit">
        <span class="sub-title">时间限制</span>：C/C++语言
        {{ question.codeTimeLimit }}s / 其他语言
        {{ question.codeOtherTimeLimit }}s<br /><span
class="sub-title"
          >内存限制</span
        >：C/C++语言 {{ question.codeMemoryLimit }}MB / 其他语言
        {{ question.codeOtherMemoryLimit }}MB<br /><br />
      </div>
      <span class="sub-title">题目描述</span>：
      <div
        class="codeDescription"
        v-html="strReplace(question.codeDescription)"
      ></div>
    </div>
    <div
      :class="[question.isWhiteBackground ? 'question-body2' : 'question-body']"
      class="breakBr"
    >
      <span
class="group"
        ><span class="sub-title">输入描述：</span
        ><span>{{ strReplace(question.codeInput) }}</span></span
      >
      <br />
      <span class="group">
        <span class="sub-title">输出描述：</span
        ><span>{{ strReplace(question.codeOutput) }}</span>
      </span>
    </div>
    <div
      :class="[question.isWhiteBackground ? 'question-body2' : 'question-body']"
      class="breakBr"
    >
      <span
class="group"
        ><span class="sub-title">输入样例：</span
        ><span>{{ strReplace(question.codeInputExample) }}</span></span
      >
      <br />
      <span class="group">
        <span class="sub-title">输出样例：</span
        ><span>{{ strReplace(question.codeOutputExample) }}</span>
      </span>
    </div>
    </div>
    <div class="right-container">
      <div class="right2">
        <write-and-submit
          class="editor"
          ref="writeAndSubmit"
          @changeTime="changeTime"
          @singleChange="singleChange"
          :question="question"
          :commitLogs="commitLogs"
          v-show="!showEditorOrTestData"
        ></write-and-submit>
        <div v-show="showEditorOrTestData" class="commit-log">
          <div class="title">运行记录</div>
          <i
            class="el-icon-close"
            @click="showEditorOrTestData = false"
          ></i>
          <el-table :data="tableData" style="width: 100%;margin-top:20px;">
            <el-table-column
              type="index"
              align="center"
              width="50px"
              label="编号"
            >
            </el-table-column>
            <el-table-column
              prop="language"
              align="center"
              width="150px"
              label="语言"
            >
            </el-table-column>
            <el-table-column
              prop="isUserTestCase"
              width="100px"
              align="center"
              label="自测用例"
            >
              <template slot-scope="scope">
                <span style="margin-left: 10px">{{
                  scope.row.isUserTestCase ? "是" : "否"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              align="center"
              prop="sourceCode"
              label="代码详情"
            >
              <template slot-scope="scope">
                <el-tooltip
                  class="item"
                  effect="dark"
                  placement="top-start"
                >
                  <div
                    slot="content"
                    v-html="Replace(scope.row.sourceCode)"
                  ></div>
                  <i class="el-icon-warning"></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="resultMessage" label="编译结果">
            </el-table-column>
            <el-table-column
              align="center"
              prop="passRate"
              width="100px"
              label="AC通过率"
            >
              <template slot-scope="scope">
                <span>{{ scope.row.passRate || 0 }}%</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="compileOutput"
              label="编译详情"
              align="center"
            >
              <template slot-scope="scope" v-if="scope.row.compileOutput">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="scope.row.compileOutput"
                  placement="top-start"
                >
                  <i class="el-icon-warning"></i>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column prop="runAt" label="提交时间">
            </el-table-column>
          </el-table>
        </div>
        <div class="operate">
          <div class="btns">
            <el-button
              class="pre_btn gray_btn"
              type="primary"
              plain
              @click="commitLog"
              >运行记录</el-button
            >
            <el-button
              class="next_btn gray_btn"
              type="primary"
              @click="selfTest"
              >自测数据</el-button
            >
          </div>
          <div class="tools">
            <el-button
@click="saveCode"
plain
type="primary"
              >保存</el-button
            >
            <el-button
              :disabled="runTime !== 0"
              class="next_btn"
              :style="
                runTime === 0 ? null : 'background-color:#ccc;border:none;'
              "
              type="primary"
              @click="codeRun"
              >{{ runTime === 0 ? "" : runTime }}运行代码</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api'
import WriteAndSubmit from '../../components/WriteAndSubmit.vue'

export default {
  name: 'Question',
  components: {
    WriteAndSubmit
  },
  props: ['current', 'question', 'commitLogs', 'total'],
  data() {
    return {
      prefixName: '',
      isFold: true,
      runTime: 0,
      tableData: [],
      isEnablePreviousQuestion: true,
      showEditorOrTestData: false
    }
  },
  watch: {
    question: function(newQuestion) {
      if (newQuestion.type === 6) {
        this.showEditorOrTestData = false
        this.$nextTick(() => {
          this.$refs.writeAndSubmit.selfTest(false)
          this.$refs.writeAndSubmit.closeRunDialog()
        })
      }
    }
  },
  created() {
    this.isEnablePreviousQuestion =
      this.$route.query.isEnablePreviousQuestion === 'true'
  },
  methods: {
    backTop() {
      document.documentElement.scrollTop = 0
    },
    Replace(val) {
      return val.replace(/\n/g, '<br />')
    },
    singleChange(val) {
      this.question.value = val
    },
    // 自测
    selfTest() {
      this.$refs.writeAndSubmit.selfTest()
    },
    changeTime(val) {
      this.runTime = val
    },
    // 用户刷新的时候，并不会监听 $route ,所以需要自动判断url中的params
    // 进而更新当前 current 和 question
    handleRefresh() {
      this.current = +this.$route.query.current || 0
      this.question = this.questions[this.current]
      this.backTop()
    },
    // 字符串替换
    strReplace(str) {
      if (!str) return
      const s = str.replace(/&lt;/g, '<')
      return s.replace(/&gt;/g, '>')
    },
    breakToBr(str) {
      const a = JSON.stringify(str)
      return a.replace(/\n/g, '<br />')
    },
    // 提交记录
    async commitLog() {
      this.showEditorOrTestData = true
      const res = await Api.getAllResult(this.question.questionUuid)
      if (res.code === 0) {
        this.tableData = res.data
      } else {
        console.log('获取失败！！')
      }
    },
    // 代码运行结果
    async codeRun() {
      await this.$refs.writeAndSubmit.codeRun(false)
      await this.saveCode(false)
    },
    saveCode(hasDialog) {
      this.$parent.saveCode(hasDialog)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
