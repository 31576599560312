var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "editor-container" },
    [
      _c("div", { staticStyle: { position: "relative" } }, [
        _c("div", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.question.isShowAnswerBox,
              expression: "question.isShowAnswerBox",
            },
          ],
          attrs: { id: "editor" },
        }),
        _c("span", { staticClass: "text-num" }, [
          _vm._v(_vm._s(_vm.textNum) + " 字符"),
        ]),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "插入代码",
            visible: _vm.showInsertCode,
            "before-close": _vm.clearContent,
            width: "800px",
            modal: false,
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showInsertCode = $event
            },
          },
        },
        [
          _c(
            "el-select",
            {
              staticStyle: { width: "200px", "margin-bottom": "10px" },
              attrs: { placeholder: "请选择", size: "small" },
              on: { change: _vm.onEditorModeChange },
              model: {
                value: _vm.cmEditorMode,
                callback: function ($$v) {
                  _vm.cmEditorMode = $$v
                },
                expression: "cmEditorMode",
              },
            },
            _vm._l(_vm.cmEditorModeOptions, function (item) {
              return _c("el-option", {
                key: item.name,
                attrs: { label: item.name, value: item.type },
              })
            }),
            1
          ),
          _c("code-mirror-editor", {
            ref: "cmEditor",
            staticStyle: {
              "font-size": "16px",
              height: "400px",
              "margin-bottom": "10px",
            },
            attrs: {
              "cm-mode": _vm.cmMode,
              "auto-format-json": true,
              "json-indentation": 2,
            },
          }),
          _c(
            "div",
            { staticClass: "insert" },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.insert } },
                [_vm._v("插入")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "画图板",
            visible: _vm.showWhiteBoard,
            "modal-append-to-body": false,
            width: "940px",
            modal: false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showWhiteBoard = $event
            },
          },
        },
        [
          _c("vue-drawing", {
            attrs: { width: 900, height: 400 },
            on: { "image-export": _vm.insertPaint },
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "手机扫码传图",
            visible: _vm.showPhoneUpload,
            "before-close": _vm.beforePhoneUploadClose,
            width: "600px",
            modal: false,
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPhoneUpload = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content-contaienr" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.qrLoading,
                      expression: "qrLoading",
                    },
                  ],
                  staticClass: "qr-container",
                  staticStyle: { width: "200px", height: "200px" },
                  attrs: { "element-loading-text": "二维码生成中" },
                },
                [
                  _c("vue-qr", {
                    staticStyle: { width: "200px", height: "200px" },
                    attrs: { size: 800, text: _vm.qrContent },
                  }),
                  _vm.isFailure
                    ? _c(
                        "div",
                        { staticClass: "refresh-qr" },
                        [
                          _c("span", { staticClass: "failure" }, [
                            _vm._v("二维码已失效"),
                          ]),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "danger" },
                              on: { click: _vm.refresh },
                            },
                            [_vm._v("请点击刷新")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("span", { staticClass: "upload-label" }, [
                _vm._v("手机微信扫码"),
              ]),
              _c("span", { staticClass: "upload-tips" }, [
                _vm._v("点击下方按钮完成上传"),
              ]),
              _c(
                "el-button",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { type: "danger", loading: _vm.isGettingImage },
                  on: { click: _vm.getImage },
                },
                [_vm._v("立即上传")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialogVisible,
            modal: false,
            title: "上传文件",
            width: "40%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _vm.flag
            ? _c("el-progress", {
                staticStyle: {
                  width: "100%",
                  "font-size": "12px",
                  "margin-bottom": "10px",
                },
                attrs: {
                  "text-inside": true,
                  "stroke-width": 15,
                  percentage: _vm.progressPercent,
                },
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "files" },
            _vm._l(_vm.fileList, function (item, index) {
              return _c("div", { key: index, staticClass: "file" }, [
                _c("span", [_vm._v(_vm._s(item.name))]),
                _c("i", {
                  staticClass: "el-icon-close",
                  on: {
                    click: function ($event) {
                      return _vm.deleteFile(index)
                    },
                  },
                }),
              ])
            }),
            0
          ),
          _c(
            "el-upload",
            {
              ref: "upload",
              staticClass: "editor-slide-upload",
              attrs: {
                "file-list": _vm.fileList,
                "show-file-list": false,
                "before-upload": _vm.beforeUpload,
                "http-request": _vm.uploadHttp,
                accept:
                  ".jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.zip,.webp",
                action: "",
              },
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-top": "10px" },
                  attrs: { size: "small", type: "danger" },
                },
                [_vm._v(" 选择文件 ")]
              ),
              _c("div", { staticStyle: { "margin-top": "30px" } }, [
                _vm._v(" 支持的格式：jpg、jpeg、png、doc、docx、xls"),
                _c("br"),
                _vm._v("xlsx、ppt、pptx、zip "),
                _c("br"),
                _vm._v("最多支持上传1个文件，且文件不能超过30MB "),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "btn_container" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small", type: "danger", plain: "" },
                  on: {
                    click: () => {
                      _vm.$refs.upload.abort()
                      _vm.fileList = []
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v(" 取消 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !_vm.fileList.length,
                    size: "small",
                    type: "danger",
                  },
                  on: { click: _vm.handleSubmit },
                },
                [_vm._v(" 上传 ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { title: "数学公式", visible: _vm.mathDialog, width: "600px" },
          on: {
            "update:visible": function ($event) {
              _vm.mathDialog = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content" },
            [_c("math-editor", { on: { latex: _vm.latex } })],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.mathDialog = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.insertMath } },
                [_vm._v("插 入")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "editor-btns" },
        [
          _vm.question.plugins.ieEnableFormula
            ? _c(
                "el-button",
                {
                  staticClass: "editor-btn",
                  attrs: {
                    icon: "iconfont iconzu",
                    size: "mini",
                    type: "danger",
                  },
                  on: {
                    click: function ($event) {
                      _vm.mathDialog = true
                    },
                  },
                },
                [_vm._v(" 公式 ")]
              )
            : _vm._e(),
          _vm.question.plugins.ieEnableCodeTemplate
            ? _c(
                "el-button",
                {
                  staticClass: "editor-btn",
                  attrs: {
                    icon: "iconfont iconcharudaima",
                    size: "mini",
                    type: "danger",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showInsertCode = true
                    },
                  },
                },
                [_vm._v(" 代码 ")]
              )
            : _vm._e(),
          _vm.question.plugins.ieEnablePaint
            ? _c(
                "el-button",
                {
                  staticClass: "editor-btn",
                  attrs: {
                    icon: "iconfont iconsheji_huatu",
                    size: "mini",
                    type: "danger",
                  },
                  on: {
                    click: function ($event) {
                      _vm.showWhiteBoard = true
                    },
                  },
                },
                [_vm._v(" 画图 ")]
              )
            : _vm._e(),
          _vm.question.plugins.ieEnableMobilePhone
            ? _c(
                "el-button",
                {
                  staticClass: "editor-btn",
                  attrs: {
                    icon: "el-icon-upload",
                    size: "mini",
                    type: "danger",
                  },
                  on: {
                    click: () => {
                      _vm.showPhoneUpload = true
                      _vm.getMobileToken()
                      _vm.refreshQR()
                    },
                  },
                },
                [_vm._v(" 手机图片上传 ")]
              )
            : _vm._e(),
          _vm.question.plugins.isEnableFileUpload
            ? _c(
                "el-button",
                {
                  staticClass: "editor-btn",
                  attrs: {
                    icon: "el-icon-upload",
                    size: "mini",
                    type: "danger",
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = true
                    },
                  },
                },
                [_vm._v(" 本机文件上传 ")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }