var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cloze-container" },
    _vm._l(_vm.question.groupOptionList, function (item, index) {
      return _c(
        "div",
        { key: index, staticClass: "questions" },
        [
          _c("div", { staticClass: "describe" }, [
            _c("span", [
              _vm._v("第" + _vm._s(index + 1) + "题 " + _vm._s(item.typeName)),
            ]),
            !_vm.$store.state.examInfo.isEvaluation
              ? _c("span", { staticStyle: { color: "#a58e54" } }, [
                  _vm._v(_vm._s(item.questionScore) + " 分"),
                ])
              : _vm._e(),
          ]),
          _c("div", {
            staticClass: "sub-question",
            domProps: { innerHTML: _vm._s(_vm.strReplace(item.body)) },
          }),
          _c("el-input", {
            attrs: {
              type: "textarea",
              autosize: { minRows: 4, maxRows: 8 },
              placeholder: "请输入内容",
            },
            on: {
              input: function ($event) {
                return _vm.singleChange()
              },
            },
            model: {
              value: _vm.answer[index],
              callback: function ($$v) {
                _vm.$set(_vm.answer, index, $$v)
              },
              expression: "answer[index]",
            },
          }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }