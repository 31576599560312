<!--
 * @Author: Libra
 * @Date: 2021-09-15 10:51:19
 * @LastEditTime: 2022-11-15 15:06:22
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/components/Calculator/index.vue
-->
<template>
  <div class="calculator">
    <i class="el-icon-close" @click="closeCalculator"></i>
    <button @click="changeModeEvent" class="toggle-button">
      <p v-if="changeMode">切换高级模式 ⚈</p>
      <p v-else>切换简单模式 ⚆</p>
    </button>
    <div class="results">
      <input class="input" v-model="current" />
    </div>
    <div class="mode" v-if="changeMode">
      <button class="button" @click="press">7</button>
      <button class="button" @click="press">8</button>
      <button class="button" @click="press">9</button>
      <button class="button" @click="press">×</button>
      <button class="button" @click="press">&lt;=</button>
      <button class="button" @click="press">C</button>
      <button class="button" @click="press">4</button>
      <button class="button" @click="press($event)">5</button>
      <button class="button" @click="press">6</button>
      <button class="button" @click="press">÷</button>
      <button class="button" @click="press">(</button>
      <button class="button" @click="press">)</button>
      <button class="button" @click="press">1</button>
      <button class="button" @click="press">2</button>
      <button class="button" @click="press">3</button>
      <button class="button" @click="press">-</button>
      <button class="button" @click="press">x 2</button>
      <button class="button" @click="press">±</button>
      <button class="button" @click="press">0</button>
      <button class="button" @click="press">.</button>
      <button class="button" @click="press">%</button>
      <button class="button" @click="press">+</button>
      <button class="button equal-sign" @click="press">=</button>
    </div>
    <div class="mode" v-else>
      <button class="button" @click="press">sin</button>
      <button class="button" @click="press">cos</button>
      <button class="button" @click="press">tan</button>
      <button class="button" @click="press">x^</button>
      <button class="button" @click="press">&lt;=</button>
      <button class="button" @click="press">C</button>
      <button class="button" @click="press">log</button>
      <button class="button" @click="press">ln</button>
      <button class="button" @click="press">e</button>
      <button class="button" @click="press">°</button>
      <button class="button" @click="press">rad</button>
      <button class="button" @click="press">√</button>
      <button class="button" @click="press">7</button>
      <button class="button" @click="press">8</button>
      <button class="button" @click="press">9</button>
      <button class="button" @click="press">/</button>
      <button class="button" @click="press">x 2</button>
      <button class="button" @click="press">x !</button>
      <button class="button" @click="press">4</button>
      <button class="button" @click="press">5</button>
      <button class="button" @click="press">6</button>
      <button class="button" @click="press">*</button>
      <button class="button" @click="press">(</button>
      <button class="button" @click="press">)</button>
      <button class="button" @click="press">1</button>
      <button class="button" @click="press">2</button>
      <button class="button" @click="press">3</button>
      <button class="button" @click="press">-</button>
      <button class="button" @click="press">%</button>
      <button class="button" @click="press">±</button>
      <button class="button" @click="press">0</button>
      <button class="button" @click="press">.</button>
      <button class="button" @click="press">π</button>
      <button class="button" @click="press">+</button>
      <button class="button equal-sign" @click="press">=</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Calculator',
  data() {
    return {
      current: '',
      changeMode: true
    }
  },
  methods: {
    press: function(event) {
      const me = this
      const key = event.target.textContent
      if (
        key !== '=' &&
        key !== 'C' &&
        key !== '×' &&
        key !== '÷' &&
        key !== '√' &&
        key !== 'x 2' &&
        key !== '%' &&
        key !== '<=' &&
        key !== '±' &&
        key !== 'sin' &&
        key !== 'cos' &&
        key !== 'tan' &&
        key !== 'log' &&
        key !== 'ln' &&
        key !== 'x^' &&
        key !== 'x !' &&
        key !== 'π' &&
        key !== 'e' &&
        key !== 'rad' &&
        key !== '°'
      ) {
        me.current += key
      } else if (key === '=') {
        if (me.current.indexOf('^') > -1) {
          const base = me.current.slice(0, me.current.indexOf('^'))
          const exponent = me.current.slice(me.current.indexOf('^') + 1)
          me.current = eval('Math.pow(' + base + ',' + exponent + ')')
        } else {
          me.current = eval(me.current)
        }
      } else if (key === 'C') {
        me.current = ''
      } else if (key === '×') {
        me.current += '*'
      } else if (key === '÷') {
        me.current += '/'
      } else if (key === '+') {
        me.current += '+'
      } else if (key === '-') {
        me.current += '-'
      } else if (key === '±') {
        if (me.current.charAt(0) === '-') {
          me.current = me.current.slice(1)
        } else {
          me.current = '-' + me.current
        }
      } else if (key === '<=') {
        me.current = me.current.substring(0, me.current.length - 1)
      } else if (key === '%') {
        me.current = me.current / 100
      } else if (key === 'π') {
        me.current = me.current * Math.PI
      } else if (key === 'x 2') {
        me.current = eval(me.current * me.current)
      } else if (key === '√') {
        me.current = Math.sqrt(me.current)
      } else if (key === 'sin') {
        me.current = Math.sin(me.current)
      } else if (key === 'cos') {
        me.current = Math.cos(me.current)
      } else if (key === 'tan') {
        me.current = Math.tan(me.current)
      } else if (key === 'log') {
        me.current = Math.log10(me.current)
      } else if (key === 'ln') {
        me.current = Math.log(me.current)
      } else if (key === 'x^') {
        me.current += '^'
      } else if (key === 'x !') {
        // eslint-disable-next-line no-unused-vars
        const number = 1
        if (me.current === 0) {
          me.current = '1'
        } else if (me.current < 0) {
          me.current = NaN
        } else {
          let number = 1
          for (let i = me.current; i > 0; i--) {
            number *= i
          }
          me.current = number
        }
      } else if (key === 'e') {
        me.current = Math.exp(me.current)
      } else if (key === 'rad') {
        me.current = me.current * (Math.PI / 180)
      } else if (key === '°') {
        me.current = me.current * (180 / Math.PI)
      }
    },
    changeModeEvent: function() {
      const me = this
      me.changeMode = !me.changeMode
    },
    closeCalculator() {
      this.$emit('closeCalculator')
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
