var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("codemirror", {
    ref: "myCm",
    attrs: { value: _vm.editorValue, options: _vm.cmOptions },
    on: { changes: _vm.onCmCodeChanges, blur: _vm.onCmBlur },
    nativeOn: {
      keyup: function ($event) {
        return _vm.onKeyUp.apply(null, arguments)
      },
      mousedown: function ($event) {
        return _vm.onMouseDown.apply(null, arguments)
      },
      paste: function ($event) {
        return _vm.OnPaste.apply(null, arguments)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }