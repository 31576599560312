var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "short-answer" },
    [
      _c("wangEditor", {
        ref: "editor",
        attrs: { question: _vm.question, commitLogs: _vm.commitLogs },
        on: { answer: _vm.getAnswer, init: _vm.init },
      }),
      _c(
        "div",
        { staticClass: "con" },
        _vm._l(_vm.originalFile, function (item, index) {
          return _c("div", { key: index, staticClass: "file" }, [
            _c("i", {
              staticClass: "el-icon-refresh-right",
              on: { click: _vm.reUpload },
            }),
            _vm._v(" " + _vm._s(item) + " "),
          ])
        }),
        0
      ),
      _vm.question.plugins.isEnableFileUpload
        ? _c("span", { staticClass: "tips" }, [
            _vm._v(
              "上传本机文件提示: 如果无法上传文件，可将文件传到网盘等云空间里，把文件的链接复制进来即可！"
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }