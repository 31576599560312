<!--
 * @Author: Libra
 * @Date: 2022-01-18 17:50:33
 * @LastEditTime: 2022-03-22 11:02:47
 * @LastEditors: Libra
 * @Description: 子母题
 * @FilePath: /stone-exam-ui/src/views/question/components/Cloze.vue
-->
<template>
  <div>
    <cloze-essay
      :question="question"
      :commitLogs="commitLogs"
      @singleChange="singleChange"
      v-if="clozeType === 5"
    />
    <cloze-select
      :question="question"
      :commitLogs="commitLogs"
      @singleChange="singleChange"
      v-else
    />
  </div>
</template>

<script>
import ClozeEssay from './ClozeEssay.vue'
import ClozeSelect from './ClozeSelect.vue'
export default {
  props: ['question', 'commitLogs'],
  components: {
    ClozeEssay,
    ClozeSelect
  },
  data() {
    return {
      clozeType: this.question.groupOptionList[0].type
    }
  },
  methods: {
    singleChange(val) {
      this.$emit('singleChange', val)
    }
  }
}
</script>

<style lang="scss" scoped></style>
