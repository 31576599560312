/*
 * @Author: Libra
 * @Date: 2021-06-21 14:22:32
 * @LastEditTime: 2021-06-21 14:23:05
 * @LastEditors: Libra
 * @Description: 所有支持的编程语言
 * @FilePath: /stone-exam-ui/src/common/language.js
 */
export default [
  { type: 1, name: 'C', sdk: 'Clang 7.0.1' },
  { type: 2, name: 'C', sdk: 'GCC 9.2.0' },
  { type: 3, name: 'C++', sdk: 'Clang 7.0.1' },
  { type: 4, name: 'C++', sdk: 'G++ 9.2.0' },
  { type: 5, name: 'C#', sdk: 'Mono 6.6.0.161' },
  { type: 7, name: 'Java', sdk: 'OpenJDK 13.0.1' },
  { type: 9, name: 'JavaScript', sdk: 'Node.js 12.14.0' },
  { type: 10, name: 'Python', sdk: '2.7.17' },
  { type: 11, name: 'Python', sdk: '3.8.1' },
  { type: 12, name: 'PHP', sdk: '7.4.1' },
  { type: 13, name: 'Swift', sdk: '5.2.3' },
  { type: 14, name: 'Objective-C', sdk: 'Clang 7.0.1' },
  { type: 15, name: 'Go', sdk: '1.13.5' },
  { type: 16, name: 'Ruby', sdk: '2.7.0' },
  { type: 17, name: 'Kotlin', sdk: '1.3.70' },
  { type: 18, name: 'R', sdk: '4.0.0' },
  { type: 19, name: 'SQL', sdk: 'SQLite 3.27.2' },
  { type: 20, name: 'Rust', sdk: '1.40.0' },
  { type: 21, name: 'Scala', sdk: '2.13.2' },
  { type: 22, name: 'Groovy', sdk: '3.0.3' },
  { type: 23, name: 'Common Lisp', sdk: 'SBCL 2.0.0' },
  { type: 24, name: 'Bash', sdk: '5.0.0' }
]
