<!--
 * @Author: Libra
 * @Date: 2021-04-27 10:59:54
 * @LastEditTime: 2023-10-30 14:10:54
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/views/question/components/Select.vue
-->
<template>
  <div class="select">
    <div class="options">
      <el-checkbox-group
        v-model="multiData"
        @change="singleChange"
        v-if="+question.type === 2 || +question.type === 7"
      >
        <div
          :class="multiData.includes(option.value) ? 'option2' : 'option'"
          :key="option.value"
          v-for="(option, index) in question.optionList"
        >
          <el-checkbox
:label="option.value"
:key="option.value"
            >
            <div style="display: flex; justify-content: flex-start; align-items: center;">
              <span>{{optionMap[index]}}、</span>
            <span
              v-html="strReplace(option.title)"
            ></span
          >
            </div>
        </el-checkbox>
        </div>
      </el-checkbox-group>
      <el-radio-group v-else v-model="singleData" @change="singleChange">
        <div
          :class="singleData === option.value ? 'option2' : 'option'"
          :key="option.value"
          v-for="(option, index) in question.optionList"
        >
          <el-radio :label="option.value" :key="option.value">
            <div style="display: flex; justify-content: flex-start; align-items: center;">
              <span>
                {{optionMap[index]}}、
              </span>
              <span
                class="option-content"
                v-html="strReplace(option.title)"
              ></span>
            </div>
          </el-radio>
        </div>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
import { getItem, setItem } from '@/utils/storage'
export default {
  name: 'QuestionSelect',
  props: ['question', 'commitLogs'],
  created() {
    // 是否需要选项乱序
    this.isOptionRandom = this.$route.query.isOptionRandom === 'true'
    // 某道题目是否选项乱序
    this.isOptionRandomDisable = this.question.isOptionRandomDisable
    for (const item of this.commitLogs) {
      if (item.questionUuid === this.question.questionUuid) {
        if (this.question.type === 2 || this.question.type === 7) {
          const resArr = item.value.map((i) => {
            return Number(i)
          })
          this.multiData = resArr
        } else {
          const resArr = item.value.map((i) => {
            return Number(i)
          })
          this.singleData = resArr[0]
        }
      }
    }
    this.handleOptionShuffle(this.question.optionList)
  },
  data() {
    return {
      singleData: [],
      multiData: [],
      isOptionRandom: false,
      isOptionRandomDisable: false,
      optionMap: {
        '0': 'A',
        '1': 'B',
        '2': 'C',
        '3': 'D',
        '4': 'E',
        '5': 'F',
        '6': 'G',
        '7': 'H'
      }
    }
  },
  methods: {
    singleChange() {
      if (this.question.type === 2 || this.question.type === 7) {
        this.$emit('singleChange', {
          value: this.multiData,
          file: null,
          originalFile: null
        })
      } else {
        this.$emit('singleChange', {
          value: [this.singleData],
          file: null,
          originalFile: null
        })
      }
    },
    // 字符串替换
    strReplace(str) {
      if (!str) return
      const s = str.replace(/&lt;/g, '<')
      return s.replace(/&gt;/g, '>')
    },
    // 数组乱序
    shuffle(a) {
      if (!this.isOptionRandom) return a
      if (this.isOptionRandomDisable) return a
      for (let i = a.length; i; i--) {
        const j = Math.floor(Math.random() * i);
        [a[i - 1], a[j]] = [a[j], a[i - 1]]
      }
      return a
    },
    // 处理一下选项乱序， 再次进入时，不需要乱序
    handleOptionShuffle() {
      let questionList = getItem('questionList')
      if (questionList) {
        questionList = JSON.parse(questionList)
        // 如果存在这道题，就替换，不存在就添加
        const index = questionList.findIndex(
          (item) => item.questionUuid === this.question.questionUuid
        )
        if (index !== -1) {
          this.question.optionList = questionList[index].optionList
        } else {
          questionList.push({
            questionUuid: this.question.questionUuid,
            optionList: this.shuffle(this.question.optionList)
          })
          setItem('questionList', JSON.stringify(questionList))
        }
      } else {
        questionList = [
          {
            questionUuid: this.question.questionUuid,
            optionList: this.shuffle(this.question.optionList)
          }
        ]
        setItem('questionList', JSON.stringify(questionList))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/theme/variables.scss";
.select {
  .options {
    ::v-deep .el-radio-group,
    ::v-deep .el-checkbox-group {
      width: 100%;
      .option {
        font-size: 15px;
        margin-bottom: 12px;
        background-color: #f1f1f1;
        p {
          display: inline-block;
          margin: 0;
        }
        // .is-checked {
        //   line-height: 1.5;
        // }
        .el-radio {
          display: block;
          padding: 16px 24px 16px 8px;
          box-sizing: border-box;
          .el-radio__label {
            white-space: normal;
            .option-content {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
        .el-checkbox {
          padding: 16px 24px 16px 8px;
          box-sizing: border-box;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .el-checkbox__label {
            white-space: normal;
            .option-content {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            p {
              line-height: 1.5;
              display: inline;
            }
          }
        }
        .el-radio {
          padding: 16px 24px 16px 8px;
          box-sizing: border-box;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .el-radio__label {
            white-space: normal;
            .option-content {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            p {
              line-height: 1.5;
              display: inline;
            }
          }
        }
        &:hover {
          background-color: #fce6e8;
          color: #de0000;
        }
      }
      .option2 {
        font-size: 15px;
        margin-bottom: 12px;
        background-color: #fce6e8;
        p {
          display: inline-block;
          margin: 0;
        }
        // .is-checked {
        //   line-height: 1.5;
        // }
        .el-radio {
          display: block;
          padding: 16px 24px 16px 8px;
          box-sizing: border-box;
          .el-radio__label {
            white-space: normal;
            .option-content {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
        .el-checkbox {
          padding: 16px 24px 16px 8px;
          box-sizing: border-box;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .el-checkbox__label {
            white-space: normal;
            .option-content {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            p {
              line-height: 1.5;
              display: inline;
            }
          }
        }
        .el-radio {
          padding: 16px 24px 16px 8px;
          box-sizing: border-box;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          .el-radio__label {
            white-space: normal;
            .option-content {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            p {
              line-height: 1.5;
              display: inline;
            }
          }
        }
      }
    }
  }
}
</style>
