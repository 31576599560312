/*
 * @Author: Libra
 * @Date: 2022-04-07 17:55:10
 * @LastEditTime: 2022-04-07 17:55:10
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/common/formula.js
 */
export default [
  // 常规选项卡
  {
    label: '常规',
    formulas: [
      {
        name: '分数',
        formula: '\\frac{}{}'
      },
      {
        name: '平方根',
        formula: '\\sqrt{}'
      },
      {
        name: '求根',
        formula: '\\sqrt[]{}'
      },
      {
        name: '上标',
        formula: 'x^{}'
      },
      {
        name: '下标',
        formula: 'x_{}'
      },
      {
        name: '小括号',
        formula: '\\left ( {} \\right )'
      },
      {
        name: '中括号',
        formula: '\\left [ {} \\right ]'
      },
      {
        name: '大括号',
        formula: '\\left \\{ {} \\right \\}'
      },
      {
        name: '单竖线',
        formula: '\\left| {} \\right|'
      },
      {
        name: '加号',
        formula: '+'
      },
      {
        name: '减号',
        formula: '-'
      },
      {
        name: '乘号',
        formula: '\\times'
      },
      {
        name: '除号',
        formula: '\\div'
      },
      {
        name: '加减号',
        formula: '\\pm'
      },
      {
        name: '斜杠',
        formula: '/'
      },
      {
        name: '小于等于',
        formula: '\\le'
      },
      {
        name: '大于等于',
        formula: '\\ge'
      },
      {
        name: '属于',
        formula: '\\in'
      },
      {
        name: '子集',
        formula: '\\subset'
      },
      {
        name: '并集',
        formula: '\\cup'
      },
      {
        name: '交集',
        formula: '\\cap'
      },
      {
        name: '空集',
        formula: '\\varnothing'
      },
      {
        name: '无穷大',
        formula: '\\infty'
      },
      {
        name: 'π',
        formula: '\\pi'
      },
      {
        name: '等于',
        formula: '='
      },
      {
        name: '约等于',
        formula: '\\approx'
      }
    ]
  },
  // 符号选项卡
  {
    label: '符号',
    formulas: [
      {
        name: '加号',
        formula: '+'
      },
      {
        name: '减号',
        formula: '-'
      },
      {
        name: '乘号',
        formula: '\\times'
      },
      {
        name: '除号',
        formula: '\\div'
      },
      {
        name: '加减号',
        formula: '\\pm'
      },
      {
        name: '正负号',
        formula: '\\mp'
      },
      {
        name: '斜杠',
        formula: '/'
      },
      {
        name: '小于等于',
        formula: '\\le'
      },
      {
        name: '大于等于',
        formula: '\\ge'
      },
      {
        name: '属于',
        formula: '\\in'
      },
      {
        name: '子集',
        formula: '\\subset'
      },
      {
        name: '并集',
        formula: '\\cup'
      },
      {
        name: '交集',
        formula: '\\cap'
      },
      {
        name: '空集',
        formula: '\\varnothing'
      },
      {
        name: '无穷大',
        formula: '\\infty'
      },
      {
        name: 'π',
        formula: '\\pi'
      },
      {
        name: '等于',
        formula: '='
      },
      {
        name: '约等于',
        formula: '\\approx'
      },
      {
        name: '中间点',
        formula: '\\cdot'
      },
      {
        name: '星号',
        formula: '\\ast'
      },
      {
        name: '环运算符',
        formula: '\\circ'
      },
      {
        name: '反斜杠',
        formula: '\\backslash'
      },
      {
        name: '偏微分',
        formula: '\\partial'
      },
      {
        name: '增量',
        formula: '\\bigtriangleup'
      },
      {
        name: '倒三角算子',
        formula: '\\bigtriangledown'
      },
      {
        name: '度',
        formula: '^{\\circ}'
      },
      {
        name: '撇号',
        formula: '^{\\prime}'
      },
      {
        name: '双撇号',
        formula: '^{\\prime \\prime}'
      },
      {
        name: '波浪号运算符',
        formula: '\\sim'
      }
    ]
  }
]
