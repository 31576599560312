<!--
 * @Author: Libra
 * @Date: 2021-07-21 18:10:01
 * @LastEditTime: 2022-11-15 15:13:45
 * @LastEditors: Libra
 * @Description: 数学公式编辑器
 * @FilePath: /stone-exam-ui/src/components/MathEditor/index.vue
-->
<template>
  <div>
    <el-tabs type="border-card" v-model="activeName">
      <el-tab-pane
        :label="item.label"
        :name="item.label"
        v-for="(item, index) in formula"
        :key="index"
      >
        <svg-icon
          @click.native="add(i.formula)"
          :svgTitle="i.name"
          v-for="(i, idx) in item.formulas"
          :key="idx"
          :icon-class="i.name"
        ></svg-icon>
      </el-tab-pane>
    </el-tabs>
    <div ref="mathEditor" class="math-editor"></div>
  </div>
</template>

<script>
import Formula from '@/common/formula'
export default {
  data() {
    return {
      mathEditor: null,
      formula: Formula,
      activeName: '常规'
    }
  },
  mounted() {
    this.initMathquill()
  },
  methods: {
    // 初始化 mathquill
    initMathquill() {
      this.$nextTick(() => {
        var MQ = window.MathQuill.getInterface(2)
        this.mathEditor = MQ.MathField(this.$refs.mathEditor, {
          spaceBehavesLikeTab: true,
          handlers: {
            edit: () => {
              this.$emit('latex', this.mathEditor.latex()) // simple API
            }
          }
        })
        this.mathEditor.latex('')
      })
    },
    add(formula) {
      this.mathEditor.write(formula)
    }
  }
}
</script>

<style lang="scss" scoped>
@import './index.scss';
</style>
