<!--
 * @Author: Libra
 * @Date: 2023-02-14 10:22:45
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/views/question/components/Interview.vue
-->
<template>
  <div class="interview">
    <!-- <div class="remain-read-time" v-if="readMode === 1 && !answerUrl && remainReadTime > 0">距开始录制时间：<span class="read-time">{{ formatSeconds(remainReadTime) }}</span>（倒计时结束后会自动开始录制）</div>
    <div class="remain-read-time" style="margin-bottom: 10px;">本题剩余时间：<span class="read-time">{{ formatSeconds(remainAnswerTimer) }}</span></div> -->
  <div
style="width: 100%;display: flex;
justify-content: center;
align-items: center;">
    <CustomButton
          title="录制视频"
          :isPlain="false"
          :hasIcon="true"
          classStr="cusBtn"
          v-if="(readMode === 0 || remainReadTime === 0) &&!answerUrl"
          iconName="iconfont iconvideo2-fill"
          :clickMethod="()=>{startRecord(remainAnswerTimer)}"
        ></CustomButton>
  </div>
    <div
v-if="!!answerUrl"
style="display: flex;
justify-content: flex-start;
align-items: center;">
      <video-player
        :id="`video${question.questionUuid}`"
        :answerUrl="answerUrl"
      />
      <div style="width:20px;"></div>
      <CustomButton
          class="gap"
          title="删除视频"
          :isPlain="true"
          :hasIcon="true"
          v-if="question.isVideoAnswerDeletable"
          :clickMethod="deleteVideo"
          iconName="iconfont iconshanchu"
        ></CustomButton>
    </div>
    <GlobalDialog
      :dialogVisible="showInterviewRecord"
      :show_close="false"
      :isShowFooter="false"
      @dialog-cancel="showInterviewRecord = false"
      title="视频录制"
      customClass="video-record-dialog"
      width="90%"
      :showModal="true"
      style="height: 100vh"
    >
      <div class="record-con">
        <div class="record-title-con">
          <div class="left">视频录制<span class="recording">（录制中...）</span></div>
          <div class="record-title" v-if="isStart">
            <span class="title">正在录制：</span>
            <span class="time">{{ formatSeconds(videoTime) }}</span>
          </div>
          <div class="right">
            <div class="record-title2">
              <span class="title">剩余时间：</span>
              <span class="time">{{ formatSeconds(remainTime) }}</span>
            </div>
            <div class="record-title2" style="margin-left: 10px;" v-if="isWillExpire">
              <span class="title">录制剩余时间：</span>
              <span class="time">{{ formatSeconds(remainRecordTime) }}</span>
            </div>
          </div>
        </div>
        <div class="main-con">
          <div
            class="record"
            v-loading="isLoading"
            :element-loading-text="`${!isStart ?'正在开始。。':'录制结果获取中。。'}`"
          >
          </div>
          <div class="question-body-re">
            <div class="quest-body" v-html="strReplace(question.body)"></div>
          </div>
        </div>
        <div class="re-btn-con">
          <div style="margin-right: 10px;font-size: 12px;">
            摄像头：
            <el-select
              style="width:200px"
              v-model="defaultCamera"
              @change="selectCamera"
            >
              <el-option
                v-for="item in cameraList"
                :key="item.deviceId"
                :label="item.label"
                :value="item.deviceId"
              >
              </el-option>
            </el-select>
          </div>
          <CustomButton
            v-if="isStart"
            :isDisabled="videoTime<=minTime"
            :title="`完成录制${videoTime<=minTime?`，最短录制：(${formatSeconds(minTime)})`:''}`"
            :isPlain="false"
            :clickMethod="stopRecord"
          ></CustomButton>
          <div style="margin: 0 10px; font-size: 12px;">
            麦克风：
            <el-select
              style="width:200px"
              v-model="defaultAudio"
              @change="selectAudio"
            >
              <el-option
                v-for="item in audioList"
                :key="item.deviceId"
                :label="item.label"
                :value="item.deviceId"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </GlobalDialog>
  </div>
</template>

<script>
import GlobalDialog from '@/components/GlobalDialog'
import Api from '@/api/api'
import TRTC from 'trtc-js-sdk'
import CustomButton from '@/components/CustomButton'
import videoPlayer from '@/components/videoPlayer'
export default {
  name: 'QuestionInterview',
  props: ['question', 'commitLogs'],
  data() {
    return {
      showInterviewRecord: false,
      isStart: false,
      videoTime: 0,
      remainTime: 0,
      remainReadTime: 0,
      timer: null,
      answerUrl: null,
      player: null,
      audioList: [],
      cameraList: [],
      defaultAudio: '',
      defaultCamera: '',
      isLoading: false,
      remainRecordTime: 30,
      isWillExpire: false,
      // TODO:换成真实数据
      totalTime: 0,
      readTime: 0,
      minTime: 0,
      readMode: 0,
      remainAnswerTimer: 0,
      expireTimer: null,
      recordCountDowntimer: null
    }
  },
  components: {
    GlobalDialog,
    CustomButton,
    videoPlayer
  },
  created() {
    this.totalTime = this.question.videoInterviewQuestionSet.totalDuration
    this.readTime = this.question.videoInterviewQuestionSet.readDuration
    this.minTime = this.question.videoInterviewQuestionSet.recordLowestDuration || 15
    this.readMode = this.question.videoInterviewQuestionSet.readType
    for (const item of this.commitLogs) {
      if (item.questionUuid === this.question.questionUuid) {
        const val = JSON.parse(JSON.stringify(item.value))
        this.answerUrl = val[0]
      }
    }
  },
  async mounted() {
    this.deviceChange()
    this.audioList = await TRTC.getMicrophones()
    this.cameraList = await TRTC.getCameras()
    this.defaultAudio = this.audioList[0].deviceId
    this.defaultCamera = this.cameraList[0].deviceId
    await this.handleTimeAndStage()
  },
  methods: {
    recordCountDown() {
      const thirtyMin = 60 * 30
      clearInterval(this.recordCountDowntimer)
      this.recordCountDowntimer = setInterval(() => {
        if (this.videoTime === thirtyMin - 30) {
          this.handleExpire()
        }
        if (this.videoTime === thirtyMin) {
          this.stopRecord()
          return
        }
      }, 1000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.recordCountDowntimer)
      })
    },
    handleExpire() {
      this.isWillExpire = true
      clearInterval(this.expireTimer)
      this.expireTimer = setInterval(() => {
        this.remainRecordTime--
        if (this.remainRecordTime === 0) {
          this.stopRecord()
          this.isWillExpire = false
          this.remainRecordTime = 30
          clearInterval(this.expireTimer)
        }
      }, 1000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.expireTimer)
      })
    },
    // 处理时间和阶段
    async handleTimeAndStage() {
      // 秒
      const totalTime = this.totalTime
      const readTime = this.readTime

      // 将本题的总时间存入sessionStorage，是一个数组，每个元素是一个题目的总时间，根据current来获取和更新
      const current = +this.$route.query.current
      const totalTimes = JSON.parse(sessionStorage.getItem('totalTimes')) || []
      totalTimes[current] = totalTime
      sessionStorage.setItem('totalTimes', JSON.stringify(totalTimes))

      // 取出当前题前面题目的总时间
      let preTotalTime = 0
      for (let i = 0; i < current; i++) {
        preTotalTime += totalTimes[i]
      }
      // 处理重新登录或者刷新页面的时间同步
      // 考试开始时间
      const startTime = new Date(this.$store.state.examInfo.startAt).getTime()
      let curTime = null
      // 当前时间
      const res = await Api.getTime()
      if (res.code === 0) {
        curTime = res.data.timestamp * 1000
      }
      // 根据时间差判断当前是在阅读时间还是答题时间
      const diffTime = curTime - startTime - this.$route.query.readDuration * 1000 - preTotalTime * 1000
      this.remainAnswerTimer = totalTime - Math.floor(diffTime / 1000)
      const timer = setInterval(() => {
        this.remainAnswerTimer = this.remainAnswerTimer - 1
        if (this.remainAnswerTimer <= 0) {
          this.$parent.$parent.jumpToQuestion(Number(current + 1))
          clearInterval(timer)
        }
      }, 1000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(timer)
      })
      if (diffTime > readTime * 1000 && diffTime < totalTime * 1000) {
        // 答题时间
        if (this.answerUrl) return
        // this.startRecord(totalTime - Math.floor(diffTime / 1000))
      } else if (diffTime > totalTime * 1000) {
        // 肯定有下一题，没有下一题会自动结束的（考试总时间到了），跳转下一题
        this.$parent.$parent.jumpToQuestion(Number(current + 1))
      } else {
        // 阅读时间
        if (this.answerUrl) return
        this.remainReadTime = readTime - Math.floor(diffTime / 1000)
        const timer = setInterval(() => {
          this.remainReadTime = this.remainReadTime - 1
          if (this.remainReadTime <= 0) {
            clearInterval(timer)
            if (!this.isStart && this.readMode === 1) this.startRecord(totalTime - readTime + this.remainReadTime)
          }
        }, 1000)
        this.$once('hook:beforeDestroy', () => {
          clearInterval(timer)
        })
      }

      // 判断下一题
    },
    // 字符串替换
    strReplace(str) {
      if (!str) return
      const s = str.replace(/&lt;/g, '<')
      return s.replace(/&gt;/g, '>')
    },
    // 获取 rtc token
    async getRtcToken() {
      const questionUuid = this.question.questionUuid
      const res = await Api.getQuestionSig(questionUuid)
      this.options = res.data
    },
    async startRecord(time) {
      this.isLoading = true
      clearInterval(this.timer)
      this.showInterviewRecord = true
      this.client = null
      await this.getRtcToken()
      await this.initClient()
      await this.joinChannel(time)
    },
    // 创建频道
    async initClient() {
      const { sig, userId, appId } = this.options
      const option = {
        mode: 'rtc',
        sdkAppId: appId,
        userId,
        userSig: sig,
        userDefineRecordId: userId,
        autoSubscribe: false
      }
      this.client = await TRTC.createClient(option)
    },
    /**
     * 检测设备热插拔
     */
    async deviceChange() {
      // 1. 保存一份设备列表
      let prevDevices = await TRTC.getDevices()
      // 2. 监听设备变更事件
      navigator.mediaDevices.addEventListener('devicechange', async() => {
        // 3. 设备变更时，获取变更后的设备列表，用于和 prevDevices 比对
        const devices = await TRTC.getDevices()
        // 4. 新增的设备列表
        const devicesAdded = devices.filter(
          (device) =>
            prevDevices.findIndex(
              ({ deviceId }) => device.deviceId === deviceId
            ) < 0
        )
        // 5. 移除的设备列表
        const devicesRemoved = prevDevices.filter(
          (prevDevice) =>
            devices.findIndex(
              ({ deviceId }) => prevDevice.deviceId === deviceId
            ) < 0
        )
        if (devicesAdded.length > 0) {
          this.handleDevicesAdded(devicesAdded)
        }
        if (devicesRemoved.length > 0) {
          this.handleDevicesRemoved(devicesRemoved)
        }
        prevDevices = devices
      })
    },
    async handleDevicesAdded(devicesAdded) {
      devicesAdded.forEach((device) => {
        if (device.kind === 'audioinput') {
          // 提示用户检测到新麦克风插入。若用户需要切换到新设备，可以调用 localStream.switchDevice 接口切换设备
          this.audioList.push(device)
          this.audioListStr = this.audioList.map((device) => device.label)
        } else if (device.kind === 'videoinput') {
          // 提示用户检测到新摄像头插入。若用户需要切换到新设备，可以调用 localStream.switchDevice 接口切换设备
          this.cameraList.push(device)
          this.cameraListStr = this.cameraList.map((device) => device.label)
        }
      })
    },
    async handleDevicesRemoved(devicesRemoved) {
      devicesRemoved.forEach((device) => {
        if (device.kind === 'audioinput') {
          // 从 audioList 中移除设备
          this.audioList = this.audioList.filter(
            (d) => d.deviceId !== device.deviceId
          )
          this.audioListStr = this.audioList.map((device) => device.label)
        } else if (device.kind === 'videoinput') {
          // 从 cameraList 中移除设备
          this.cameraList = this.cameraList.filter(
            (d) => d.deviceId !== device.deviceId
          )
          this.cameraListStr.value = this.cameraList.map(
            (device) => device.label
          )
        }
      })
    },
    async selectAudio(device) {
      const localStream = this.cameraLocalStream
      for (const item of this.audioList) {
        if (item.deviceId === device) {
          localStream && localStream.switchDevice('audio', item.deviceId)
          this.defaultAudio = device
          break
        }
      }
    },
    selectCamera(device) {
      const localStream = this.cameraLocalStream
      console.log(localStream)
      for (const item of this.cameraList) {
        console.log(item.deviceId, device)
        if (item.deviceId === device) {
          localStream && localStream.switchDevice('video', item.deviceId)
          this.defaultCamera = device
          break
        }
      }
    },
    // 加入频道
    async joinChannel(time) {
      this.client
        .join({ roomId: this.options.roomNumber })
        .then(async() => {
          try {
            this.cameraLocalStream = TRTC.createStream({
              userId: this.options.userId,
              audio: true,
              video: true,
              cameraId: this.defaultCamera,
              microphoneId: this.defaultAudio
            })
          } catch (error) {
            this.$message.error('开始录制失败，请先进行设备检测，确保设备一切正常！')
          }
          this.cameraLocalStream.setVideoProfile({
            width: 480,
            height: 360,
            frameRate: 15,
            bitrate: 300 /* kpbs */
          })
          this.cameraLocalStream
            .initialize()
            .then(() => {
              this.client
                .publish(this.cameraLocalStream)
                .then(() => {
                  const localPlayerContainer = document.createElement('div')
                  localPlayerContainer.id = this.options.userId
                  localPlayerContainer.style.width = '100%'
                  // localPlayerContainer.style.height = '100%'
                  const container = document.querySelector(
                    '.record-con .record'
                  )
                  container.append(localPlayerContainer)
                  this.cameraLocalStream.play(localPlayerContainer)
                  this.isLoading = false
                  setTimeout(() => {
                    this.isStart = true
                  }, 500)
                  this.countDown(time)
                  this.recordCountDown()
                })
                .catch((error) => {
                  console.error('本地流发布失败 ' + error)
                })
              console.log('初始化本地流成功')
            })
            .catch((error) => {
              this.$message.error('开始录制失败，请先进行设备检测，确保设备一切正常！')
              console.error('初始化本地流失败 ' + error)
            })
        })
        .catch((error) => {
          console.error('进房失败 ' + error)
        })
    },
    singleChange(answer) {
      this.$emit('singleChange', answer)
    },
    // 获取视频答案
    async getVideoAnswer(questionUuid) {
      const res = await Api.getAnswer(questionUuid)
      this.singleChange(res.data)
      return res.data
    },
    // 离开频道
    async stopRecord() {
      this.client && this.client.leave()
      this.isLoading = true
      this.client = null
      this.client = null
      const container = document.getElementById(this.options.userId)
      container && container.remove()
      this.resetTimer()
      const questionUuid = this.question.questionUuid
      let count = 0
      // 每十秒调用一次
      const timer = setInterval(async() => {
        count++
        if (count === 4) {
          clearInterval(timer)
          return
        }
        const res = await this.getVideoAnswer(questionUuid)
        if (res) {
          this.answerUrl = res.value[0]
          clearInterval(timer)
          this.isLoading = false
          setTimeout(() => {
            this.isStart = false
          }, 500)
          this.showInterviewRecord = false
        }
      }, 5000)
    },
    async deleteVideo() {
      const questionUuid = this.question.questionUuid
      const res = await Api.deleteAnswer(questionUuid)
      if (res.code === 0) {
        this.answerUrl = null
        this.showDeleteVideo = false
        await this.getVideoAnswer(questionUuid)
        console.log('deleteVideo success!')
      } else {
        console.log('deleteVideo fail!')
      }
    },
    // 计时
    countDown(remainTime) {
      clearInterval(this.timer)
      this.remainTime = remainTime
      this.timer = setInterval(async() => {
        if (this.remainTime === 0) {
          await this.stopRecord()
          this.$parent.$parent.jumpToQuestion(Number(this.$route.query.current + 1))
          return
        }
        this.remainTime--
        this.videoTime++
      }, 1000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.timer)
      })
    },
    // reset timer
    resetTimer() {
      this.videoTime = 0
      clearInterval(this.timer)
    },
    // 秒转为时分秒 00:00:00 格式
    formatSeconds(value) {
      let theTime = parseInt(value) // 秒
      let theTime1 = 0 // 分
      let theTime2 = 0 // 小时
      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60)
        theTime = parseInt(theTime % 60)
        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60)
          theTime1 = parseInt(theTime1 % 60)
        }
      }
      let result = ''
      if (theTime2 > 0) {
        result = `${theTime2 < 10 ? '0' + theTime2 : theTime2}:`
      }
      result += `${theTime1 < 10 ? '0' + theTime1 : theTime1}:`
      result += `${theTime < 10 ? '0' + theTime : theTime}`
      return result
    },
    // 设备检测
    checkAllDevice() {
      this.$refs.detect.checkAllDevice()
    }
  }
}
</script>

<style lang="scss" scoped>
video {
  margin-top: 0px !important;
}
::v-deep .el-dialog__body {
  padding: 0 10px 10px 10px;
  height: 100%;
  .slot-container {
    height: 100%;
  }
}
::v-deep .dj-dialog-content {
  padding: 0;
  overflow: unset;
}
.interview {
  width: 100%;
  ::v-deep .cusBtn {
    padding-left: 80px;
    padding-right: 80px;
  }
  .remain-read-time {
    margin-top: 10px;
    .read-time {
      color: #f56c6c;
      font-size: 20px;
      font-weight: bold;
    }
  }
  ::v-deep .video-record-dialog {
    height: 80%;
    margin-top: 80px !important;
    border-radius: 20px;
    box-shadow: 4px 4px 19px 6px rgba(0, 0, 0, 0.25);
  }
  ::v-deep .video-record-dialog .el-dialog__header {
    display: none;
  }
  .record-con {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .record-title-con {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 73px;
      .left {
        padding: 20px 0 20px 20px;
        font-size: 18px;
        .recording {
          color: #f56c6c;
          font-weight: bold;
        }
      }
.record-title {
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          .title {
            font-size: 14px;
            color: #f56c6c;
          }
          .time {
            color: #f56c6c;
            font-weight: bold;
            font-size: 28px;
          }
        }
      .right {
        padding: 20px 20px 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .record-title2 {
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          .title {
            font-size: 14px;
          }
          .time {
            font-weight: bold;
            color: #f56c6c;
            font-size: 22px;
          }
        }
      }
    }
    .main-con {
      overflow: hidden;
      display: flex;
      margin: 0 20px;
      padding: 20px;
      width: 100%;
      flex: 1;
      justify-content: space-between;
      align-items: center;
      background-color: #e6e6e6;
      border-radius: 20px;
      .question-body-re {
        flex: 1;
        height: 100%;
        border-radius: 20px;
        padding: 20px;
        background-color: #4c4c4c;
        .quest-body {
          height: 100%;
          overflow: auto;
          color: #fff;
        }
      }
      .record {
        flex: 2;
        height: 100%;
        background-color: #4c4c4c;
        border-radius: 20px;
        position: relative;
        color: #fff;
        margin-right: 10px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        .record-tips {
          margin-top: 40px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          padding: 0 20px;
          flex-direction: column;
          font-size: 18px;
          font-weight: bold;
          line-height: 2;
          .content {
            font-size: 16px;
            font-weight: normal;
          }
          .btn_start {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
            width: 150px;
            height: 80px;
            border-radius: 3px;
            background-color: #f56c6c;
            align-self: center;
            cursor: pointer;
            &:hover {
              background-color: rgba(245, 108, 108, 0.8);
            }
          }
        }
      }
    }
    .re-btn-con {
      height: 40px;
      padding: 0 20px;
      margin-top: 20px;
      margin-bottom: 10px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  .gap {
    margin-top: 10px;
  }
}
</style>
