var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loading
    ? _c(
        "div",
        { staticClass: "layout" },
        [
          _c("detect-layout"),
          _c(
            "GlobalDialog",
            {
              attrs: {
                dialogVisible: _vm.showNotAnwserdList,
                title: "提示",
                width: "550px",
                btn_title: "去完成",
                show_close: false,
              },
              on: {
                "dialog-cancel": function ($event) {
                  return _vm.goToQuestion(_vm.notAnsweredList[0])
                },
              },
            },
            [
              _c("div", { staticClass: "occupy-result" }, [
                _c("i", { staticClass: "iconfont icontishi" }),
                _c("div", { staticClass: "right" }, [
                  _c("span", { staticClass: "title3" }, [
                    _vm._v(
                      "您有" +
                        _vm._s(_vm.notAnsweredList.length) +
                        "道未作答完成的题目"
                    ),
                  ]),
                  _c("span", { staticClass: "detail" }, [
                    _vm._v("请作答完再提交！"),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "GlobalDialog",
            {
              attrs: {
                dialogVisible: _vm.showPrompt,
                title: "提示",
                width: "550px",
                show_close: false,
              },
              on: {
                "dialog-cancel": function ($event) {
                  _vm.showPrompt = false
                },
              },
            },
            [
              _c("div", { staticClass: "prompt-container" }, [
                _c("span", { staticClass: "title" }, [_vm._v("温馨提示")]),
                _c("div", { staticClass: "content" }, [
                  _c("i", { staticClass: "iconfont iconxiaolian" }),
                  _c("ul", { staticClass: "right" }, [
                    _c("li", { staticClass: "detail" }, [
                      _vm._v(" 在线编程题"),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#f35a5a",
                            "font-weight": "bold",
                          },
                        },
                        [_vm._v("可以使用本地编译器")]
                      ),
                    ]),
                    _c("li", { staticClass: "detail" }, [
                      _vm._v(" 作答此题"),
                      _c(
                        "span",
                        {
                          staticStyle: {
                            color: "#f35a5a",
                            "font-weight": "bold",
                          },
                        },
                        [_vm._v("可以跳出考试系统页面")]
                      ),
                    ]),
                    _c("li", { staticClass: "detail" }, [
                      _vm._v(" 系统会比对所有考生编程代码，请遵守考试纪律 "),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "GlobalDialog",
            {
              attrs: {
                dialogVisible: _vm.showSaveSuccess,
                title: "提示",
                width: "400px",
                show_close: false,
              },
              on: {
                "dialog-cancel": function ($event) {
                  _vm.showSaveSuccess = false
                },
              },
            },
            [
              _c("div", { staticClass: "prompt-container" }, [
                _c("div", { staticClass: "content" }, [
                  _c("i", {
                    staticClass: "iconfont iconduigou1",
                    staticStyle: { "font-size": "48px" },
                  }),
                  _c(
                    "div",
                    { staticClass: "right", staticStyle: { margin: "0" } },
                    [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("保存成功！"),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "GlobalDialog",
            {
              attrs: {
                dialogVisible: _vm.showMustAnswer,
                title: "提示",
                width: "500px",
                show_close: false,
              },
              on: {
                "dialog-cancel": function ($event) {
                  _vm.showMustAnswer = false
                },
              },
            },
            [
              _c("div", { staticClass: "occupy-result" }, [
                _c("i", { staticClass: "iconfont icontishi" }),
                _c("div", { staticClass: "right" }, [
                  _c("span", { staticClass: "title3" }, [
                    _vm._v("此题没有作答"),
                  ]),
                  _c("span", { staticClass: "detail" }, [
                    _vm._v("请作答完再打开下一题！"),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "GlobalDialog",
            {
              attrs: {
                dialogVisible: _vm.showOccupyScreenTip,
                show_close: false,
                title: "提示",
                width: "500px",
                btn_title: "我知道了，继续考试",
              },
              on: {
                "dialog-cancel": function ($event) {
                  return _vm.reOccupyScreen()
                },
              },
            },
            [
              _c("div", { staticClass: "occupy-result" }, [
                _c("i", { staticClass: "iconfont iconjinzhi" }),
                _c("div", { staticClass: "right" }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("本场考试禁止退出全屏"),
                  ]),
                  _c("span", { staticClass: "content" }, [
                    _vm._v("请点击下方按钮返回考试"),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "GlobalDialog",
            {
              attrs: {
                dialogVisible: _vm.showLeavePageTip,
                width: "500px",
                show_close: false,
                title: "提示",
              },
              on: {
                "dialog-cancel": function ($event) {
                  _vm.showLeavePageTip = false
                },
              },
            },
            [
              _c("div", { staticClass: "occupy-result" }, [
                _c("i", {
                  staticClass: "iconfont iconiconfontzhizuobiaozhun41",
                }),
                _c("div", { staticClass: "right" }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("您已离开页面："),
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#e06563", "font-size": "30px" },
                      },
                      [_vm._v(_vm._s(_vm.leaveCount) + " ")]
                    ),
                    _vm._v("次"),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "content",
                      staticStyle: {
                        "line-height": "1.8",
                        "letter-spacing": "2px",
                      },
                    },
                    [
                      _vm._v(" 请确保各类可能导致鼠标失焦的后台软件"),
                      _c("br"),
                      _vm._v("已全部关闭。 "),
                      _c("br"),
                      _vm._v("离开次数将记录在您的考试成绩中"),
                      _c("br"),
                      _vm._v("请遵守考试规则 "),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "GlobalDialog",
            {
              attrs: {
                dialogVisible: _vm.showYellowCard,
                width: "500px",
                title: "提示",
              },
              on: {
                "dialog-cancel": function ($event) {
                  _vm.showYellowCard = false
                },
              },
            },
            [
              _c("div", { staticClass: "occupy-result" }, [
                _c("i", { staticClass: "iconfont icontishi-2" }),
                _c("div", { staticClass: "right" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("黄牌警告！")]),
                  _c("span", { staticClass: "title2" }, [
                    _vm._v("您已离开考试界面："),
                    _c(
                      "span",
                      {
                        staticStyle: { color: "#e06563", "font-size": "30px" },
                      },
                      [_vm._v(_vm._s(_vm.leaveCount) + " ")]
                    ),
                    _vm._v("次"),
                  ]),
                  _vm.$store.state.examInfo.leaveCountLimit !== 0
                    ? _c("span", { staticClass: "title2" }, [
                        _vm._v("还剩余："),
                        _c("span", { staticStyle: { "font-size": "24px" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$store.state.examInfo.leaveCountLimit -
                                _vm.leaveCount >
                                0
                                ? _vm.$store.state.examInfo.leaveCountLimit -
                                    _vm.leaveCount
                                : 0
                            )
                          ),
                        ]),
                        _vm._v(" 次"),
                      ])
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticClass: "content",
                      staticStyle: {
                        "line-height": "1.8",
                        "letter-spacing": "2px",
                      },
                    },
                    [
                      _vm._v("请再次确认后台软件已全部关闭"),
                      _c("br"),
                      _vm._v("次数过多会影响您的成绩"),
                      _c("br"),
                      _vm._v(" 请遵守考试规则！ "),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
          _c(
            "GlobalDialog",
            {
              attrs: {
                dialogVisible: _vm.showConfirmSubmit,
                title: "提示",
                width: "500px",
                showSecond: true,
                show_close: false,
                btn_title: "取消",
                btn_title2: "提交",
              },
              on: {
                "dialog-cancel": function ($event) {
                  _vm.showConfirmSubmit = false
                },
                "dialog-ok": _vm.submitPaper,
              },
            },
            [
              _c("div", { staticClass: "occupy-result" }, [
                _c("i", { staticClass: "iconfont icontishi-2" }),
                _c("div", { staticClass: "right" }, [
                  _c(
                    "span",
                    {
                      staticClass: "title3",
                      staticStyle: { color: "#1e1e1e" },
                    },
                    [
                      _vm._v(_vm._s(_vm.partName) + " 小卷提交后"),
                      _c("br"),
                      _c("span", { staticClass: "title3" }, [
                        _vm._v("不能再修改"),
                      ]),
                    ]
                  ),
                  _c("span", { staticClass: "detail" }, [
                    _vm._v("你确认要提交吗？"),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "GlobalDialog",
            {
              attrs: {
                dialogVisible: _vm.showPracticeSubmit,
                title: "提示",
                showSecond: true,
                show_close: false,
                btn_title: "取消",
                btn_title2: "确认",
                width: "500px",
              },
              on: {
                "dialog-cancel": function ($event) {
                  _vm.showPracticeSubmit = false
                },
                "dialog-ok": _vm.submitPaper,
              },
            },
            [
              _c("div", { staticClass: "occupy-result" }, [
                _c("i", { staticClass: "iconfont icontishi" }),
                _c("div", { staticClass: "right" }, [
                  _c("span", { staticClass: "title" }, [
                    _vm._v("此练习题小卷已完成! "),
                  ]),
                  _c("span", { staticClass: "detail" }, [
                    _vm._v("正式考试时，以实际考试规则为准"),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "GlobalDialog",
            {
              attrs: {
                dialogVisible: _vm.showCanModConfirmSubmit,
                title: "提示",
                width: "500px",
                show_close: false,
                showSecond: true,
                btn_title: "取消",
                btn_title2: "确认",
              },
              on: {
                "dialog-cancel": function ($event) {
                  _vm.showCanModConfirmSubmit = false
                },
                close: function ($event) {
                  _vm.showCanModConfirmSubmit = false
                },
                "dialog-ok": _vm.submitPaper,
              },
            },
            [
              _c("div", { staticClass: "occupy-result" }, [
                _c("i", { staticClass: "iconfont icontishi" }),
                _c("div", { staticClass: "right" }, [
                  _c("span", { staticClass: "title3" }, [
                    _vm._v("你确认要提交吗？"),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "GlobalDialog",
            {
              attrs: {
                dialogVisible: _vm.showNotAnwser,
                title: "提示",
                width: "500px",
                show_close: false,
                showSecond: true,
                btn_title2: "继续答题",
                btn_title: "下一题",
              },
              on: {
                "dialog-cancel": _vm.nextQuestion,
                close: function ($event) {
                  _vm.showNotAnwser = false
                },
                "dialog-ok": function ($event) {
                  _vm.showNotAnwser = false
                },
              },
            },
            [
              _c("div", { staticClass: "occupy-result" }, [
                _c("i", { staticClass: "iconfont icontishi" }),
                _c("div", { staticClass: "right" }, [
                  _c("span", { staticClass: "title3" }, [
                    _vm._v("此题没有作答"),
                  ]),
                  _c("span", { staticClass: "detail" }, [
                    _vm._v("建议作答完再打开下一题！"),
                  ]),
                ]),
              ]),
            ]
          ),
          _c(
            "GlobalDialog",
            {
              attrs: {
                dialogVisible: _vm.showCanNotBack,
                title: "提示",
                width: "500px",
                show_close: false,
              },
              on: {
                "dialog-cancel": function ($event) {
                  _vm.showCanNotBack = false
                },
              },
            },
            [
              _c("div", { staticClass: "occupy-result" }, [
                _c("i", { staticClass: "iconfont icontishi" }),
                _c("div", { staticClass: "right" }, [
                  _c("span", { staticClass: "title3" }, [
                    _vm._v("本小卷所有试题提交后"),
                    _c("br"),
                    _vm._v(" 不能返回修改 "),
                  ]),
                  _c("span", { staticClass: "detail" }, [
                    _vm._v("请确保准确作答后，再点击下一题"),
                  ]),
                ]),
              ]),
            ]
          ),
          _vm.question.type == 6
            ? _c("program-layout", {
                staticClass: "question",
                attrs: {
                  current: _vm.current,
                  commitLogs: _vm.commitLogs,
                  question: _vm.question,
                  total: _vm.total,
                  saveCode: _vm.saveCode,
                },
              })
            : _c("base-layout", {
                staticClass: "question",
                attrs: {
                  current: _vm.current,
                  commitLogs: _vm.commitLogs,
                  question: _vm.question,
                  questionIdList: _vm.questionIdList,
                  answerIdList: _vm.answerIdList,
                  total: _vm.total,
                  showVideo: _vm.showVideo,
                },
                on: {
                  submit: _vm.submit,
                  jumpToQuestionInRight: _vm.jumpToQuestionInRight,
                },
              }),
          _c(
            "div",
            {
              staticClass: "operate",
              style: {
                width:
                  _vm.question.type === 6
                    ? "50%"
                    : "calc((100% - 1230px)/2 + 910px)",
              },
            },
            [
              _c(
                "div",
                { staticClass: "tools" },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showCustomer,
                          expression: "showCustomer",
                        },
                      ],
                      staticClass: "service-box",
                    },
                    [
                      _c("customer", {
                        ref: "customer",
                        on: {
                          messageList: _vm.handleMessageList,
                          closeCustomer: function ($event) {
                            _vm.showCustomer = false
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.useCalculator
                    ? _c(
                        "span",
                        {
                          staticClass: "calculators",
                          attrs: { id: "calculator" },
                        },
                        [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showCalculator,
                                  expression: "showCalculator",
                                },
                              ],
                              staticClass: "calculator-box",
                            },
                            [
                              _c("calculator", {
                                on: {
                                  closeCalculator: () =>
                                    (_vm.showCalculator = false),
                                },
                              }),
                            ],
                            1
                          ),
                          _c("span", { on: { click: _vm.openCalculator } }, [
                            _c("i", { staticClass: "iconfont iconjisuanqi" }),
                            _vm._v("计算器"),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-badge",
                    {
                      staticClass: "item",
                      attrs: { "is-dot": _vm.unReadCounts },
                    },
                    [
                      _vm.useCustomer
                        ? _c(
                            "div",
                            {
                              staticClass: "service",
                              attrs: { id: "service" },
                              on: { click: _vm.openCustomer },
                            },
                            [
                              _c("i", { staticClass: "iconfont iconkefu1" }),
                              _vm._v("客服 "),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "btns" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.isEnablePreviousQuestion &&
                            _vm.current !== 0 &&
                            _vm.question.type !== 16,
                          expression:
                            "isEnablePreviousQuestion && current !== 0 && question.type !== 16",
                        },
                      ],
                      staticClass: "pre_btn",
                      attrs: { type: "primary", plain: "" },
                      on: {
                        click: function ($event) {
                          return _vm.prevQuestion()
                        },
                      },
                    },
                    [_vm._v("上一题")]
                  ),
                  _vm.question.type === 5 ||
                  _vm.question.type === 14 ||
                  _vm.question.type === 15 ||
                  _vm.question.type === 13
                    ? _c(
                        "el-button",
                        {
                          staticClass: "next_btn",
                          attrs: { type: "primary" },
                          on: { click: _vm.save },
                        },
                        [_vm._v("保存")]
                      )
                    : _vm._e(),
                  _vm.current !== _vm.total - 1 && _vm.question.type !== 16
                    ? _c(
                        "el-button",
                        {
                          staticClass: "next_btn",
                          attrs: {
                            loading: _vm.isLoadingNext,
                            type: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.next()
                            },
                          },
                        },
                        [_vm._v("下一题")]
                      )
                    : _vm._e(),
                  _vm.current === _vm.total - 1
                    ? _c(
                        "el-button",
                        {
                          staticClass: "next_btn",
                          attrs: { type: "primary" },
                          on: { click: _vm.submit },
                        },
                        [_vm._v("提交当前小卷")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }