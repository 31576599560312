<!--
 * @Author: Libra
 * @Date: 2021-04-27 10:59:54
 * @LastEditTime: 2022-11-16 16:59:35
 * @LastEditors: Libra
 * @Description: 问答题界面
 * @FilePath: /stone-exam-ui/src/views/question/components/ShortAnswer.vue
-->
<template>
  <div class="short-answer">
    <wangEditor
      @answer="getAnswer"
      @init="init"
      ref="editor"
      :question="question"
      :commitLogs="commitLogs"
    />
    <div class="con">
      <div class="file" v-for="(item, index) in originalFile" :key="index">
        <i class="el-icon-refresh-right" @click="reUpload"></i>
        {{ item }}
      </div>
    </div>
    <span
class="tips"
v-if="question.plugins.isEnableFileUpload"
      >上传本机文件提示:
      如果无法上传文件，可将文件传到网盘等云空间里，把文件的链接复制进来即可！</span
    >
  </div>
</template>

<script>
import WangEditor from '@/components/WangEditor'
import { bus } from '@/utils/bus'

export default {
  name: 'QuestionShortAnswer',
  props: ['question', 'commitLogs'],
  components: {
    WangEditor
  },
  data() {
    return {
      files: [],
      originalFile: [],
      answer: null
    }
  },
  mounted() {
    // 每两分钟自动调一次保存答案
    const t = setInterval(() => {
      console.log('问答题两分钟自动保存答案')
      bus.$emit('saveAnswer')
    }, 120000)
    this.$once('hook:beforeDestroy', () => {
      clearInterval(t)
    })
    this.init()
  },
  methods: {
    getAnswer(val, flag) {
      this.$emit('singleChange', val)
      this.answer = val
      this.originalFile = val.originalFile ? val.originalFile : []
      this.files = val.file ? val.file : []
      if (flag) {
        bus.$emit('saveAnswer')
      }
    },
    init() {
      for (const item of this.commitLogs) {
        if (item.questionUuid === this.question.questionUuid) {
          // 等待富文本编辑器加载完成再赋值，这块还没想好怎么搞
          const i = JSON.parse(JSON.stringify(item))
          this.answer = {
            file: i.file,
            originalFile: i.originalFile,
            value: i.value
          }
          const editor = this.$children[0]
          if (i.value.length && i.value[0] !== '') {
            editor.setContent(...i.value)
          }
          editor.files = i.file || []
          editor.originalFile = i.originalFile || []
          this.originalFile = item.originalFile ? item.originalFile : []
          this.files = item.file ? item.file : []
        }
      }
    },
    reUpload() {
      this.$refs.editor.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/theme/variables.scss';
::v-deep .mce-label {
  display: none;
}
.short-answer {
  overflow: auto;
  .tips {
    display: block;
    margin: 40px 0 20px 0;
    line-height: 2;
    font-size: 12px;
    background-color: rgb(253, 236, 236);
    color: #cb2a1d;
    padding: 10px 20px;
  }
  .con {
    margin-top: 40px;
    .file {
      padding: 5px;
      font-size: 14px;
      position: relative;
      &:hover {
        background-color: #fdecec;
        cursor: pointer;
      }
      .el-icon-refresh-right {
        position: absolute;
        top: 5px;
        right: 10px;
      }
    }
  }
}
.select {
  .options {
    ::v-deep .el-radio-group,
    ::v-deep .el-checkbox-group {
      width: 100%;
      .option {
        font-size: 15px;
        padding: 16px 24px 16px 8px;
        background-color: #f6f6f6;
        margin-bottom: 12px;
        box-sizing: border-box;
        &:nth-child(2n) {
          background-color: #fce6e8;
          color: #de0000;
        }
      }
    }
  }
}
</style>
