<!--
 * @Author: Libra
 * @Date: 2021-12-28 18:00:32
 * @LastEditTime: 2023-03-01 14:51:53
 * @LastEditors: Libra
 * @Description: 视频播放器
 * @FilePath: /stone-exam-ui/src/components/videoPlayer/index.vue
-->
<template>
  <video
    class="video-js vjs-default-skin vjs-big-play-centered"
    style="margin-top: 20px;"
    :id="id"
    width="480"
    height="360"
  >
    <source type="application/x-mpegURL" />
  </video>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      default: 'video-player'
    },
    answerUrl: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      player: null
    }
  },
  mounted() {
    this.initVideo()
  },
  beforeDestroy() {
    this.player.dispose()
  },
  methods: {
    initVideo() {
      // 初始化视频方法
      this.player = this.$video(this.id, {
        controls: true,
        autoplay: false,
        muted: false,
        preload: 'auto',
        controlBar: {
          fullscreenToggle: false
        },
        sources: [
          {
            src: this.answerUrl,
            type: 'video/mp4'
          }
        ]
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.video-js {
  border: 1px solid #ccc;
}
</style>
