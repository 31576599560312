var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.formula, function (item, index) {
          return _c(
            "el-tab-pane",
            { key: index, attrs: { label: item.label, name: item.label } },
            _vm._l(item.formulas, function (i, idx) {
              return _c("svg-icon", {
                key: idx,
                attrs: { svgTitle: i.name, "icon-class": i.name },
                nativeOn: {
                  click: function ($event) {
                    return _vm.add(i.formula)
                  },
                },
              })
            }),
            1
          )
        }),
        1
      ),
      _c("div", { ref: "mathEditor", staticClass: "math-editor" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }