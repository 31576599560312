var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "audio-contaienr" },
    [
      _c(
        "GlobalDialog",
        {
          attrs: {
            dialogVisible: _vm.showAudioDetect,
            title: "检测设备",
            isShowFooter: false,
            width: "500px",
          },
          on: {
            "dialog-cancel": function ($event) {
              _vm.showAudioDetect = false
            },
          },
        },
        [
          _c("div", { staticClass: "audio-detect" }, [
            _c("span", { staticClass: "title" }, [
              _vm._v("你能听到自己的声音吗？"),
            ]),
            _c("div", { staticClass: "detect-container" }, [
              _c("div", { staticClass: "left" }, [
                _c("div", { staticClass: "icon-container" }, [
                  _c("i", { staticClass: "iconfont iconshengyin" }),
                ]),
                _c("span", { staticClass: "start-title" }, [
                  _vm._v("开始检测"),
                ]),
              ]),
              _c("div", { staticClass: "right" }),
            ]),
            _c(
              "div",
              { staticClass: "buttons" },
              [
                _c("CustomButton", {
                  staticClass: "gap",
                  attrs: { title: "我能听到", isPlain: true },
                }),
                _c("CustomButton", {
                  attrs: { title: "没有声音", isPlain: true },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "drops" }, [
              _c("div", { staticClass: "left" }, [
                _c("i", { staticClass: "iconfont iconvoice-2" }),
                _c("span", [_vm._v("麦克风")]),
              ]),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: {
                          label: item.label,
                          value: item.value,
                          disabled: item.disabled,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "drops" }, [
              _c("div", { staticClass: "left" }, [
                _c("i", {
                  staticClass:
                    "iconfont iconyanjizhushou-shangchuan_yangshengqi",
                }),
                _c("span", [_vm._v("扬声器")]),
              ]),
              _c(
                "div",
                { staticClass: "right" },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.value2,
                        callback: function ($$v) {
                          _vm.value2 = $$v
                        },
                        expression: "value2",
                      },
                    },
                    _vm._l(_vm.options, function (item) {
                      return _c("el-option", {
                        key: item.value,
                        attrs: {
                          label: item.label,
                          value: item.value,
                          disabled: item.disabled,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "GlobalDialog",
        {
          attrs: {
            dialogVisible: _vm.showGetNullAudio,
            show_close: false,
            title: "提示",
            width: "500px",
          },
          on: {
            "dialog-cancel": function ($event) {
              _vm.showGetNullAudio = false
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "occupy-result",
              staticStyle: { "margin-bottom": "20px" },
            },
            [
              _c("i", { staticClass: "iconfont iconjinzhi" }),
              _c("div", { staticClass: "right" }, [
                _c("span", { staticClass: "title" }, [_vm._v("未获取到音频")]),
                _c("span", { staticClass: "content" }, [
                  _vm._v("请在手机端上传音频，再点击预览"),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "GlobalDialog",
        {
          attrs: {
            dialogVisible: _vm.showDeleteAudio,
            show_close: false,
            title: "提示",
            width: "500px",
            showSecond: true,
            btn_title: "取消",
            btn_title2: "确认",
          },
          on: {
            "dialog-cancel": function ($event) {
              _vm.showDeleteAudio = false
            },
            "dialog-ok": _vm.deleteAudio,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "occupy-result",
              staticStyle: { "margin-bottom": "20px" },
            },
            [
              _c("i", { staticClass: "iconfont iconshanchu" }),
              _c("div", { staticClass: "right" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("确定要删除吗？"),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "GlobalDialog",
        {
          attrs: {
            dialogVisible: _vm.showReUpload,
            show_close: false,
            title: "提示",
            width: "500px",
            showSecond: true,
            btn_title: "取消",
            btn_title2: "确认",
          },
          on: {
            "dialog-cancel": function ($event) {
              _vm.showReUpload = false
            },
            "dialog-ok": _vm.reUpload,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "occupy-result",
              staticStyle: { "margin-bottom": "20px" },
            },
            [
              _c("i", { staticClass: "iconfont iconrefresh" }),
              _c("div", { staticClass: "right" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("重新上传会删除原先的音频"),
                ]),
                _c("span", { staticClass: "content" }, [
                  _vm._v("你确定要重新上传音频吗？"),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "手机扫码上传音频",
            visible: _vm.showPhoneUpload,
            "before-close": _vm.beforePhoneUploadClose,
            width: "600px",
            modal: false,
            "modal-append-to-body": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showPhoneUpload = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "content-contaienr" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.qrLoading,
                      expression: "qrLoading",
                    },
                  ],
                  staticClass: "qr-container",
                  staticStyle: { width: "200px", height: "200px" },
                  attrs: { "element-loading-text": "二维码生成中" },
                },
                [
                  _c("vue-qr", {
                    staticStyle: { width: "200px", height: "200px" },
                    attrs: { size: 800, text: _vm.qrContent },
                  }),
                  _vm.isFailure
                    ? _c(
                        "div",
                        { staticClass: "refresh-qr" },
                        [
                          _c("span", { staticClass: "failure" }, [
                            _vm._v("二维码已失效"),
                          ]),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "danger" },
                              on: { click: _vm.refresh },
                            },
                            [_vm._v("请点击刷新")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("span", { staticClass: "upload-label" }, [
                _vm._v("手机微信扫码"),
              ]),
              _c(
                "el-button",
                {
                  staticStyle: { margin: "20px 0" },
                  attrs: { type: "danger", loading: _vm.isGettingAudio },
                  on: { click: _vm.getAudio },
                },
                [_vm._v("手机已传完，预览音频")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "answer" },
        [
          !_vm.isAnswerUseText
            ? _c("div", { staticClass: "audio-answer" }, [
                _c(
                  "div",
                  { staticClass: "btn-con" },
                  [
                    _vm.src === null
                      ? _c("CustomButton", {
                          staticClass: "gap",
                          attrs: {
                            title: "上传录音",
                            isPlain: false,
                            hasIcon: true,
                            iconName: "iconfont iconupload-fill",
                            clickMethod: _vm.upload,
                            isDisabled: _vm.src !== null,
                          },
                        })
                      : _vm._e(),
                    _c("CustomButton", {
                      staticClass: "gap",
                      attrs: {
                        title: "预览音频",
                        isPlain: true,
                        hasIcon: true,
                        iconName: "iconfont iconyanjing",
                        clickMethod: _vm.getAudio,
                      },
                    }),
                    _vm.src !== null
                      ? _c("CustomButton", {
                          attrs: {
                            title: "删除录音",
                            isPlain: true,
                            hasIcon: true,
                            clickMethod: () => (_vm.showDeleteAudio = true),
                            iconName: "iconfont iconshanchu",
                            isDisabled: _vm.src === null,
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm.src
                  ? _c("audio", { attrs: { controls: "", src: _vm.src } }, [
                      _vm._v(" 你的浏览器不支持 "),
                      _c("code", [_vm._v("audio")]),
                      _vm._v(" 标签. "),
                    ])
                  : _vm._e(),
              ])
            : _c("el-input", {
                attrs: {
                  type: "textarea",
                  rows: 8,
                  placeholder: "请输入答案...",
                },
                on: {
                  input: function ($event) {
                    return _vm.singleChange()
                  },
                },
                model: {
                  value: _vm.audioAnswer,
                  callback: function ($$v) {
                    _vm.audioAnswer = $$v
                  },
                  expression: "audioAnswer",
                },
              }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }