var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.clozeType === 5
        ? _c("cloze-essay", {
            attrs: { question: _vm.question, commitLogs: _vm.commitLogs },
            on: { singleChange: _vm.singleChange },
          })
        : _c("cloze-select", {
            attrs: { question: _vm.question, commitLogs: _vm.commitLogs },
            on: { singleChange: _vm.singleChange },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }