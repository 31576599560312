var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cloze-container" },
    _vm._l(_vm.groupOptionList, function (item, index) {
      return _c(
        "div",
        { key: index, staticClass: "questions" },
        [
          _c("div", { staticClass: "describe" }, [
            _c("span", [
              _vm._v("第" + _vm._s(index + 1) + "题 " + _vm._s(item.typeName)),
            ]),
            !_vm.$store.state.examInfo.isEvaluation
              ? _c("span", { staticStyle: { color: "#a58e54" } }, [
                  _vm._v(
                    _vm._s(
                      _vm.$route.query.isQuestionnaire === "true"
                        ? "--"
                        : item.questionScore
                    ) + " 分"
                  ),
                ])
              : _vm._e(),
          ]),
          _c("div", {
            staticClass: "sub-question",
            domProps: { innerHTML: _vm._s(_vm.strReplace(item.body)) },
          }),
          _vm._l(item.optionList, function (option, i) {
            return _c(
              "div",
              { key: i, staticClass: "sub-question-content" },
              [
                +item.type === 2 || +item.type === 7
                  ? _c(
                      "el-checkbox-group",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.multi($event, index)
                          },
                        },
                        model: {
                          value: _vm.clozeAnwser[index],
                          callback: function ($$v) {
                            _vm.$set(_vm.clozeAnwser, index, $$v)
                          },
                          expression: "clozeAnwser[index]",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            class: _vm.clozeAnwser[index].includes(
                              String(option.value)
                            )
                              ? "option2"
                              : "option",
                          },
                          [
                            _c(
                              "el-checkbox",
                              {
                                key: String(option.value),
                                attrs: { label: String(option.value) },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "flex-start",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.optionMap[i]) + "、"),
                                    ]),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.strReplace(option.title)
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                +item.type === 1 || +item.type === 3
                  ? _c(
                      "el-radio-group",
                      {
                        on: {
                          change: function ($event) {
                            return _vm.single($event, index)
                          },
                        },
                        model: {
                          value: _vm.clozeAnwser[index],
                          callback: function ($$v) {
                            _vm.$set(_vm.clozeAnwser, index, $$v)
                          },
                          expression: "clozeAnwser[index]",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            class:
                              _vm.clozeAnwser[index] === String(option.value)
                                ? "option2"
                                : "option",
                          },
                          [
                            _c(
                              "el-radio",
                              {
                                key: String(option.value),
                                attrs: { label: String(option.value) },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      display: "flex",
                                      "justify-content": "flex-start",
                                      "align-items": "center",
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        " " + _vm._s(_vm.optionMap[i]) + "、 "
                                      ),
                                    ]),
                                    _c("span", {
                                      staticClass: "option-content",
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.strReplace(option.title)
                                        ),
                                      },
                                    }),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }