var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "interview" },
    [
      _c(
        "div",
        {
          staticStyle: {
            width: "100%",
            display: "flex",
            "justify-content": "center",
            "align-items": "center",
          },
        },
        [
          (_vm.readMode === 0 || _vm.remainReadTime === 0) && !_vm.answerUrl
            ? _c("CustomButton", {
                attrs: {
                  title: "录制视频",
                  isPlain: false,
                  hasIcon: true,
                  classStr: "cusBtn",
                  iconName: "iconfont iconvideo2-fill",
                  clickMethod: () => {
                    _vm.startRecord(_vm.remainAnswerTimer)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      !!_vm.answerUrl
        ? _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "justify-content": "flex-start",
                "align-items": "center",
              },
            },
            [
              _c("video-player", {
                attrs: {
                  id: `video${_vm.question.questionUuid}`,
                  answerUrl: _vm.answerUrl,
                },
              }),
              _c("div", { staticStyle: { width: "20px" } }),
              _vm.question.isVideoAnswerDeletable
                ? _c("CustomButton", {
                    staticClass: "gap",
                    attrs: {
                      title: "删除视频",
                      isPlain: true,
                      hasIcon: true,
                      clickMethod: _vm.deleteVideo,
                      iconName: "iconfont iconshanchu",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "GlobalDialog",
        {
          staticStyle: { height: "100vh" },
          attrs: {
            dialogVisible: _vm.showInterviewRecord,
            show_close: false,
            isShowFooter: false,
            title: "视频录制",
            customClass: "video-record-dialog",
            width: "90%",
            showModal: true,
          },
          on: {
            "dialog-cancel": function ($event) {
              _vm.showInterviewRecord = false
            },
          },
        },
        [
          _c("div", { staticClass: "record-con" }, [
            _c("div", { staticClass: "record-title-con" }, [
              _c("div", { staticClass: "left" }, [
                _vm._v("视频录制"),
                _c("span", { staticClass: "recording" }, [
                  _vm._v("（录制中...）"),
                ]),
              ]),
              _vm.isStart
                ? _c("div", { staticClass: "record-title" }, [
                    _c("span", { staticClass: "title" }, [
                      _vm._v("正在录制："),
                    ]),
                    _c("span", { staticClass: "time" }, [
                      _vm._v(_vm._s(_vm.formatSeconds(_vm.videoTime))),
                    ]),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "right" }, [
                _c("div", { staticClass: "record-title2" }, [
                  _c("span", { staticClass: "title" }, [_vm._v("剩余时间：")]),
                  _c("span", { staticClass: "time" }, [
                    _vm._v(_vm._s(_vm.formatSeconds(_vm.remainTime))),
                  ]),
                ]),
                _vm.isWillExpire
                  ? _c(
                      "div",
                      {
                        staticClass: "record-title2",
                        staticStyle: { "margin-left": "10px" },
                      },
                      [
                        _c("span", { staticClass: "title" }, [
                          _vm._v("录制剩余时间："),
                        ]),
                        _c("span", { staticClass: "time" }, [
                          _vm._v(
                            _vm._s(_vm.formatSeconds(_vm.remainRecordTime))
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "main-con" }, [
              _c("div", {
                directives: [
                  {
                    name: "loading",
                    rawName: "v-loading",
                    value: _vm.isLoading,
                    expression: "isLoading",
                  },
                ],
                staticClass: "record",
                attrs: {
                  "element-loading-text": `${
                    !_vm.isStart ? "正在开始。。" : "录制结果获取中。。"
                  }`,
                },
              }),
              _c("div", { staticClass: "question-body-re" }, [
                _c("div", {
                  staticClass: "quest-body",
                  domProps: {
                    innerHTML: _vm._s(_vm.strReplace(_vm.question.body)),
                  },
                }),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "re-btn-con" },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-right": "10px",
                      "font-size": "12px",
                    },
                  },
                  [
                    _vm._v(" 摄像头： "),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "200px" },
                        on: { change: _vm.selectCamera },
                        model: {
                          value: _vm.defaultCamera,
                          callback: function ($$v) {
                            _vm.defaultCamera = $$v
                          },
                          expression: "defaultCamera",
                        },
                      },
                      _vm._l(_vm.cameraList, function (item) {
                        return _c("el-option", {
                          key: item.deviceId,
                          attrs: { label: item.label, value: item.deviceId },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm.isStart
                  ? _c("CustomButton", {
                      attrs: {
                        isDisabled: _vm.videoTime <= _vm.minTime,
                        title: `完成录制${
                          _vm.videoTime <= _vm.minTime
                            ? `，最短录制：(${_vm.formatSeconds(_vm.minTime)})`
                            : ""
                        }`,
                        isPlain: false,
                        clickMethod: _vm.stopRecord,
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticStyle: { margin: "0 10px", "font-size": "12px" } },
                  [
                    _vm._v(" 麦克风： "),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "200px" },
                        on: { change: _vm.selectAudio },
                        model: {
                          value: _vm.defaultAudio,
                          callback: function ($$v) {
                            _vm.defaultAudio = $$v
                          },
                          expression: "defaultAudio",
                        },
                      },
                      _vm._l(_vm.audioList, function (item) {
                        return _c("el-option", {
                          key: item.deviceId,
                          attrs: { label: item.label, value: item.deviceId },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }