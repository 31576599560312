<!--
 * @Author: Libra
 * @Date: 2021-05-24 14:57:21
 * @LastEditTime: 2023-10-30 14:20:43
 * @LastEditors: Libra
 * @Description: 代码编写和提交
 * @FilePath: /stone-exam-ui/src/views/question/components/WriteAndSubmit.vue
-->
<template>
  <div class="code-mirror-div">
    <div class="run-dialog" v-show="showRun">
      <i class="el-icon-close" @click="closeRunDialog"></i>
      <div class="top">
        <input class="output" disabled type="text" v-model="outputTitle" />
        <button
          class="run-btn"
          :disabled="runTime !== 30"
          @click="codeRun()"
          :style="runTime === 30 ? null : 'background-color:#ccc;'"
        >
          {{ runTime === 30 ? '' : runTime }}&nbsp;<i
            class="el-icon-video-play"
          ></i
          >&nbsp;运行
        </button>
      </div>
      <div class="output-detail" v-html="outputDetail"></div>
    </div>
    <div class="tool-bar">
      <el-select
        v-model="cmEditorMode"
        placeholder="请选择"
        size="small"
        style="width:200px;"
        @change="onEditorModeChange"
      >
        <el-option
          v-for="item in cmEditorModeOptions"
          :key="item.name"
          :label="item.name"
          :value="item.type"
        />
      </el-select>
    </div>
    <div class="editor-container">
      <code-mirror-editor
        ref="cmEditor"
        style="font-size:16px;"
        @getValue="singleChange"
        :cm-theme="cmTheme"
        :cm-mode="cmMode"
        :auto-format-json="autoFormatJson"
        :json-indentation="jsonIndentation"
      />
    </div>
    <div class="input-output-container">
      <div class="input-output" v-show="showInputOutput">
        <i class="el-icon-close" @click="closeInputOutput()"></i>
        <div class="input">
          <span class="title">填写输入数据</span>
          <textarea
            name=""
            id=""
            cols="30"
            rows="6"
            v-model="inputCase"
          ></textarea>
        </div>
        <div class="output">
          <span class="title">填写期望输出数据</span>
          <textarea
            name=""
            id=""
            cols="30"
            rows="6"
            v-model="outputCase"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CodeMirrorEditor from '@/components/Editor'
import Languages from '@/common/language'
import Api from '@/api/api'
export default {
  components: {
    CodeMirrorEditor
  },
  props: ['question', 'commitLogs'],
  data() {
    return {
      cmTheme: 'gruvbox-dark', // codeMirror主题
      showInputOutput: false,
      showRun: false,
      runCount: 0,
      outputTitle: '',
      outputDetail: '',
      inputCase: '',
      outputCase: '',
      // 每次运行时间间隔
      runTime: 30,
      codeId: null,
      // 防止重复点击
      hasClick: false,
      // codeMirror主题选项
      cmThemeOptions: [
        '3024-day',
        'default',
        '3024-night',
        'abcdef',
        'ambiance',
        'ayu-dark',
        'ayu-mirage',
        'base16-dark',
        'base16-light',
        'bespin',
        'blackboard',
        'cobalt',
        'colorforth',
        'darcula',
        'dracula',
        'duotone-dark',
        'duotone-light',
        'eclipse',
        'elegant',
        'erlang-dark',
        'gruvbox-dark',
        'hopscotch',
        'icecoder',
        'idea',
        'isotope',
        'lesser-dark',
        'liquibyte',
        'lucario',
        'material',
        'material-darker',
        'material-palenight',
        'material-ocean',
        'mbo',
        'mdn-like',
        'midnight',
        'monokai',
        'moxer',
        'neat',
        'neo',
        'night',
        'nord',
        'oceanic-next',
        'panda-syntax',
        'paraiso-dark',
        'paraiso-light',
        'pastel-on-dark',
        'railscasts',
        'rubyblue',
        'seti',
        'shadowfox',
        'solarized dark',
        'solarized light',
        'the-matrix',
        'tomorrow-night-bright',
        'tomorrow-night-eighties',
        'ttcn',
        'twilight',
        'vibrant-ink',
        'xq-dark',
        'xq-light',
        'yeti',
        'yonce',
        'zenburn'
      ],
      cmEditorMode: 'default', // 编辑模式
      // 编辑模式选项
      cmEditorModeOptions: [],
      cmMode: 'application/json', // codeMirror模式
      jsonIndentation: 2, // json编辑模式下，json格式化缩进 支持字符或数字，最大不超过10，默认缩进2个空格
      autoFormatJson: true // json编辑模式下，输入框失去焦点时是否自动格式化，true 开启， false 关闭
    }
  },
  created() {
    this.getLanguageList()
    for (const item of this.commitLogs) {
      if (item.questionUuid === this.question.questionUuid) {
        const val = JSON.parse(JSON.stringify(item.value))
        this.cmEditorMode = +val[0]
        // 更改一下mode，防止代码报错
        this.onEditorModeChange(+val[0])
        this.setValue(val[1])
      }
    }
  },
  mounted() {
    // 每两分钟自动调一次保存答案
    const t = setInterval(() => {
      console.log('编程题两分钟自动保存答案')
      this.saveAnwser()
    }, 120000)
    this.$once('hook:beforeDestroy', () => {
      clearInterval(t)
    })
  },
  watch: {
    question: function(newQuestion) {
      this.getLanguageList()
      let isHasAnwser = false
      for (const item of this.commitLogs) {
        if (item.questionUuid === newQuestion.questionUuid) {
          isHasAnwser = true
          const val = JSON.parse(JSON.stringify(item.value))
          this.cmEditorMode = +val[0]
          // 更改一下mode，防止代码报错
          this.onEditorModeChange(+val[0])
          this.setValue(val[1])
        }
      }
      if (!isHasAnwser) {
        this.getLanguageList()
      }
    },
    $route() {
      this.codeId = null
    }
  },
  methods: {
    // 切换编辑模式事件处理函数
    onEditorModeChange(value) {
      switch (value) {
        case 1:
          this.cmMode = 'text/x-csrc'
          break
        case 2:
          this.cmMode = 'text/x-csrc'
          break
        case 3:
          this.cmMode = 'text/x-c++src'
          break
        case 4:
          this.cmMode = 'text/x-c++src'
          break
        case 5:
          this.cmMode = 'text/x-csharp'
          break
        case 7:
          this.cmMode = 'text/x-java'
          break
        case 9:
          this.cmMode = 'text/javascript'
          break
        case 10:
          this.cmMode = 'text/x-python'
          break
        case 11:
          this.cmMode = 'text/x-python'
          break
        case 12:
          this.cmMode = 'text/x-php'
          break
        case 13:
          this.cmMode = 'text/x-swift'
          break
        case 14:
          this.cmMode = 'text/x-objectivec'
          break
        case 15:
          this.cmMode = 'text/x-go'
          break
        case 16:
          this.cmMode = 'text/x-ruby'
          break
        case 17:
          this.cmMode = 'text/x-kotlin'
          break
        case 18:
          this.cmMode = 'text/x-rsrc'
          break
        case 19:
          this.cmMode = 'text/x-sql'
          break
        case 20:
          this.cmMode = 'text/x-rustsrc'
          break
        case 21:
          this.cmMode = 'text/x-scala'
          break
        case 22:
          this.cmMode = 'text/x-groovy'
          break
        case 23:
          this.cmMode = 'text/x-common-lisp'
          break
        case 24:
          this.cmMode = 'text/x-bash'
          break
        default:
          this.cmMode = 'application/json'
      }
      // 如果答过题，就显示答的内容
      if (this.question.value && +this.question.value.value[0] === value) {
        this.setValue(this.strReplace(this.question.value.value[1]))
        // 这里需要等setValue执行完毕，才能获取到editor的现值
        setTimeout(() => {
          this.singleChange()
        }, 200)
      } else {
        // 如果没有答过，就显示默认模板
        const codeTemplateList = this.question.codeTemplateList
        if (!codeTemplateList.length) {
          this.setValue('')
          setTimeout(() => {
            this.singleChange()
          }, 200)
          return
        }
        for (const item of codeTemplateList) {
          if (item.type === value) {
            this.setValue(this.strReplace(item.template))
          }
        }
      }
    },
    // 获取内容
    getValue() {
      return this.$refs.cmEditor.getValue()
    },
    // 修改内容
    setValue(jsonValue) {
      setTimeout(() => {
        this.$refs.cmEditor.setValue(jsonValue)
      }, 100)
    },
    // 自测数据
    async selfTest(showInputOutput = true) {
      this.showInputOutput = showInputOutput
      if (this.showInputOutput === false) {
        this.inputCase = ''
        this.outputCase = ''
      }
    },
    closeInputOutput() {
      this.showInputOutput = false
      this.inputCase = ''
      this.outputCase = ''
    },
    // 代码运行结果
    async codeRun() {
      if (this.hasClick) return
      this.hasClick = true
      this.outputTitle = ''
      this.outputDetail = ''
      const t = setInterval(() => {
        this.runTime--
        this.$emit('changeTime', this.runTime)
        if (this.runTime <= 0) {
          clearInterval(t)
          this.hasClick = false
          this.runTime = 30
        }
      }, 1000)
      if (!this.showRun) {
        this.showRun = !this.showRun
      }
      let res
      if (this.inputCase && this.outputCase) {
        res = await Api.runUserCode({
          languageType: this.cmEditorMode,
          questionUuid: this.question.questionUuid,
          sourceCode: this.getValue(),
          stdin: this.inputCase,
          expectedOutput: this.outputCase
        })
        // this.codeId = res.data
      } else {
        res = await Api.runCode({
          languageType: this.cmEditorMode,
          questionUuid: this.question.questionUuid,
          sourceCode: this.getValue(),
          stdin: null
        })
        this.codeId = res.data
      }
      // 这里更新了codeId，要保存到答案中去
      this.$emit('singleChange', {
        value:
          this.codeId === null
            ? [this.cmEditorMode, this.getValue()]
            : [this.cmEditorMode, this.getValue(), this.codeId],
        file: null,
        originalFile: null
      })
      if (res.code === 0) {
        this.outputTitle = '运行中...'
        this.runCount++
        let result = {}
        const t = setInterval(async() => {
          result = await Api.getRunResult(res.data)
          if (result.code === 0 || this.runCount === 3) {
            clearInterval(t)
            const resData = result.data
            if (resData) {
              if (resData.isMatchExpected !== null) {
                if (resData.isMatchExpected) {
                  this.outputTitle = '恭喜编译成功井运行通过( Accepted )'
                  this.outputDetail = `说明:<br />
所有测试数据均已输出正确结果!`
                } else {
                  if (resData.compileOutput) {
                    this.outputTitle = '您的程序编译错误 Wrong Answer'
                    this.outputDetail = resData.compileOutput
                    return
                  }
                  this.outputTitle = '您的程序输出结果错误 Wrong Answer'
                  this.outputDetail = `说明:<br />
所有测式数据均未输出正确结果!<br /><br /><br />
代码运行没通过?建议您再仔细阅读编程题须知，<a href="https://exam-file.iguokao.com/oj.html" target="_blank" style="color: red;">现在查看</a>`
                }
              } else {
                if (resData.passRate === 0) {
                  if (resData.compileOutput) {
                    this.outputTitle = '您的程序编译错误 Wrong Answer'
                    this.outputDetail = resData.compileOutput
                    return
                  }
                  this.outputTitle = '您的程序输出结果错误 Wrong Answer'
                  this.outputDetail = `说明:<br />
所有测式数据均未输出正确结果!<br /><br /><br />
代码运行没通过?建议您再仔细阅读编程题须知，<a href="https://exam-file.iguokao.com/oj.html" target="_blank" style="color: red;">现在查看</a>`
                } else if (resData.passRate === 100) {
                  this.outputTitle = '恭喜编译成功井运行通过( Accepted )'
                  this.outputDetail = `说明:<br />
所有测试数据均已输出正确结果!`
                } else {
                  this.outputTitle = '答案错误( Wrong Answer(WA) )'
                  this.outputDetail = `说明:<br />
所有测试数据正确率为${Number(resData.passRate).toFixed(0)}% !<br />
可以尝试再次完善代码，井调试，争取全部AC通过`
                }
              }
            } else {
              this.outputTitle = '运行超时'
            }
            this.runCount = 0
            return
          }
          this.runCount++
        }, 10000)
      } else {
        console.log('代码运行失败！！')
      }
      // 清空 codeId
      this.codeId = null
    },
    // 获得编程语言列表
    getLanguageList() {
      this.languageList = this.question.codeSupportLanguage
      const res = []
      for (const item of this.languageList) {
        for (const i of Languages) {
          if (item === i.type) {
            res.push({
              type: i.type,
              name: `${i.name} ${i.sdk}`
            })
          }
        }
      }
      this.cmEditorMode = res[0].type
      this.cmEditorModeOptions = res
      const codeTemplateList = this.question.codeTemplateList
      if (codeTemplateList.length === 0) {
        this.setValue('')
        return
      }
      for (const item of codeTemplateList) {
        if (item.type === res[0].type) {
          this.setValue(this.strReplace(item.template))
        }
      }
    },
    closeRunDialog() {
      this.showRun = false
      this.outputTitle = ''
      this.outputDetail = ''
    },
    singleChange() {
      this.$emit('singleChange', {
        value:
          this.codeId === null
            ? [this.cmEditorMode, this.getValue()]
            : [this.cmEditorMode, this.getValue(), this.codeId],
        file: null,
        originalFile: null
      })
    },
    // 字符串替换
    strReplace(str) {
      if (!str) return
      const s = str.replace(/&lt;/g, '<')
      return s.replace(/&gt;/g, '>')
    },
    async saveAnwser() {
      const question = this.question
      await Api.answerSave({
        partUuid: this.$route.query.partUuid,
        questionUuid: question.questionUuid,
        value: question.value.value,
        file: question.value.file,
        originalFile: question.value.originalFile
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.code-mirror-div {
  position: relative;
  ::v-deep .CodeMirror-gutters {
    z-index: 1;
  }
  ::v-deep .CodeMirror {
    z-index: 1;
  }
  .run-dialog {
    position: fixed;
    right: 20px;
    bottom: 70px;
    z-index: 3;
    width: 400px;
    height: 500px;
    padding: 10px 20px;
    box-sizing: border-box;
    background-color: #1e1e1e;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .el-icon-close {
      color: #fff;
      position: absolute;
      top: 5px;
      right: 5px;
      cursor: pointer;
    }
    .top {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .run-btn {
        font-size: 14px;
        width: 100px;
        &:hover {
          background-color: rgba(243, 90, 90, 0.8);
        }
      }
      .output {
        flex: 1;
        margin-right: 10px;
        height: 30px;
        padding: 0;
        color: red;
        font-size: 14px;
        padding-left: 10px;
        background-color: #fff;
        outline: none;
        border: none;
      }
      button {
        height: 30px;
        background-color: #cb2a1d;
        outline: none;
        border: none;
        color: #fff;
        padding: 0 10px;
        border-radius: 3px;
      }
    }
    .output-detail {
      width: 100%;
      flex: 1;
      background-color: #000;
      margin: 20px 0 0 0;
      padding: 10px;
      font-size: 14px;
      color: white;
      overflow: auto;
    }
  }
  .tool-bar {
    width: 100%;
    background-color: #282828;
    padding: 10px;
    border-radius: 5px 5px 0 0;
  }
  .editor-container {
    height: 100%;
    overflow: auto;
  }
  .input-output-container {
    padding: 0 20px;
    position: absolute;
    right: 0;
    font-size: 14px;
    width: 50%;
    .input-output {
      width: 50%;
      position: fixed;
      bottom: 70px;
      right: 0px;
      padding: 10px;
      padding-left: 20px;
      background-color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      border-right: 1px solid #eee;
      z-index: 2;
      .el-icon-close {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
      }
      div {
        flex: 1;
        textarea {
          margin-top: 10px;
          width: 95%;
          outline: none;
          border: 1px solid #eee;
          box-sizing: border-box;
          padding: 10px;
          border-radius: 5px;
          resize: none;
          background-color: #fff;
          &:focus {
            border: 1px solid #cb2a1d;
          }
        }
      }
    }
  }
}
</style>
