<!--
 * @Author: Libra
 * @Date: 2021-12-24 09:57:38
 * @LastEditTime: 2023-01-28 15:03:59
 * @LastEditors: Libra
 * @Description:视频题 腾讯
 * @FilePath: /stone-exam-ui/src/views/question/components/Video.vue
-->
<template>
  <div class="video-contaienr">
    <GlobalDialog
      :dialogVisible="showDeleteVideo"
      :show_close="false"
      @dialog-cancel="showDeleteVideo = false"
      @dialog-ok="deleteVideo"
      title="提示"
      width="500px"
      :showSecond="true"
      btn_title="取消"
      btn_title2="确认"
    >
      <div class="occupy-result" style="margin-bottom: 20px;">
        <i class="iconfont iconshanchu"></i>
        <div class="right">
          <span class="title">确定要删除吗？</span>
        </div>
      </div>
    </GlobalDialog>
    <GlobalDialog
      :dialogVisible="showDetectDevice"
      @dialog-cancel="showDetectDevice = false"
      @dialog-ok="checkAllDevice"
      @close = "showDetectDevice = false"
      title="设备检测"
      :is-show-footer="false"
      width="600px"
    >
      <div class="device-detect" v-if="showDetectDevice">
        <device-detect @next="showDetectDevice = false" />
      </div>
    </GlobalDialog>
    <GlobalDialog
      :dialogVisible="showVideoRecord"
      :show_close="false"
      :isShowFooter="false"
      @dialog-cancel="showVideoRecord = false"
      title="视频录制"
      customClass="video-record-dialog"
      width="860px"
      style="cursor: move;"
      v-drag
    >
      <div class="record-con">
        <div class="record-title-con">
          <div class="left">视频录制</div>
          <div class="right">
            <div class="record-title" v-if="isStart">
              <span class="title">正在录制：</span>
              <span class="time">{{ formatSeconds(videoTime) }}</span>
            </div>
            <div class="record-title2" v-if="isWillExpire">
              <span class="title">剩余时间：</span>
              <span class="time">{{ formatSeconds(remainTime) }}</span>
            </div>
          </div>
        </div>
        <div class="main-con">
          <div
            class="record"
            v-loading="isLoading"
            element-loading-text="录制结果获取中。。"
          >
            <div class="record-tips" v-if="!isStart">
              <div class="btn_start" @click="startRecord">开始录制</div>
              注意事项：
              <span class="content">
                1、录制一旦开始，则不能暂停，请务必准备充分后，再录制；<br />
                2、录制时长不要超过题目的时长要求；<br />
                3、不要使用外接显示器，遇到设备故障时，重启电脑再试。
                <!-- 4、建议先录制一段10秒的短视频，检查一下是否能看到视频画面、听到声音。 -->
              </span>
            </div>
          </div>
          <div class="question-body-re">
            <div class="quest-body" v-html="strReplace(question.body)"></div>
          </div>
        </div>
        <div class="re-btn-con">
          <div style="margin-right: 10px;font-size: 12px;">
            摄像头：
            <el-select
              style="width:100px"
              v-model="defaultCamera"
              @change="selectCamera"
            >
              <el-option
                v-for="item in cameraList"
                :key="item.deviceId"
                :label="item.label"
                :value="item.deviceId"
              >
              </el-option>
            </el-select>
          </div>
          <CustomButton
            v-if="isStart"
            :isDisabled="videoTime<=5"
            title="完成录制"
            :isPlain="false"
            :clickMethod="stopRecord"
          ></CustomButton>
          <CustomButton
            class="gap"
            title="取消录制"
            v-if="!isStart"
            :isPlain="true"
            :clickMethod="() => (showVideoRecord = false)"
          ></CustomButton>
          <div style="margin: 0 10px; font-size: 12px;">
            麦克风：
            <el-select
              style="width:100px"
              v-model="defaultAudio"
              @change="selectAudio"
            >
              <el-option
                v-for="item in audioList"
                :key="item.deviceId"
                :label="item.label"
                :value="item.deviceId"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
    </GlobalDialog>
    <div class="answer">
      <div class="btn-con">
        <CustomButton
          class="gap"
          title="录制视频"
          :isPlain="false"
          :hasIcon="true"
          :isDisabled="!!answerUrl"
          iconName="iconfont iconvideo2-fill"
          :clickMethod="() => (showVideoRecord = true)"
        ></CustomButton>
        <CustomButton
          class="gap"
          title="删除视频"
          :isPlain="true"
          :hasIcon="true"
          v-if="question.isVideoAnswerDeletable"
          :clickMethod="() => (showDeleteVideo = true)"
          iconName="iconfont iconshanchu"
        ></CustomButton>
        <CustomButton
          title="设备检测"
          :isPlain="true"
          :hasIcon="true"
          :clickMethod="detectDevice"
          iconName="iconfont iconshexiangtou3"
        ></CustomButton>
      </div>
    </div>
    <div v-if="!!answerUrl">
      <video-player
        :id="`video${question.questionUuid}`"
        :answerUrl="answerUrl"
      />
    </div>
  </div>
</template>

<script>
import CustomButton from '@/components/CustomButton'
import GlobalDialog from '@/components/GlobalDialog'
import DeviceDetect from '@/components/deviceDetect/index.vue'
import Api from '@/api/api'
import videoPlayer from '@/components/videoPlayer'
import TRTC from 'trtc-js-sdk'

export default {
  props: ['question', 'commitLogs'],
  data() {
    return {
      showDeleteVideo: false,
      showDetectDevice: false,
      showVideoRecord: false,
      client: null,
      options: {},
      isLoading: false,
      isStart: false,
      videoTime: 0,
      remainTime: 30,
      isWillExpire: false,
      timer: null,
      answerUrl: null,
      player: null,
      audioList: [],
      cameraList: [],
      defaultAudio: '',
      defaultCamera: ''
    }
  },
  components: {
    CustomButton,
    GlobalDialog,
    DeviceDetect,
    videoPlayer
  },
  directives: {
    drag: {
      // 指令的定义
      bind: (el) => {
        const oDiv = el
        oDiv.onmousedown = (e) => {
          const disX = e.clientX - oDiv.offsetLeft
          const disY = e.clientY - oDiv.offsetTop
          document.onmousemove = (e) => {
            const left = e.clientX - disX
            const top = e.clientY - disY
            oDiv.style.left = left + 'px'
            oDiv.style.top = top + 'px'
          }
          document.onmouseup = (e) => {
            document.onmousemove = null
            document.onmouseup = null
          }
        }
      }
    }
  },
  created() {
    for (const item of this.commitLogs) {
      if (item.questionUuid === this.question.questionUuid) {
        const val = JSON.parse(JSON.stringify(item.value))
        this.answerUrl = val[0]
      }
    }
  },
  async mounted() {
    this.deviceChange()
    this.audioList = await TRTC.getMicrophones()
    this.cameraList = await TRTC.getCameras()
    this.defaultAudio = this.audioList[0].deviceId
    this.defaultCamera = this.cameraList[0].deviceId
    console.log(this.audioList, this.cameraList)
  },
  beforeDestroy() {
    // 小卷或者考试时间到了，没有停止录制，这里手动停止一下
    this.stopRecord()
  },
  methods: {
    detectDevice() {
      this.showDetectDevice = true
    },
    // 获取 rtc token
    async getRtcToken() {
      const questionUuid = this.question.questionUuid
      const res = await Api.getQuestionSig(questionUuid)
      this.options = res.data
    },
    async startRecord() {
      clearInterval(this.timer)
      this.showVideoRecord = true
      this.client = null
      await this.getRtcToken()
      await this.initClient()
      await this.joinChannel()
    },
    // 创建频道
    async initClient() {
      const { sig, userId, appId } = this.options
      const option = {
        mode: 'rtc',
        sdkAppId: appId,
        userId,
        userSig: sig,
        userDefineRecordId: userId,
        autoSubscribe: false
      }
      this.client = await TRTC.createClient(option)
    },
    /**
     * 检测设备热插拔
     */
    async deviceChange() {
      // 1. 保存一份设备列表
      let prevDevices = await TRTC.getDevices()
      // 2. 监听设备变更事件
      navigator.mediaDevices.addEventListener('devicechange', async() => {
        // 3. 设备变更时，获取变更后的设备列表，用于和 prevDevices 比对
        const devices = await TRTC.getDevices()
        // 4. 新增的设备列表
        const devicesAdded = devices.filter(
          (device) =>
            prevDevices.findIndex(
              ({ deviceId }) => device.deviceId === deviceId
            ) < 0
        )
        // 5. 移除的设备列表
        const devicesRemoved = prevDevices.filter(
          (prevDevice) =>
            devices.findIndex(
              ({ deviceId }) => prevDevice.deviceId === deviceId
            ) < 0
        )
        if (devicesAdded.length > 0) {
          this.handleDevicesAdded(devicesAdded)
        }
        if (devicesRemoved.length > 0) {
          this.handleDevicesRemoved(devicesRemoved)
        }
        prevDevices = devices
      })
    },
    async handleDevicesAdded(devicesAdded) {
      devicesAdded.forEach((device) => {
        if (device.kind === 'audioinput') {
          // 提示用户检测到新麦克风插入。若用户需要切换到新设备，可以调用 localStream.switchDevice 接口切换设备
          this.audioList.push(device)
          this.audioListStr = this.audioList.map((device) => device.label)
        } else if (device.kind === 'videoinput') {
          // 提示用户检测到新摄像头插入。若用户需要切换到新设备，可以调用 localStream.switchDevice 接口切换设备
          this.cameraList.push(device)
          this.cameraListStr = this.cameraList.map((device) => device.label)
        }
      })
    },
    async handleDevicesRemoved(devicesRemoved) {
      devicesRemoved.forEach((device) => {
        if (device.kind === 'audioinput') {
          // 从 audioList 中移除设备
          this.audioList = this.audioList.filter(
            (d) => d.deviceId !== device.deviceId
          )
          this.audioListStr = this.audioList.map((device) => device.label)
        } else if (device.kind === 'videoinput') {
          // 从 cameraList 中移除设备
          this.cameraList = this.cameraList.filter(
            (d) => d.deviceId !== device.deviceId
          )
          this.cameraListStr.value = this.cameraList.map(
            (device) => device.label
          )
        }
      })
    },
    async selectAudio(device) {
      const localStream = this.cameraLocalStream
      for (const item of this.audioList) {
        if (item.deviceId === device) {
          localStream && localStream.switchDevice('audio', item.deviceId)
          this.defaultAudio = device
          break
        }
      }
    },
    selectCamera(device) {
      const localStream = this.cameraLocalStream
      console.log(localStream)
      for (const item of this.cameraList) {
        console.log(item.deviceId, device)
        if (item.deviceId === device) {
          localStream && localStream.switchDevice('video', item.deviceId)
          this.defaultCamera = device
          break
        }
      }
    },
    handleExpire() {
      this.isWillExpire = true
      if (this.timer2) return
      this.timer2 = setInterval(() => {
        this.remainTime--
        if (this.remainTime === 0) {
          this.stopRecord()
          this.isWillExpire = false
          this.remainTime = 30
          clearInterval(this.timer2)
        }
      }, 1000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.timer2)
      })
    },
    // 加入频道
    async joinChannel() {
      this.client
        .join({ roomId: this.options.roomNumber })
        .then(async() => {
          try {
            this.cameraLocalStream = TRTC.createStream({
              userId: this.options.userId,
              audio: true,
              video: true,
              cameraId: this.defaultCamera,
              microphoneId: this.defaultAudio
            })
          } catch (error) {
            this.$message.error('开始录制失败，请先进行设备检测，确保设备一切正常！')
          }
          this.cameraLocalStream.setVideoProfile({
            width: 480,
            height: 360,
            frameRate: 15,
            bitrate: 300 /* kpbs */
          })
          this.cameraLocalStream
            .initialize()
            .then(() => {
              this.client
                .publish(this.cameraLocalStream)
                .then(() => {
                  const localPlayerContainer = document.createElement('div')
                  localPlayerContainer.id = this.options.userId
                  localPlayerContainer.style.width = '560px'
                  localPlayerContainer.style.height = '420px'
                  const container = document.querySelector(
                    '.record-con .record'
                  )
                  container.append(localPlayerContainer)
                  this.cameraLocalStream.play(localPlayerContainer)
                  this.isStart = true
                  this.countDown()
                })
                .catch((error) => {
                  console.error('本地流发布失败 ' + error)
                })
              console.log('初始化本地流成功')
            })
            .catch((error) => {
              this.$message.error('开始录制失败，请先进行设备检测，确保设备一切正常！')
              console.error('初始化本地流失败 ' + error)
            })
        })
        .catch((error) => {
          console.error('进房失败 ' + error)
        })
    },
    singleChange(answer) {
      this.$emit('singleChange', answer)
    },
    // 获取视频答案
    async getVideoAnswer(questionUuid) {
      const res = await Api.getAnswer(questionUuid)
      this.singleChange(res.data)
      return res.data
    },
    // 离开频道
    async stopRecord() {
      this.client && this.client.leave()
      this.isLoading = true
      this.client = null
      this.client = null
      const container = document.getElementById(this.options.userId)
      container && container.remove()
      this.resetTimer()
      const questionUuid = this.question.questionUuid
      let count = 0
      // 每十秒调用一次
      const timer = setInterval(async() => {
        count++
        if (count === 4) {
          clearInterval(timer)
          return
        }
        const res = await this.getVideoAnswer(questionUuid)
        if (res) {
          this.answerUrl = res.value[0]
          clearInterval(timer)
          this.isLoading = false
          this.isStart = false
          this.showVideoRecord = false
        }
      }, 5000)
    },
    async deleteVideo() {
      const questionUuid = this.question.questionUuid
      const res = await Api.deleteAnswer(questionUuid)
      if (res.code === 0) {
        this.answerUrl = null
        this.showDeleteVideo = false
        await this.getVideoAnswer(questionUuid)
        console.log('deleteVideo success!')
      } else {
        console.log('deleteVideo fail!')
      }
    },
    // 计时
    countDown() {
      const twentyMin = 20 * 60
      clearInterval(this.timer)
      this.timer = setInterval(() => {
        if (this.videoTime === twentyMin - 30) {
          this.handleExpire()
        }
        if (this.videoTime === twentyMin) {
          this.stopRecord()
          return
        }
        this.videoTime++
      }, 1000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.timer)
      })
    },
    // reset timer
    resetTimer() {
      this.videoTime = 0
      clearInterval(this.timer)
    },
    // 秒转为时分秒 00:00:00 格式
    formatSeconds(value) {
      let theTime = parseInt(value) // 秒
      let theTime1 = 0 // 分
      let theTime2 = 0 // 小时
      if (theTime > 60) {
        theTime1 = parseInt(theTime / 60)
        theTime = parseInt(theTime % 60)
        if (theTime1 > 60) {
          theTime2 = parseInt(theTime1 / 60)
          theTime1 = parseInt(theTime1 % 60)
        }
      }
      let result = ''
      if (theTime2 > 0) {
        result = `${theTime2 < 10 ? '0' + theTime2 : theTime2}:`
      }
      result += `${theTime1 < 10 ? '0' + theTime1 : theTime1}:`
      result += `${theTime < 10 ? '0' + theTime : theTime}`
      return result
    },
    // 字符串替换
    strReplace(str) {
      if (!str) return
      const s = str.replace(/&lt;/g, '<')
      return s.replace(/&gt;/g, '>')
    },
    // 设备检测
    checkAllDevice() {
      this.$refs.detect.checkAllDevice()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 0 10px 10px 10px;
}
::v-deep .dj-dialog-content {
  padding: 0;
  overflow: unset;
}
.video-contaienr {
  width: 100%;
  ::v-deep .video-record-dialog {
    border-radius: 20px;
    box-shadow: 4px 4px 19px 6px rgba(0, 0, 0, 0.25);
  }
  ::v-deep .video-record-dialog .el-dialog__header {
    display: none;
  }
  .occupy-result {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .iconfont {
      font-size: 100px;
      color: #e06563;
    }
    .right {
      margin-left: 20px;
      .title2 {
        display: block;
        font-size: 18px;
        margin-bottom: 20px;
      }
      .title3 {
        display: block;
        font-size: 20px;
        color: #cb2a1d;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .title {
        display: block;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
        margin-top: 10px;
        letter-spacing: 2px;
      }
      .content {
        display: block;
      }
    }
  }
  .record-con {
    width: 100%;
    .record-title-con {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        padding: 20px 0 20px 20px;
        font-size: 18px;
      }
      .right {
        padding: 20px 20px 20px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .record-title {
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          .title {
            font-size: 14px;
          }
          .time {
            color: #f56c6c;
            font-weight: bold;
            font-size: 22px;
          }
        }
        .record-title2 {
          font-size: 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          .title {
            font-size: 14px;
          }
          .time {
            font-weight: bold;
            color: #f56c6c;
            font-size: 18px;
          }
        }
      }
    }
    .main-con {
      display: flex;
      margin: 0 20px;
      padding: 20px;
      justify-content: space-between;
      align-items: center;
      background-color: #e6e6e6;
      border-radius: 20px;
      .question-body-re {
        width: 180px;
        height: 420px;
        border-radius: 20px;
        padding: 20px;
        background-color: #4c4c4c;
        .quest-body {
          height: 100%;
          overflow: auto;
          color: #fff;
        }
      }
      .record {
        width: 560px;
        height: 420px;
        overflow: hidden;
        background-color: #4c4c4c;
        border-radius: 20px;
        position: relative;
        color: #fff;
        .record-tips {
          margin-top: 40px;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          padding: 0 20px;
          flex-direction: column;
          font-size: 18px;
          font-weight: bold;
          line-height: 2;
          .content {
            font-size: 16px;
            font-weight: normal;
          }
          .btn_start {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
            width: 150px;
            height: 80px;
            border-radius: 3px;
            background-color: #f56c6c;
            align-self: center;
            cursor: pointer;
            &:hover {
              background-color: rgba(245, 108, 108, 0.8);
            }
          }
        }
      }
    }
    .re-btn-con {
      padding: 0 20px;
      margin-top: 20px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .gap {
        margin-right: 10px;
      }
    }
  }
  .answer {
    .btn-con {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 24px;
      .gap {
        margin-right: 20px;
      }
    }
  }
  .occupy-result {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    .iconfont {
      font-size: 89px;
      color: #cb2a1d;
    }
    .right {
      margin-left: 20px;
      .title2 {
        display: block;
        font-size: 18px;
        margin-bottom: 20px;
      }
      .title3 {
        display: block;
        font-size: 20px;
        color: #cb2a1d;
        font-weight: bold;
        margin-bottom: 20px;
      }
      .title {
        display: block;
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 20px;
        margin-top: 10px;
      }
      .content {
        display: block;
      }
    }
  }
}
</style>
