/*
 * @Author: Libra
 * @Date: 2021-09-15 18:22:26
 * @LastEditTime: 2021-09-15 18:52:46
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-exam-ui/src/common/WaterMark.js
 */
const setWatermark = (element, str1) => {
  const id = '1.23452384164.123412415'

  if (document.getElementById(id) !== null) {
    document.body.removeChild(document.getElementById(id))
  }

  const can = document.createElement('canvas')
  // 设置canvas画布大小
  can.width = 300
  can.height = 160

  const cans = can.getContext('2d')
  cans.rotate((-20 * Math.PI) / 180) // 水印旋转角度
  cans.font = '24px Vedana'
  cans.fillStyle = '#666666'
  cans.textAlign = 'center'
  cans.textBaseline = 'Middle'
  cans.fillText(str1, can.width / 2, can.height) // 水印在画布的位置x，y轴
  const div = document.createElement('div')
  div.id = id
  div.style.pointerEvents = 'none'
  div.style.top = '40px'
  div.style.left = '0px'
  div.style.opacity = '0.1'
  div.style.position = 'fixed'
  div.style.zIndex = '1'
  div.style.width = element.clientWidth + 'px'
  div.style.height = element.clientHeight + 'px'
  div.style.background =
    'url(' + can.toDataURL('image/png') + ') left top repeat'
  element.appendChild(div)
  return id
}

// 添加水印方法
export const setWaterMark = (ele, str1) => {
  let id = setWatermark(ele, str1)
  if (document.getElementById(id) === null) {
    id = setWatermark(ele, str1)
  }
}

// 移除水印方法
export const removeWatermark = () => {
  const id = '1.23452384164.123412415'
  if (document.getElementById(id) !== null) {
    document.body.removeChild(document.getElementById(id))
  }
}
