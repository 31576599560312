var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "question" }, [
    _c("div", { staticClass: "left", attrs: { id: "left" } }, [
      _c("div", { staticClass: "question-type" }, [
        _c("div", [
          _vm._v(" " + _vm._s(_vm.current + 1) + "、编程题 "),
          !_vm.$store.state.examInfo.isEvaluation
            ? _c("span", { staticClass: "score" }, [
                _vm._v(
                  _vm._s(
                    _vm.$store.state.isPractice ||
                      _vm.$route.query.isQuestionnaire === "true"
                      ? "--"
                      : _vm.question.questionScore
                  )
                ),
                _c("span", { staticStyle: { "font-size": "12px" } }, [
                  _vm._v("分"),
                ]),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "toggle-nail" }, [
          _c("span", [
            _c(
              "span",
              { staticStyle: { color: "#f35a5a", "font-size": "18px" } },
              [_vm._v(_vm._s(_vm.current + 1))]
            ),
            _vm._v("/" + _vm._s(_vm.total)),
          ]),
        ]),
      ]),
      _c(
        "div",
        {
          class: [
            _vm.question.isWhiteBackground ? "question-body2" : "question-body",
          ],
        },
        [
          _c("div", {
            staticClass: "title",
            domProps: { innerHTML: _vm._s(_vm.strReplace(_vm.question.body)) },
          }),
          _c("div", { staticClass: "limit" }, [
            _c("span", { staticClass: "sub-title" }, [_vm._v("时间限制")]),
            _vm._v(
              "：C/C++语言 " +
                _vm._s(_vm.question.codeTimeLimit) +
                "s / 其他语言 " +
                _vm._s(_vm.question.codeOtherTimeLimit) +
                "s"
            ),
            _c("br"),
            _c("span", { staticClass: "sub-title" }, [_vm._v("内存限制")]),
            _vm._v(
              "：C/C++语言 " +
                _vm._s(_vm.question.codeMemoryLimit) +
                "MB / 其他语言 " +
                _vm._s(_vm.question.codeOtherMemoryLimit) +
                "MB"
            ),
            _c("br"),
            _c("br"),
          ]),
          _c("span", { staticClass: "sub-title" }, [_vm._v("题目描述")]),
          _vm._v("： "),
          _c("div", {
            staticClass: "codeDescription",
            domProps: {
              innerHTML: _vm._s(_vm.strReplace(_vm.question.codeDescription)),
            },
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "breakBr",
          class: [
            _vm.question.isWhiteBackground ? "question-body2" : "question-body",
          ],
        },
        [
          _c("span", { staticClass: "group" }, [
            _c("span", { staticClass: "sub-title" }, [_vm._v("输入描述：")]),
            _c("span", [
              _vm._v(_vm._s(_vm.strReplace(_vm.question.codeInput))),
            ]),
          ]),
          _c("br"),
          _c("span", { staticClass: "group" }, [
            _c("span", { staticClass: "sub-title" }, [_vm._v("输出描述：")]),
            _c("span", [
              _vm._v(_vm._s(_vm.strReplace(_vm.question.codeOutput))),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "breakBr",
          class: [
            _vm.question.isWhiteBackground ? "question-body2" : "question-body",
          ],
        },
        [
          _c("span", { staticClass: "group" }, [
            _c("span", { staticClass: "sub-title" }, [_vm._v("输入样例：")]),
            _c("span", [
              _vm._v(_vm._s(_vm.strReplace(_vm.question.codeInputExample))),
            ]),
          ]),
          _c("br"),
          _c("span", { staticClass: "group" }, [
            _c("span", { staticClass: "sub-title" }, [_vm._v("输出样例：")]),
            _c("span", [
              _vm._v(_vm._s(_vm.strReplace(_vm.question.codeOutputExample))),
            ]),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "right-container" }, [
      _c(
        "div",
        { staticClass: "right2" },
        [
          _c("write-and-submit", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showEditorOrTestData,
                expression: "!showEditorOrTestData",
              },
            ],
            ref: "writeAndSubmit",
            staticClass: "editor",
            attrs: { question: _vm.question, commitLogs: _vm.commitLogs },
            on: { changeTime: _vm.changeTime, singleChange: _vm.singleChange },
          }),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showEditorOrTestData,
                  expression: "showEditorOrTestData",
                },
              ],
              staticClass: "commit-log",
            },
            [
              _c("div", { staticClass: "title" }, [_vm._v("运行记录")]),
              _c("i", {
                staticClass: "el-icon-close",
                on: {
                  click: function ($event) {
                    _vm.showEditorOrTestData = false
                  },
                },
              }),
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%", "margin-top": "20px" },
                  attrs: { data: _vm.tableData },
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      type: "index",
                      align: "center",
                      width: "50px",
                      label: "编号",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "language",
                      align: "center",
                      width: "150px",
                      label: "语言",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "isUserTestCase",
                      width: "100px",
                      align: "center",
                      label: "自测用例",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "10px" } },
                              [
                                _vm._v(
                                  _vm._s(scope.row.isUserTestCase ? "是" : "否")
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "sourceCode",
                      label: "代码详情",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c(
                              "el-tooltip",
                              {
                                staticClass: "item",
                                attrs: {
                                  effect: "dark",
                                  placement: "top-start",
                                },
                              },
                              [
                                _c("div", {
                                  attrs: { slot: "content" },
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.Replace(scope.row.sourceCode)
                                    ),
                                  },
                                  slot: "content",
                                }),
                                _c("i", { staticClass: "el-icon-warning" }),
                              ]
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "resultMessage", label: "编译结果" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      align: "center",
                      prop: "passRate",
                      width: "100px",
                      label: "AC通过率",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (scope) {
                          return [
                            _c("span", [
                              _vm._v(_vm._s(scope.row.passRate || 0) + "%"),
                            ]),
                          ]
                        },
                      },
                    ]),
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "compileOutput",
                      label: "编译详情",
                      align: "center",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return scope.row.compileOutput
                              ? [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: scope.row.compileOutput,
                                        placement: "top-start",
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-warning",
                                      }),
                                    ]
                                  ),
                                ]
                              : undefined
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "runAt", label: "提交时间" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "operate" }, [
            _c(
              "div",
              { staticClass: "btns" },
              [
                _c(
                  "el-button",
                  {
                    staticClass: "pre_btn gray_btn",
                    attrs: { type: "primary", plain: "" },
                    on: { click: _vm.commitLog },
                  },
                  [_vm._v("运行记录")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "next_btn gray_btn",
                    attrs: { type: "primary" },
                    on: { click: _vm.selfTest },
                  },
                  [_vm._v("自测数据")]
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "tools" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { plain: "", type: "primary" },
                    on: { click: _vm.saveCode },
                  },
                  [_vm._v("保存")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "next_btn",
                    style:
                      _vm.runTime === 0
                        ? null
                        : "background-color:#ccc;border:none;",
                    attrs: { disabled: _vm.runTime !== 0, type: "primary" },
                    on: { click: _vm.codeRun },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm.runTime === 0 ? "" : _vm.runTime) + "运行代码"
                    ),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }