var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "video-contaienr" },
    [
      _c(
        "GlobalDialog",
        {
          attrs: {
            dialogVisible: _vm.showDeleteVideo,
            show_close: false,
            title: "提示",
            width: "500px",
            showSecond: true,
            btn_title: "取消",
            btn_title2: "确认",
          },
          on: {
            "dialog-cancel": function ($event) {
              _vm.showDeleteVideo = false
            },
            "dialog-ok": _vm.deleteVideo,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "occupy-result",
              staticStyle: { "margin-bottom": "20px" },
            },
            [
              _c("i", { staticClass: "iconfont iconshanchu" }),
              _c("div", { staticClass: "right" }, [
                _c("span", { staticClass: "title" }, [
                  _vm._v("确定要删除吗？"),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "GlobalDialog",
        {
          attrs: {
            dialogVisible: _vm.showDetectDevice,
            title: "设备检测",
            "is-show-footer": false,
            width: "600px",
          },
          on: {
            "dialog-cancel": function ($event) {
              _vm.showDetectDevice = false
            },
            "dialog-ok": _vm.checkAllDevice,
            close: function ($event) {
              _vm.showDetectDevice = false
            },
          },
        },
        [
          _vm.showDetectDevice
            ? _c(
                "div",
                { staticClass: "device-detect" },
                [
                  _c("device-detect", {
                    on: {
                      next: function ($event) {
                        _vm.showDetectDevice = false
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "GlobalDialog",
        {
          directives: [{ name: "drag", rawName: "v-drag" }],
          staticStyle: { cursor: "move" },
          attrs: {
            dialogVisible: _vm.showVideoRecord,
            show_close: false,
            isShowFooter: false,
            title: "视频录制",
            customClass: "video-record-dialog",
            width: "860px",
          },
          on: {
            "dialog-cancel": function ($event) {
              _vm.showVideoRecord = false
            },
          },
        },
        [
          _c("div", { staticClass: "record-con" }, [
            _c("div", { staticClass: "record-title-con" }, [
              _c("div", { staticClass: "left" }, [_vm._v("视频录制")]),
              _c("div", { staticClass: "right" }, [
                _vm.isStart
                  ? _c("div", { staticClass: "record-title" }, [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("正在录制："),
                      ]),
                      _c("span", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm.formatSeconds(_vm.videoTime))),
                      ]),
                    ])
                  : _vm._e(),
                _vm.isWillExpire
                  ? _c("div", { staticClass: "record-title2" }, [
                      _c("span", { staticClass: "title" }, [
                        _vm._v("剩余时间："),
                      ]),
                      _c("span", { staticClass: "time" }, [
                        _vm._v(_vm._s(_vm.formatSeconds(_vm.remainTime))),
                      ]),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c("div", { staticClass: "main-con" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.isLoading,
                      expression: "isLoading",
                    },
                  ],
                  staticClass: "record",
                  attrs: { "element-loading-text": "录制结果获取中。。" },
                },
                [
                  !_vm.isStart
                    ? _c("div", { staticClass: "record-tips" }, [
                        _c(
                          "div",
                          {
                            staticClass: "btn_start",
                            on: { click: _vm.startRecord },
                          },
                          [_vm._v("开始录制")]
                        ),
                        _vm._v(" 注意事项： "),
                        _c("span", { staticClass: "content" }, [
                          _vm._v(
                            " 1、录制一旦开始，则不能暂停，请务必准备充分后，再录制；"
                          ),
                          _c("br"),
                          _vm._v(" 2、录制时长不要超过题目的时长要求；"),
                          _c("br"),
                          _vm._v(
                            " 3、不要使用外接显示器，遇到设备故障时，重启电脑再试。 "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
              _c("div", { staticClass: "question-body-re" }, [
                _c("div", {
                  staticClass: "quest-body",
                  domProps: {
                    innerHTML: _vm._s(_vm.strReplace(_vm.question.body)),
                  },
                }),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "re-btn-con" },
              [
                _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-right": "10px",
                      "font-size": "12px",
                    },
                  },
                  [
                    _vm._v(" 摄像头： "),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100px" },
                        on: { change: _vm.selectCamera },
                        model: {
                          value: _vm.defaultCamera,
                          callback: function ($$v) {
                            _vm.defaultCamera = $$v
                          },
                          expression: "defaultCamera",
                        },
                      },
                      _vm._l(_vm.cameraList, function (item) {
                        return _c("el-option", {
                          key: item.deviceId,
                          attrs: { label: item.label, value: item.deviceId },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _vm.isStart
                  ? _c("CustomButton", {
                      attrs: {
                        isDisabled: _vm.videoTime <= 5,
                        title: "完成录制",
                        isPlain: false,
                        clickMethod: _vm.stopRecord,
                      },
                    })
                  : _vm._e(),
                !_vm.isStart
                  ? _c("CustomButton", {
                      staticClass: "gap",
                      attrs: {
                        title: "取消录制",
                        isPlain: true,
                        clickMethod: () => (_vm.showVideoRecord = false),
                      },
                    })
                  : _vm._e(),
                _c(
                  "div",
                  { staticStyle: { margin: "0 10px", "font-size": "12px" } },
                  [
                    _vm._v(" 麦克风： "),
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "100px" },
                        on: { change: _vm.selectAudio },
                        model: {
                          value: _vm.defaultAudio,
                          callback: function ($$v) {
                            _vm.defaultAudio = $$v
                          },
                          expression: "defaultAudio",
                        },
                      },
                      _vm._l(_vm.audioList, function (item) {
                        return _c("el-option", {
                          key: item.deviceId,
                          attrs: { label: item.label, value: item.deviceId },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("div", { staticClass: "answer" }, [
        _c(
          "div",
          { staticClass: "btn-con" },
          [
            _c("CustomButton", {
              staticClass: "gap",
              attrs: {
                title: "录制视频",
                isPlain: false,
                hasIcon: true,
                isDisabled: !!_vm.answerUrl,
                iconName: "iconfont iconvideo2-fill",
                clickMethod: () => (_vm.showVideoRecord = true),
              },
            }),
            _vm.question.isVideoAnswerDeletable
              ? _c("CustomButton", {
                  staticClass: "gap",
                  attrs: {
                    title: "删除视频",
                    isPlain: true,
                    hasIcon: true,
                    clickMethod: () => (_vm.showDeleteVideo = true),
                    iconName: "iconfont iconshanchu",
                  },
                })
              : _vm._e(),
            _c("CustomButton", {
              attrs: {
                title: "设备检测",
                isPlain: true,
                hasIcon: true,
                clickMethod: _vm.detectDevice,
                iconName: "iconfont iconshexiangtou3",
              },
            }),
          ],
          1
        ),
      ]),
      !!_vm.answerUrl
        ? _c(
            "div",
            [
              _c("video-player", {
                attrs: {
                  id: `video${_vm.question.questionUuid}`,
                  answerUrl: _vm.answerUrl,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }