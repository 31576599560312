<!--
 * @Author: Libra
 * @Date: 2022-04-07 13:18:25
 * @LastEditTime: 2023-01-09 10:44:52
 * @LastEditors: Libra
 * @Description: 问答题答题框（富文本编辑器）
 * @FilePath: /stone-exam-ui/src/components/WangEditor/index.vue
-->
<template>
  <div class="editor-container">
    <div style="position: relative;">
      <div id="editor" v-show="question.isShowAnswerBox"></div>
      <span class="text-num">{{ textNum }} 字符</span>
    </div>
    <el-dialog
      title="插入代码"
      :visible.sync="showInsertCode"
      :before-close="clearContent"
      width="800px"
      :modal="false"
      :modal-append-to-body="false"
    >
      <el-select
        v-model="cmEditorMode"
        placeholder="请选择"
        size="small"
        style="width:200px;margin-bottom: 10px;"
        @change="onEditorModeChange"
      >
        <el-option
          v-for="item in cmEditorModeOptions"
          :key="item.name"
          :label="item.name"
          :value="item.type"
        />
      </el-select>
      <code-mirror-editor
        ref="cmEditor"
        style="font-size:16px; height: 400px;margin-bottom: 10px;"
        :cm-mode="cmMode"
        :auto-format-json="true"
        :json-indentation="2"
      />
      <div class="insert">
        <el-button type="primary" @click="insert">插入</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="画图板"
      :visible.sync="showWhiteBoard"
      :modal-append-to-body="false"
      width="940px"
      :modal="false"
    >
      <vue-drawing :width="900" :height="400" @image-export="insertPaint" />
    </el-dialog>
    <el-dialog
      title="手机扫码传图"
      :visible.sync="showPhoneUpload"
      :before-close="beforePhoneUploadClose"
      width="600px"
      :modal="false"
      :modal-append-to-body="false"
    >
      <div class="content-contaienr">
        <div
          v-loading="qrLoading"
          element-loading-text="二维码生成中"
          class="qr-container"
          style="width: 200px;height: 200px;"
        >
          <vue-qr
            style="width: 200px;height: 200px;"
            :size="800"
            :text="qrContent"
          ></vue-qr>
          <div class="refresh-qr" v-if="isFailure">
            <span class="failure">二维码已失效</span>
            <el-button type="danger" @click="refresh">请点击刷新</el-button>
          </div>
        </div>
        <span class="upload-label">手机微信扫码</span>
        <span class="upload-tips">点击下方按钮完成上传</span>
        <el-button
          type="danger"
          style="margin-bottom: 20px;"
          @click="getImage"
          :loading="isGettingImage"
          >立即上传</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogVisible"
      :modal="false"
      title="上传文件"
      width="40%"
    >
      <el-progress
        v-if="flag"
        style="width: 100%;font-size: 12px;margin-bottom: 10px;"
        :text-inside="true"
        :stroke-width="15"
        :percentage="progressPercent"
      />
      <div class="files">
        <div class="file" v-for="(item, index) in fileList" :key="index">
          <span>{{ item.name }}</span>
          <i class="el-icon-close" @click="deleteFile(index)"></i>
        </div>
      </div>
      <el-upload
        ref="upload"
        :file-list="fileList"
        :show-file-list="false"
        :before-upload="beforeUpload"
        :http-request="uploadHttp"
        class="editor-slide-upload"
        accept=".jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.zip,.webp"
        action=""
      >
        <el-button size="small" type="danger" style="margin-top: 10px;">
          选择文件
        </el-button>
        <div style="margin-top: 30px">
          支持的格式：jpg、jpeg、png、doc、docx、xls<br />xlsx、ppt、pptx、zip
          <br />最多支持上传1个文件，且文件不能超过30MB
        </div>
      </el-upload>
      <div class="btn_container">
        <el-button
          size="small"
          type="danger"
          plain
          @click="
            () => {
              $refs.upload.abort();
              fileList = [];
              dialogVisible = false;
            }
          "
        >
          取消
        </el-button>
        <el-button
          :disabled="!fileList.length"
          size="small"
          type="danger"
          @click="handleSubmit"
        >
          上传
        </el-button>
      </div>
    </el-dialog>
    <el-dialog title="数学公式" :visible.sync="mathDialog" width="600px">
      <div class="content">
        <math-editor @latex="latex" />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="mathDialog = false">取 消</el-button>
        <el-button type="primary" @click="insertMath">插 入</el-button>
      </span>
    </el-dialog>
    <div class="editor-btns">
      <el-button
        class="editor-btn"
        icon="iconfont iconzu"
        size="mini"
        type="danger"
        @click="mathDialog = true"
        v-if="question.plugins.ieEnableFormula"
      >
        公式
      </el-button>
      <el-button
        class="editor-btn"
        icon="iconfont iconcharudaima"
        size="mini"
        type="danger"
        @click="showInsertCode = true"
        v-if="question.plugins.ieEnableCodeTemplate"
      >
        代码
      </el-button>
      <el-button
        class="editor-btn"
        icon="iconfont iconsheji_huatu"
        size="mini"
        type="danger"
        @click="showWhiteBoard = true"
        v-if="question.plugins.ieEnablePaint"
      >
        画图
      </el-button>
      <el-button
        class="editor-btn"
        icon="el-icon-upload"
        size="mini"
        type="danger"
        v-if="question.plugins.ieEnableMobilePhone"
        @click="
          () => {
            showPhoneUpload = true;
            getMobileToken();
            refreshQR();
          }
        "
      >
        手机图片上传
      </el-button>
      <el-button
        class="editor-btn"
        icon="el-icon-upload"
        size="mini"
        type="danger"
        @click="dialogVisible = true"
        v-if="question.plugins.isEnableFileUpload"
      >
        本机文件上传
      </el-button>
    </div>
  </div>
</template>

<script>
import E from 'wangeditor'
import CodeMirrorEditor from '@/components/Editor'
import VueDrawing from 'vue-drawing-libra'
import hljs from 'highlight.js'
import 'highlight.js/styles/a11y-light.css'
import { file_host } from '@/api/config'
import Api from '@/api/api'
import VueQr from 'vue-qr'
import MathEditor from '@/components/MathEditor'
export default {
  props: {
    question: {
      type: Object
    },
    commitLogs: {
      type: Array
    }
  },
  data() {
    return {
      editor: null,
      showInsertCode: false,
      showWhiteBoard: false,
      showPhoneUpload: false,
      showMathFormula: false,
      dialogVisible: false,
      mathDialog: false,
      qrLoading: false,
      qrContent: '',
      flag: false,
      qrTimer: null,
      cmEditorMode: 7,
      isFailure: false,
      isGettingImage: false,
      progressPercent: 0,
      fileList: [],
      textNum: 0,
      cmMode: 'default',
      mathLatex: '',
      cmEditorModeOptions: [
        { type: 1, name: 'c' },
        { type: 2, name: 'html' },
        { type: 3, name: 'cpp' },
        { type: 5, name: 'csharp' },
        { type: 7, name: 'java' },
        { type: 9, name: 'JavaScript' },
        { type: 10, name: 'py' },
        { type: 12, name: 'php' },
        { type: 16, name: 'ruby' },
        { type: 23, name: 'css' }
      ]
    }
  },
  components: {
    CodeMirrorEditor,
    VueDrawing,
    VueQr,
    MathEditor
  },
  mounted() {
    this.initEditor()
  },
  methods: {
    // 初始化 wangEditor
    initEditor() {
      const editor = new E('#editor')
      this.editor = editor
      editor.config.zIndex = 2
      editor.config.showFullScreen = false
      editor.config.height = 500
      // 配置 onchange 回调函数
      editor.config.onchange = () => {
        // 获取字数 过滤一下nbsp;和空格
        this.textNum = editor.txt.text().replace(/&nbsp;/g, '').replace(/\s/g, '').length
        this.$emit(
          'answer',
          {
            file: this.files,
            value: [editor.txt.html()],
            originalFile: this.originalFile
          },
          false
        )
      }
      // 配置触发 onchange 的时间频率，默认为 200ms
      editor.config.onchangeTimeout = 500 // 修改为 500ms
      editor.config.menus = [
        'head',
        'bold',
        'fontSize',
        'fontName',
        'italic',
        'justify',
        'lineHeight',
        'foreColor'
      ]
      editor.config.fontSizes = {
        'x-small': { name: '12px', value: '1' },
        small: { name: '14px', value: '2' },
        normal: { name: '16px', value: '3' },
        large: { name: '18px', value: '4' },
        'x-large': { name: '24px', value: '5' },
        'xx-large': { name: '32px', value: '6' },
        'xxx-large': { name: '48px', value: '7' }
      }
      editor.config.colors = [
        '#000000',
        '#eeece0',
        '#1c487f',
        '#4d80bf',
        '#ff0000',
        '#ff9900',
        '#36bfc4',
        '#00ff00',
        '#0000ff'
      ]
      editor.config.pasteTextHandle = (editor, event) => {
        // 阻止默认的粘贴行为
        return false
      }
      editor.create()
    },
    // 清空编辑器
    clearContent(done) {
      this.setValue('')
      done && done()
    },
    setContent(value) {
      this.editor.txt.append(value)
    },
    getContent() {
      return this.editor.txt.html()
    },
    // 插入代码
    insert() {
      const language = this.getLanguageByType(this.cmEditorMode)
      const value = this.getValue()
      const s = `<div>${value}</div><br />`
      var htmlObject = document.createElement('div')
      htmlObject.innerHTML = s
      hljs.configure({ useBR: true })
      const a = hljs.highlight(htmlObject.innerText.replace(/\n/g, '<br>'), {
        language
      })
      const b = a.value.replace(/&lt;/g, '<').replace(/&gt;/g, '>')
      this.editor.txt.append(`<div class="code-container">${b}</div><br />`)
      this.showInsertCode = false
      this.clearContent()
    },
    getLanguageByType(type) {
      for (const item of this.cmEditorModeOptions) {
        if (item.type === type) {
          return item.name
        }
      }
    },
    // 获取内容
    getValue() {
      return this.$refs.cmEditor.getValue()
    },
    // 修改内容
    setValue(jsonValue) {
      setTimeout(() => {
        this.$refs.cmEditor.setValue(jsonValue)
      }, 100)
    },
    // 切换编辑模式事件处理函数
    onEditorModeChange(value) {
      switch (value) {
        case 1:
          this.cmMode = 'text/x-csrc'
          break
        case 2:
          this.cmMode = 'text/html'
          break
        case 3:
          this.cmMode = 'text/x-c++src'
          break
        case 5:
          this.cmMode = 'text/x-csharp'
          break
        case 7:
          this.cmMode = 'text/x-java'
          break
        case 9:
          this.cmMode = 'text/javascript'
          break
        case 10:
          this.cmMode = 'text/x-python'
          break
        case 12:
          this.cmMode = 'text/x-php'
          break
        case 16:
          this.cmMode = 'text/x-ruby'
          break
        case 23:
          this.cmMode = 'text/css'
          break
        default:
          this.cmMode = 'application/json'
      }
    },
    insertPaint(svg) {
      let content = new DOMParser().parseFromString(svg, 'text/html')
      content = window.btoa(
        unescape(
          encodeURIComponent(
            new XMLSerializer().serializeToString(content.body.firstChild)
          )
        )
      )
      content = `<img width="600" src="data:image/svg+xml;base64,${content}">`
      this.setContent(`${content}<br/><br/> `)
      this.showWhiteBoard = false
    },
    beforePhoneUploadClose() {
      clearTimeout(this.qrTimer)
      this.isFailure = false
      this.isGettingImage = false
      this.showPhoneUpload = false
    },
    async getImage() {
      this.isGettingImage = true
      const res = await Api.getQuestionSlice(this.question.questionUuid)
      if (res.code === 0) {
        if (res.data === null) {
          this.isGettingImage = false
          this.showPhoneUpload = false
          this.showGetNullImg = true
          return
        }
        const img = new Image()
        img.src = `${file_host}${res.data.path}${res.data.name}`
        img.onload = () => {
          const height = img.height
          const width = img.width
          let resWidth = 0
          if (width > height) {
            resWidth = 600
          } else {
            resWidth = (width * 600) / height
          }
          this.setContent(
            `<img width="${resWidth}px" src="${file_host}${res.data.path}${res.data.name}" alt="">`
          )
          this.isGettingImage = false
          this.showPhoneUpload = false
        }
      }
    },
    // 手机扫码上传
    async getMobileToken() {
      this.qrLoading = true
      const res = await Api.getMobileToken()
      const { mobileMonitorType, mobileVideoType } = this.$store.state.examInfo
      const isOpen = mobileVideoType !== 0 || mobileMonitorType !== 0
      this.qrLoading = false
      const env = process.env.NODE_ENV
      // const env = 'beta'
      if (res.code === 0) {
        this.qrContent = `https://exam.stac.fun/picUpload?token=${res.data}&partUuid=${this.$route.query.partUuid}&questionUuid=${this.question.questionUuid}&isOpen=${isOpen}&env=${env}`
      }
    },
    refreshQR() {
      this.qrTimer = setTimeout(() => {
        this.isFailure = true
        clearTimeout(this.qrTimer)
      }, 60000)
      this.$once('hook:beforeDestroy', () => {
        clearInterval(this.qrTimer)
      })
    },
    async refresh() {
      await this.getMobileToken()
      this.isFailure = false
      this.refreshQR()
    },
    // 删除文件
    deleteFile(index) {
      this.fileList.splice(index, 1)
    },
    beforeUpload(file) {
      this.fileList = [...this.fileList, file]
    },
    async uploadHttp({ file }) {
      if (this.fileList.length > 1) {
        this.fileList.splice(0, 1)
      }
      if (file.size > 10485760 * 3) {
        this.$message.error('文件大小不能超过30MB')
        this.fileList = []
      }
    },
    async handleSubmit() {
      this.files = []
      this.originalFile = []
      const candidateUuid = this.$store.state.userInfo.candidateUuid
      const examUuid = this.$store.state.userInfo.examUuid
      const questionUuid = this.question.questionUuid
      this.flag = true
      // 上传新文件时，将进度条值置为零
      this.progressPercent = 0
      // 进度条
      const uploadProgressEvent = (progressEvent) => {
        this.progressPercent = Math.floor(
          (progressEvent.loaded * 100) / progressEvent.total
        )
      }
      await this.getToken()
      for (const file of this.fileList) {
        const time = new Date().getTime()
        const ext = file.name.split('.').pop()
        const filePath = `candidate/${examUuid}/answer/${candidateUuid}_${questionUuid}_${time}.${ext}`
        await this.ossUpload(file, file, uploadProgressEvent, filePath)
        this.updateSlice({
          candidateUuid: candidateUuid,
          examUuid: examUuid,
          name: `${candidateUuid}_${time}.${ext}`,
          path: filePath,
          type: 6
        })
        this.files.push(filePath)
        this.originalFile.push(file.name)
      }
      // 上传文件完成的时候需要提交一次答案，防止用户刷新页面，上传的文件不能回显
      // 第三个参数是一个flag，如果为true，则表示是文件上传，这时候则需要调用保存答案的接口
      this.$emit(
        'answer',
        {
          file: this.files,
          value: [this.question.isShowAnswerBox ? this.getContent() : ''],
          originalFile: this.originalFile
        },
        true
      )
      if (this.progressPercent === 100) {
        this.dialogVisible = false
        this.fileList = []
        this.flag = false
      }
      var scrollTarget = document.querySelector('.left')
      // scrollTarget.scrollHeight是获取dom元素的高度，然后设置scrollTop
      scrollTarget.scrollTop = scrollTarget.scrollHeight
    },
    // 获取 oss Token
    getToken: async function() {
      const res = await Api.ossToken()
      this.oss = res.data
      return res
    },
    // 上传阿里云
    ossUpload: async function(blob, file, onUploadProgress, fileName) {
      const data = {
        filename: fileName,
        blob: blob,
        ossInfo: this.oss
      }
      await Api.ossUploadFile(data, onUploadProgress)
    },
    // 更新切片信息
    updateSlice: async function(data) {
      await Api.updateSlice(data)
    },
    insertMath() {
      var MQ = window.MathQuill.getInterface(2)
      const ele = document.createElement('span')
      ele.innerText = this.mathLatex
      MQ.StaticMath(ele)
      this.setContent(`<span>${ele.outerHTML}</span> `)
      this.mathDialog = false
    },
    latex(la) {
      this.mathLatex = la
    }
  }
}
</script>
<style>
sup {
  top: 0;
}
sub {
  top: 0;
}
</style>
<style lang="scss" scoped>
@import './index.scss';
</style>
